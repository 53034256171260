import PropType from "prop-types";
import { useEffect, useState } from "react";
import { useTranslate } from "../../../../../language/i18n";
import customerService from "../../../../../services/customer/customerService";
import SelectField from "../../../SelectField/SelectField";
import styles from "./CustomerSelector.module.scss";

const CustomerSelector = ({ municipalAffiliation, onSelect, chosenMunicipality }) => {
  const translate = useTranslate();
  const [customers, setCustomers] = useState(null);

  useEffect(() => {
    customerService.getCustomers({ all: true }).then(response => {
      setCustomers(response.data.page);
    });
  }, []);

  const getPreSelected = () => {
    if (!customers?.length || !municipalAffiliation) return;

    if (customers.find(customer => customer.id === municipalAffiliation.id)) onSelect(municipalAffiliation);
  };

  return (
    <>
      {!customers?.length && customers != null && (
        <p className={styles.errorMessage}>{translate("NOT_AUTHORIZED_TO_ANY_MUNICIPALITY")}</p>
      )}
      <SelectField
        options={customers}
        onSelect={onSelect}
        label={translate("MUNICIPALITY_AFFILIATION")}
        customCssClass={styles.selectField}
        required
        selectedId={chosenMunicipality?.id || getPreSelected()}
        customSettings={{ display: "name" }}
      />
    </>
  );
};

CustomerSelector.propTypes = {
  municipalAffiliation: PropType.object,
  chosenMunicipality: PropType.object,
  onSelect: PropType.func.isRequired,
};
export default CustomerSelector;
