import PropTypes from "prop-types";

const Message = ({ color = "currentColor", size = 24, customCssClass }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 96 960 960"
    fill={color}
    className={customCssClass}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M145.087 910.131q-32.507 0-55.862-23.356-23.356-23.355-23.356-55.862V321.087q0-32.74 23.356-56.262 23.355-23.521 55.862-23.521h669.826q32.74 0 56.262 23.521 23.521 23.522 23.521 56.262v509.826q0 32.507-23.521 55.862-23.522 23.356-56.262 23.356H145.087ZM480 612.088l-334.913-223v441.825h669.826V389.088L480 612.088Zm0-73.001 330.913-218H150.087l329.913 218ZM145.087 389.088v-68.001 509.826-441.825Z" />
  </svg>
);

Message.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  customCssClass: PropTypes.string,
};

export default Message;
