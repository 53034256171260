import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import LogisticsConfirmModal from "../shared/Modals/LogisticsConfirmModal";
import { useTranslate } from "../../language/i18n";
import { useEffect, useState } from "react";
import accountService from "../../services/account/accountService";
import { setUserHasMessageToAccept } from "../../reducers/session/sessionAction";
import { Checkbox } from "@myloc/myloc-gui";

const UserMessageToAcceptModal = () => {
  const userMessageToAccept = useSelector(state => state.session?.userMessageToAccept);
  const translate = useTranslate();
  const [userFeedback, setUserFeedback] = useState(null);
  const [checked, setChecked] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (userMessageToAccept) {
      let timer = setTimeout(() => setVisible(true), 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [userMessageToAccept]);

  const submitMessagesAccepted = async () => {
    setUserFeedback(null);
    const response = await accountService.confirmAccessMessage(userMessageToAccept);
    if (response.isOk()) {
      if (response.data) {
        setUserHasMessageToAccept(response.data.accessMessage);
      }
    }
  };

  const cancelModal = () => {
    const userFeedback = translate("USER_MESSAGE_NOT_ACCEPTED");
    setUserFeedback(userFeedback);
  };

  const UserFeedback = () => {
    return userFeedback;
  };

  if (!userMessageToAccept) {
    return null;
  }
  return (
    <LogisticsConfirmModal
      title={userMessageToAccept.messageDescription}
      text={userMessageToAccept.message}
      isHtmlContent={true}
      visible={visible}
      cancel={cancelModal}
      small={false}
      submit={submitMessagesAccepted}
      showDeclineButton={false}
      confirmButtonText={"APPROVE"}
      confirmButtonDisabled={!checked}
      showCloseButton={false}
    >
      <UserFeedback message={userFeedback} />
      <Checkbox
        label={translate("GDPR_CONFIRMATION_MESSAGE")}
        onChange={() => setChecked(!checked)}
        checked={checked}
      />
    </LogisticsConfirmModal>
  );
};
UserMessageToAcceptModal.propTypes = {
  forgotPasswordUrl: PropTypes.string,
  registerUrl: PropTypes.string,
};

export default UserMessageToAcceptModal;
