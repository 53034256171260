import queryString from "query-string";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import useFetchSessionData from "../hooks/useFetchSessionData";
import useIsSingleSignOn from "../hooks/useIsSingleSignOn";

import { setCompany, setFacility, setInformation, setOrder } from "../reducers/appData/appDataActions";
import {
  setAuthorizationList,
  setInitialized,
  setLoginToken,
  setRememberMe,
  setSessionId,
  setUserHasMessageToAccept,
} from "../reducers/session/sessionAction";
import accountService from "../services/account/accountService";
import storage from "../utils/store/storage";
import { REMEMBER_ME, SESSION_ID, SINGLE_SIGN_ON_RESPONSE } from "../utils/store/storeVariables";

const Init = () => {
  const isSingleSignOn = useIsSingleSignOn();
  const loadSessionData = useFetchSessionData();
  const sessionInitialized = useSelector(state => state.session?.initialized);
  const singleSignOut = useSelector(state => state.session?.singleSignOut);

  const initializeSingleSignOnSession = useCallback(async () => {
    setRememberMe(false);

    //Doesn't need a session, is created on the fly from mod_auth_openidc in reversed proxy
    await loadSessionData();
  }, [loadSessionData]);

  useEffect(() => {
    const initializeSingleSignOnAndStandard = async () => {
      await initializeSingleSignOnSession();
      await initializeStandardLoginSession();
    };
    if (isSingleSignOn === undefined) {
      return;
    } else if (isSingleSignOn && !sessionInitialized) {
      initializeSingleSignOnAndStandard();
    } else if (!isSingleSignOn && isMylocLogin()) {
      initializeMylocLoginSession(getMylocLoginToken());
    } else if (!isSingleSignOn && !sessionInitialized) {
      initializeStandardLoginSession();
    } else {
      return;
    }
  }, [initializeSingleSignOnSession, isSingleSignOn, sessionInitialized]);

  useEffect(() => {
    if (singleSignOut) {
      storage.deleteItem(SESSION_ID);
      storage.deleteItem(SINGLE_SIGN_ON_RESPONSE);

      const domain = window.location.hostname;
      const redirectLocation =
        "/MyL/redirect_uri?logout=https%3A%2F%2F" + domain + encodeURIComponent("/MyL") + "%2Flogged-out.html";

      window.location = redirectLocation;
    }
  }, [singleSignOut]);

  return null;
};

const doLoginWithToken = async mylocLoginToken => {
  const data = {
    mylocLoginToken: mylocLoginToken,
    persistent: false,
  };
  const response = await accountService.loginWithMylocToken(data);
  if (response.data?.id) {
    const appData = await accountService.getAppData();
    setAuthorizationList(appData?.data?.authorizations);
    setInitialized();
  }
};

const mylocAuthentication = async mylocLoginToken => {
  if (mylocLoginToken) {
    setLoginToken(mylocLoginToken, false);
    await doLoginWithToken(mylocLoginToken);
  }
  return;
};
const isMylocLogin = () => {
  const mylocToken = queryString.parse(location.search).mylocLoginToken;
  return mylocToken !== undefined;
};

const getMylocLoginToken = () => {
  const mylocToken = queryString.parse(location.search).mylocLoginToken;
  return mylocToken;
};

async function initializeMylocLoginSession() {
  let query = queryString.parse(location.search);
  //If a virtualSessionId exists, use it instead of logging in again
  if (query.virtualSessionId) {
    setSessionId(encodeURIComponent(query.virtualSessionId));
    const response = await accountService.getAppData();
    setAuthorizationList(response.data.authorizations);
    setInitialized();
  } else {
    await mylocAuthentication(query.mylocLoginToken);
  }
  return;
}

async function initializeStandardLoginSession() {
  let rememberMe = await storage.loadItem(REMEMBER_ME);
  setRememberMe(!!rememberMe);

  const sessionId = await storage.loadItem(SESSION_ID);
  if (sessionId) {
    const response = await accountService.getAppData();
    if (response.isOk()) {
      if (response.data?.accessMessage) {
        setUserHasMessageToAccept(response.data.accessMessage);
      }
      setCompany({
        id: response.data.company.id,
        description: response.data.company.label,
      });

      setFacility({
        id: response.data?.facility?.id,
        description: response.data?.facility?.label,
        value: response.data?.facility?.value,
        category: response.data?.facility?.category,
      });

      setInformation(response.data.user);
      setOrder(response.data.order);
      setSessionId(response.data.id);
      setAuthorizationList(response.data.authorizations);
    } else {
      storage.saveItem(SESSION_ID, null, rememberMe);
    }
  }

  setInitialized();
}

export default Init;
