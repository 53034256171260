import { ArrowDown, ArrowUp, Check, Cross, IconButton } from "@myloc/myloc-gui";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";

import { useTranslate } from "../../../language/i18n";
import isOutOfViewport from "../../../utils/isOutOfViewport";
import OnClickOutside from "../../../utils/OnClickOutside";

import colors from "../../../style/colors.scss";
import styles from "./SelectField.module.scss";

const SIZE = {
  THIN: "thin",
  DEFAULT: "default",
};
const SelectField = ({
  options,
  onSelect,
  selectedId,
  label,
  customSettings,
  disabled,
  customCssClass,
  customSelectionCss,
  customListCss,
  name,
  required,
  prepopulate,
  noResultLabel = "NO_RESULT_FOUND",
  onSelectOnMount = true,
  size = SIZE.DEFAULT,
}) => {
  const translate = useTranslate();

  const [value, setValue] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const [highlighted, setHighlighted] = useState(0);

  const searchRef = useRef(null);
  const inputDisabled = disabled || !options || options.length === 0;

  const firstUpdate = useRef(true);

  const settings = {
    display: "value",
    id: "id",
    autocomplete: true,
    ...customSettings,
  };

  const handleOnSelect = item => {
    onSelect?.(item);
    setValue(null);
    setOpen(false);
    setHasFocus(false);
    setHighlighted(-1);
  };

  const setValueAndHandleSelect = useCallback(() => {
    if (value === null && selectedId) {
      setValue(options?.find(current => current[settings.id] === selectedId)?.[settings.display]);
      onSelect(options.find(({ id }) => id == selectedId));
    }
  }, [value, selectedId, options, settings.display, settings.id, onSelect]);

  const handleOnFocus = () => {
    setValueAndHandleSelect();
    setHasFocus(true);
    setHighlighted(-1);
  };

  const onChangeValue = e => {
    const val = e.currentTarget.value;
    setHighlighted(0);
    setOpen(true);
    setValue(val);
  };

  const clear = event => {
    event.preventDefault();

    setOpen(false);
    setValue("");
    onSelect?.("");
  };

  const refCallback = el => {
    if (!el) return;
    if (isOutOfViewport(el).bottom) el.classList.add(styles["positionAbove"]);
  };

  const open = () => {
    settings.autocomplete && setValue("");
    setOpen(!isOpen);
  };

  const onClose = () => {
    setOpen(false);
    setValue(null);
  };

  const close = () => {
    setOpen(false);
    searchRef.current.focus();
  };

  const filteredItems = () => {
    if (!value || !settings.autocomplete) return options;
    return options?.filter(suggestion => suggestion[settings.display]?.toLowerCase().indexOf(value.toLowerCase()) > -1);
  };

  const highlight = highlightIndex => {
    if (!isOpen) return;
    if (highlightIndex < 0) highlightIndex = Math.max(0, filteredItems().length - 1);
    if (highlightIndex >= filteredItems().length) highlightIndex = 0;
    setHighlighted(highlightIndex);
  };

  useEffect(() => {
    if (!onSelectOnMount) return;

    if (!firstUpdate.current || !options?.length || !selectedId) return;
    firstUpdate.current = false;

    setValueAndHandleSelect();
  }, [selectedId, onSelect, options?.length, setValueAndHandleSelect, onSelectOnMount]);

  const onKeyDown = event => {
    switch (event.key) {
      case "Escape":
        close();
        break;

      case "ArrowDown":
        highlight(highlighted + 1);
        event.preventDefault();
        break;

      case "ArrowUp":
        highlight(highlighted - 1);
        event.preventDefault();
        break;

      case "Enter":
        handleOnSelect(filteredItems()[highlighted]);
        break;

      case " ":
        value === "" && handleOnSelect(filteredItems()[highlighted]);
        break;

      case "Tab":
        isOpen && event.preventDefault();
        break;
    }
  };

  const template = item => {
    return <div>{settings?.template ? settings.template(item) : item[settings.display] || "-"}</div>;
  };

  const ListItems = () => {
    return filteredItems().length ? (
      filteredItems().map((item, id) => (
        <li
          id={id}
          key={item[settings.id]}
          onClick={() => handleOnSelect(item)}
          className={classNames(
            styles.listItem,
            id === highlighted && styles.active,
            selectedId === item[settings.id] && styles.selected,
          )}
        >
          {template(item)}
          {selectedId === item[settings.id] && <Check size="20" customCssClass={styles.icon} />}
        </li>
      ))
    ) : (
      <li className={classNames(styles.listItem, styles.noMatch)}>{translate(noResultLabel)}</li>
    );
  };

  return (
    <OnClickOutside call={onClose}>
      <div className={classNames(styles.selectField, customCssClass)}>
        <label>
          <span
            className={classNames(
              styles.label,
              styles[size],
              (value || isOpen || selectedId || selectedId === 0) && styles.small,
              hasFocus && styles.focus,
            )}
          >
            {label} {required && label && " *"}
          </span>
          <input
            type="text"
            name={name}
            ref={searchRef}
            onFocus={handleOnFocus}
            onClick={open}
            onChange={onChangeValue}
            onKeyDown={onKeyDown}
            readOnly={!settings.autocomplete}
            value={
              value !== null
                ? value
                : (selectedId || selectedId === 0) && selectedItem(options, selectedId, settings)
                ? selectedItem(options, selectedId, settings)
                : ""
            }
            disabled={inputDisabled}
            className={classNames(styles.selectionInput, styles[size], customSelectionCss)}
            required={required}
          />
          {!inputDisabled && (
            <div className={classNames(styles.fieldIcons, styles[size])}>
              {(selectedId || selectedId === 0) && !required && (
                <IconButton onClick={clear} customCssClass={styles.removeBtn}>
                  <Cross size="20" />
                </IconButton>
              )}
              <Arrow isOpen={isOpen} />
            </div>
          )}
        </label>
        {((prepopulate && isOpen) || (!prepopulate && value)) && (
          <ul className={classNames(styles.list, customListCss, styles[size])} ref={refCallback}>
            <ListItems />
          </ul>
        )}
      </div>
    </OnClickOutside>
  );
};

function selectedItem(options, selectedId, settings) {
  if (options) return options?.find(current => current[settings.id] === selectedId)?.[settings.display];
}

const Arrow = ({ isOpen }) => {
  return isOpen ? (
    <ArrowUp color={colors.primary} customCssClass={styles.arrow} />
  ) : (
    <ArrowDown customCssClass={styles.arrow} />
  );
};

Arrow.propTypes = {
  isOpen: PropTypes.bool,
};

SelectField.defaultProps = {
  prepopulate: true,
};

SelectField.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,

  onSelect: PropTypes.func.isRequired,
  filter: PropTypes.func,
  customSettings: PropTypes.shape({
    autocomplete: PropTypes.bool,
    template: PropTypes.func,
    display: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
  }),
  name: PropTypes.string,
  customCssClass: PropTypes.string,
  customSelectionCss: PropTypes.string,
  customListCss: PropTypes.string,
  required: PropTypes.bool,
  prepopulate: PropTypes.bool,
  noResultLabel: PropTypes.string,
  size: PropTypes.string,
  onSelectOnMount: PropTypes.bool,
};

export default SelectField;
export { SIZE };
