import classNames from "classnames";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslate } from "../../../language/i18n";
import { formatPostalNo } from "../../../utils/postalNo";
import Label from "../../shared/Label/Label";
import styles from "./RecieverInformation.module.scss";

const RecieverInformation = ({ customCssClass, receiverData, title }) => {
  const translate = useTranslate();

  let receiver = useSelector(state => state.appData.order?.receiver);

  if (receiverData) {
    receiver = receiverData;
  }

  if (!receiver) {
    return null;
  }

  const FacilityInformation = () => {
    const address = receiver?.address;
    return (
      <>
        <h2 className={styles.title}>{translate("INFORMATION_ABOUT_FACILITY")}</h2>
        <Label label={translate("BASE_STORAGE")} value={`${receiver?.value} - ${receiver?.label}`} />
        <p className={styles.text}>
          <span className={styles.label}>{translate("ADDRESS")}:</span>
          {address?.street && <span>{address.street}</span>}
          {address?.postalNo && <span className={styles.inline}>{formatPostalNo(address.postalNo)}</span>}
          {address?.city && <span className={styles.inline}>{address.city}</span>}
        </p>
      </>
    );
  };

  return (
    <aside className={classNames(styles.container, customCssClass)}>
      {receiver?.type === "FACILITY" ? <FacilityInformation /> : <UserInformation receiver={receiver} title={title} />}
    </aside>
  );
};

const UserInformation = ({ receiver, title, showDeregistrationReason }) => {
  const translate = useTranslate();
  const contact = receiver?.contactInformation;
  const address = receiver?.defaultAddress;

  return (
    <>
      <h3 className={styles.title}>{title || translate("CLIENT")}</h3>
      <div className={styles.content}>
        <div>
          <Label label={translate("FIRST_NAME")} value={receiver?.firstName} />
          <Label label={translate("LAST_NAME")} value={receiver?.surname} />
          <Label label={translate("PERSONAL_IDENTITY_NUMBER_OR_UNIT")} value={receiver?.identityNumber} />
          <Label label={translate("MUNICIPALITY_AFFILIATION")} value={receiver?.municipalAffiliation?.name} />
          <p className={styles.text}>
            <span className={styles.label}>{translate("PLACE_OF_RESIDENCE")}:</span>
            {address?.careOf && <span>{address.careOf}</span>}
            {address?.street && <span>{address.street}</span>}
            {address?.postalNo && <span className={styles.inline}>{formatPostalNo(address.postalNo)}</span>}
            {address?.city && <span className={styles.inline}>{address.city}</span>}
          </p>
          <Label label={translate("PHONE_NO")} value={contact?.phoneNo} />
          <Label label={translate("EMAIL")} value={contact?.email} />
        </div>
        {showDeregistrationReason && receiver?.deregistrationDate && (
          <div>
            <Label label={translate("DEREGISTRATION_DATE")} value={receiver?.deregistrationDate} />
            <Label label={translate("DEREGISTRATION_REASON")} value={receiver?.deregistrationReason?.label} />
          </div>
        )}
      </div>
    </>
  );
};

const ContactInformation = ({ contact, title }) => {
  const translate = useTranslate();
  return (
    <>
      <h3 className={styles.title}>{title || translate("CONTACT_PERSON")}</h3>
      <Label label={translate("FIRST_NAME")} value={contact?.firstName} />
      <Label label={translate("LAST_NAME")} value={contact?.surname} />
      {contact?.phoneNo && <Label label={translate("PHONE_NO")} value={contact?.phoneNo} />}
      {contact?.email && <Label label={translate("EMAIL")} value={contact?.email} />}
    </>
  );
};

UserInformation.propTypes = {
  title: PropTypes.string,
  receiver: PropTypes.object,
  showDeregistrationReason: PropTypes.bool,
};

ContactInformation.propTypes = {
  contact: PropTypes.object,
  title: PropTypes.string,
};

RecieverInformation.propTypes = {
  title: PropTypes.string,
  customCssClass: PropTypes.string,
  receiverData: PropTypes.object,
};

export default RecieverInformation;
export { Label, UserInformation, ContactInformation };
