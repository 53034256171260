import PropTypes from "prop-types";
import { useCallback, useEffect, useRef } from "react";

const OnClickOutside = ({ call, children }) => {
  const node = useRef(null);

  const listener = useCallback(
    event => {
      if (node.current?.contains(event.target)) {
        return;
      }

      call();
    },
    [call],
  );

  useEffect(() => {
    document.addEventListener("mouseup", listener);

    return () => {
      document.removeEventListener("mouseup", listener);
    };
  }, [listener]);

  return <div ref={node}>{children}</div>;
};

OnClickOutside.propTypes = {
  call: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

export default OnClickOutside;
