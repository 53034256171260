import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Cross, CheckCircle, ErrorCircle, IconButton } from "@myloc/myloc-gui";
import styles from "./Snackbar.module.scss";

export const SEVERITY = { ERROR: "error", WARNING: "warning", INFO: "info", SUCCESS: "success" };
export const POSITION = { TOP: "top", BOTTOM: "bottom", RIGHT: "right" };

const Snackbar = ({ onClose, message, severity, position, duration, autoHide }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (message) {
      setVisible(true);
      if (autoHide) {
        const timeout = setTimeout(() => {
          setVisible(false);
          onClose();
        }, duration);
        return () => clearTimeout(timeout);
      }
    }
  }, [message, duration, autoHide, onClose]);

  let alertIcon;

  switch (severity) {
    case SEVERITY.WARNING:
      alertIcon = <ErrorCircle />;
      break;
    case SEVERITY.INFO:
      alertIcon = <ErrorCircle customCssClass={styles.info} />;
      break;
    case SEVERITY.SUCCESS:
      alertIcon = <CheckCircle size="20" customCssClass={styles.check} />;
      break;
    default:
      alertIcon = <ErrorCircle />;
      break;
  }

  const handleClose = () => {
    setVisible(false);
    onClose();
  };

  return (
    <>
      {visible && (
        <section className={classNames(styles.snackbar, styles[position])}>
          <article className={classNames(styles.toast, styles[severity], styles[position])}>
            <figure className={styles.icon}>{alertIcon}</figure>
            <p className={styles.message}>{message}</p>
            <IconButton onClick={handleClose} customCssClass={styles.closeBtn}>
              <Cross size="24" color="currentColor" />
            </IconButton>
          </article>
        </section>
      )}
    </>
  );
};

Snackbar.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string,
  severity: PropTypes.oneOf([SEVERITY.ERROR, SEVERITY.WARNING, SEVERITY.INFO, SEVERITY.SUCCESS]),
  position: PropTypes.oneOf([POSITION.TOP, POSITION.BOTTOM, POSITION.RIGHT]),
  duration: PropTypes.number,
  autoHide: PropTypes.bool,
};

Snackbar.defaultProps = {
  severity: "error",
  position: "bottom",
  duration: 5000,
  autoHide: true,
};

export default Snackbar;
