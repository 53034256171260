import PropTypes from "prop-types";
import { File, FileExcel, FileImage, FilePdf, FileWord } from "../../../../assets/icons";

const Type = {
  DOC: "doc",
  DOCX: "docx",
  JPEG: "jpeg",
  JPG: "jpg",
  PDF: "pdf",
  PNG: "png",
  UNKNOWN: "",
  XLS: "xls",
  XLSX: "xlsx",
};

export function getFileType(fileName) {
  const index = fileName?.lastIndexOf(".");
  return index > 0 ? fileName.slice(index + 1).toLowerCase() : Type.UNKNOWN;
}

const FileIcon = ({ fileType, fileName, size, color, customCssClass }) => {
  const type = fileType ? fileType.toLowerCase() : getFileType(fileName);

  switch (type) {
    case Type.DOC:
    case Type.DOCX:
      return <FileWord size={size} color={color} customCssClass={customCssClass} />;
    case Type.JPEG:
    case Type.JPG:
    case Type.PNG:
      return <FileImage size={size} color={color} customCssClass={customCssClass} />;
    case Type.PDF:
      return <FilePdf size={size} color={color} customCssClass={customCssClass} />;
    case Type.XLS:
    case Type.XLSX:
      return <FileExcel size={size} color={color} customCssClass={customCssClass} />;
    default:
      return <File size={size} color={color} customCssClass={customCssClass} />;
  }
};

export default FileIcon;

FileIcon.propTypes = {
  fileType: PropTypes.string,
  fileName: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
  customCssClass: PropTypes.string,
};
