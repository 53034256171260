import { Button, Form, TextArea } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";

import { useTranslate } from "../../language/i18n";
import orderService from "../../services/order/orderService";

import { useParams } from "react-router";
import ClientHoldingList from "../shared/ClientHoldingList/ClientHoldingList";
import RadioButtonGroup from "../shared/RadioButton/RadioButtonGroup";
import styles from "./Aid.module.scss";

const MAX_SELECTABLE = 1;

function Aid({ onNext, data, setData }) {
  const translate = useTranslate();

  const order = useSelector(state => state.appData.order);
  const currentActiveStep = useSelector(state => state.appData.currentStep);

  const [isLoading, setLoading] = useState(false);
  const [type, setType] = useState(!data.product ? "NEW" : data.isNewItem ? "NEW" : "EXISTING");
  const [selectedProduct, setSelectedProduct] = useState(data?.product ? [data?.product] : []);
  const [textValue, setTextValue] = useState(type === "NEW" ? data?.product?.name ?? "" : data.text ?? "");
  const params = useParams();

  const submitHandler = async () => {
    setLoading(true);

    const { text, contentType, product, fittingType, currentStep } = data;
    const dataToSend = { text, contentType, product, fittingType, currentStep };
    dataToSend.currentStep = currentActiveStep;

    const response = await orderService.updateContent(params.content, dataToSend);
    setLoading(false);

    if (response.isOk()) {
      onNext(response.data);
    }
  };

  const selection = [
    {
      value: translate("FITTING_OF_NEW_AID"),
      id: "NEW",
    },
    {
      value: translate("FITTING_OF_EXISTING_AID"),
      id: "EXISTING",
    },
  ];

  const handleTypeChange = selected => {
    if (selected.id === "NEW") setData({ product: { name: textValue, supplier: {} }, text: "" });
    if (selected.id === "EXISTING") setData({ product: null, text: textValue });
    setSelectedProduct([]);
    setType(selected.id);
  };

  const onSelectProduct = product => {
    if (!product.length) {
      setSelectedProduct([]);
      setData({ product: null });
    }
    if (product.length) {
      setSelectedProduct([...product]);
      setData({ product: product[0] });
    }
  };

  const onTextChange = e => {
    if (type === "NEW") {
      setData({ product: { name: e.currentTarget.value, supplier: {} }, text: "" });
    } else {
      setData({ product: selectedProduct[0] || null, text: e.currentTarget.value });
    }
    setTextValue(e.currentTarget.value);
  };

  return (
    <Form onSubmit={submitHandler}>
      <RadioButtonGroup
        values={selection}
        onChange={handleTypeChange}
        selected={type}
        customCssClass={styles.radioButtons}
      />
      {type === "EXISTING" && (
        <ClientHoldingList
          onSelect={onSelectProduct}
          selected={selectedProduct}
          clientId={order?.receiver?.id}
          multiSelect
          maxSelectable={{ amount: MAX_SELECTABLE, text: translate("MAX_ONE_AID") }}
          onlyInventories
        />
      )}
      <TextArea
        name="text"
        label={translate("DESCRIBE_AID")}
        placeholder={translate("DESCRIBE_AID_DESCRIPTION")}
        customCssClass={styles.textarea}
        onChange={e => onTextChange(e)}
        value={textValue}
        required={type === "NEW" || (type === "EXISTING" && !data?.product?.productNumber)}
      />

      <Button
        type="submit"
        isLoading={isLoading}
        customCssClass={styles.submitBtn}
        disabled={MAX_SELECTABLE < selectedProduct.length}
      >
        {translate("NEXT")}
      </Button>
    </Form>
  );
}

Aid.propTypes = {
  product: PropType.object,
  text: PropType.string,
  onNext: PropType.func,
  data: PropType.object,
  setData: PropType.func,
  page: PropType.arrayOf(
    PropType.shape({
      description: PropType.string,
      id: PropType.string,
      inventoryNumber: PropType.string,
      productNumber: PropType.string,
      quantity: PropType.number,
      quantityDecimals: PropType.number,
      unit: PropType.string,
    }),
  ),
};

export default Aid;
