import PropTypes from "prop-types";
import { useMemo, useState, useCallback } from "react";
import { useTable, useSortBy } from "react-table";
import { useTranslate } from "../../../language/i18n";
import orderService from "../../../services/order/orderService";

import styles from "./Table.module.scss";
import { Link } from "react-router-dom";
import pages from "../../../utils/pages";
import Paginate from "../../shared/Pagination/Paginate";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import clientService from "../../../services/client/clientService";

const OrderTable = ({ selectedFilters, freeTextSearch, search, fromClient }) => {
  const translate = useTranslate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  if (!data) setData([]);

  const columns = useMemo(
    () => [
      {
        Header: "ORDER_DATE",
        accessor: "orderDate",
      },
      {
        Header: "ORDER_NUMBER",
        // eslint-disable-next-line react/display-name
        accessor: order => {
          if (!order?.orderNumber) return;

          return (
            <Link
              displayname={"OrdersTable"}
              to={{
                pathname: `${pages.ORDERS.PATH}/${order.id}`,
                state: {
                  id: order.id,
                  referrer: `${window.location.pathname}${window.location.search}`,
                },
              }}
            >
              {order.orderNumber}
            </Link>
          );
        },
      },
      {
        Header: fromClient ? "ORDERED_BY" : "RECEIVER",
        accessor: order => {
          if (fromClient ? !order?.responsible : !order?.receiver?.type) return;
          if (fromClient) {
            return `${order.responsible.firstName} ${order.responsible.surname}`;
          } else {
            return order.receiver.type === "FACILITY"
              ? `${order.receiver.value} - ${order.receiver.label}`
              : `${order.receiver.firstName} ${order.receiver.surname}`;
          }
        },
      },
      {
        Header: "STATUS",
        accessor: order => translate(`ORDER_STATUS_${order.status}`),
      },
    ],
    [translate, fromClient],
  );

  const getProvider = useCallback(
    filter => {
      if (!fromClient) {
        return orderService.getOrders({
          status: "20:>=;99:<=",
          sort: "orderDate:desc",
          ...selectedFilters,
          ...filter,
          freeTextSearch,
        });
      } else {
        return clientService.getOrders(fromClient, {
          status: "20:>=;99:<=",
          sort: "orderDate:desc",
          ...filter,
        });
      }
    },
    [freeTextSearch, selectedFilters, fromClient],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useSortBy);

  return (
    <Paginate
      setIsLoading={setIsLoading}
      onChange={setData}
      provider={useCallback(filter => getProvider(filter), [getProvider])}
      loadOnMount={!search}
      errorMessage={{ show: !isLoading, message: "" }}
    >
      <div className={styles.orderWrapper}>
        {isLoading ? (
          <LoadingSpinner title="ORDERS_LOADING" />
        ) : (
          <table {...getTableProps()} className={styles.table}>
            <thead>
              <tr>
                {headerGroups[0].headers.map(column => (
                  <th
                    key={column.accessor}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={styles[column.className]}
                  >
                    {translate(column.render("Header"))}
                    <span className={styles.arrow}> {column.isSorted ? (column.isSortedDesc ? "▿" : "▵") : ""}</span>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr key={row.id} {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.column.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </Paginate>
  );
};

OrderTable.propTypes = {
  selectedFilters: PropTypes.object,
  freeTextSearch: PropTypes.string,
  search: PropTypes.bool,
  fromClient: PropTypes.string,
  isReceiverMenu: PropTypes.bool,
};

export default OrderTable;
