import { Button, THEME } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "../../../../language/i18n";
import messageService from "../../../../services/message/messageService";
import { getTimestamp } from "../../../../utils/DateUtil";
import FileDownloadList from "../../../shared/File/FileDownload/FileDownload";
import Label from "../../../shared/Label/Label";
import { MESSAGE_TYPE } from "../MessageTypes";
import NewMessageModal from "../NewMessage/NewMessageModal";
import styles from "./MessageDetail.module.scss";

const MessageDetail = ({ messageId, setMessages, messageType }) => {
  const translate = useTranslate();
  const user = useSelector(state => state.appData.user);
  const [replyToMessageModal, setReplyToMessageModal] = useState(false);
  const [activeMessageType, setActiveMessageType] = useState();
  const [message, setMessage] = useState();
  const setIsRead = decodeURIComponent(user?.information?.id) === message?.receiver?.id && !message?.receiver.isRead;

  const setMessageToRead = useCallback(async () => {
    const data = { action: "READ" };
    const response = await messageService.performMessageAction(
      messageType === MESSAGE_TYPE.SENT ? message.receiver.messageId : message.id,
      data,
    );

    if (response.isOk()) {
      setMessages(prev => {
        const index = prev.findIndex(m => m.id === message.id);
        const newMessages = [...prev];
        newMessages[index] = response.data;
        return newMessages;
      });
    }
  }, [message, setMessages, messageType]);

  useEffect(() => {
    if (messageType != activeMessageType) {
      setActiveMessageType(messageType);
    }
  }, [messageType, activeMessageType]);

  useEffect(() => {
    if (setIsRead && messageType === activeMessageType) setMessageToRead();
  }, [setIsRead, setMessageToRead, activeMessageType, messageType]);

  useEffect(() => {
    messageService.getMessage(messageId).then(response => {
      setMessage(response.data);
    });
  }, [messageId]);

  if (!message) return null;

  return (
    <div className={styles.messageDetail}>
      <div className={styles.header}>
        <div>
          <span className={styles.title}>{`${message.sender.firstName} ${message.sender.surname}`}</span>
          <Label label={translate("FROM")} value={message.sender.id} inline />
          <Label label={translate("TO")} value={message.receiver.id} inline />
        </div>
        <div className={styles.date}>{getTimestamp(message.sentDate)}</div>
      </div>
      <div className={styles.message}>
        <p className={styles.subject}>{message.subject}</p>
        <div className={styles.body}>{message.body}</div>
      </div>
      <div className={styles.filesAndButton}>
        {message.attachments && <FileDownloadList files={message.attachments} />}
        <div className={styles.button}>
          <Button theme={THEME.SECONDARY} onClick={() => setReplyToMessageModal(true)}>
            {translate("REPLY")}
          </Button>
        </div>
      </div>
      {replyToMessageModal && (
        <NewMessageModal visible={replyToMessageModal} setVisible={setReplyToMessageModal} replyToMessage={message} />
      )}
    </div>
  );
};

MessageDetail.propTypes = {
  messageId: PropType.string,
  setMessages: PropType.func,
  messageType: PropType.string,
};

export default MessageDetail;
