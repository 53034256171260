import PropType from "prop-types";
import { Link } from "react-router-dom";

import { withPage } from "@myloc/myloc-utils";
import { useTranslate } from "../../../language/i18n";
import { CLIENT_TYPE, CONTENT_TYPE } from "../../../utils/constants";
import pages from "../../../utils/pages";

import Page from "../../shared/Page/Page";

//The whole order data object is sent in to this page
//Content is not decided yet so this is only a dummypage for now.
function OrderConfirmationPage({ orderNumber, receiver, content }) {
  const translate = useTranslate();
  const toReciever =
    receiver?.type === CLIENT_TYPE.USER ? (
      <span>
        {receiver.firstName} {receiver.surname}
      </span>
    ) : (
      <span>
        {receiver?.value}-{receiver?.label}
      </span>
    );
  const productPickup = content.some(item => item.contentType === CONTENT_TYPE.PRODUCT_PICKUP);

  return (
    <Page title={translate(pages.ORDER_CONFIRMATION.NAME)}>
      <p>
        Din beställning <strong>{orderNumber}</strong> till <strong>{toReciever}</strong> är skickad för behandling.
      </p>
      {productPickup && (
        <>
          <p>{translate("ORDER_CONFIRMATION_PICKUP_IN_BASE_STORAGE")}</p>
          <p>
            Arbetsuppgift finns nu i <Link to={pages.MY_TASKS.PATH}>din arbetslista</Link> för att rapportera utlämning
            till brukare
          </p>
        </>
      )}
    </Page>
  );
}

OrderConfirmationPage.propTypes = {
  orderId: PropType.string,
  orderNumber: PropType.number,
  receiver: PropType.object,
  content: PropType.array,
};

export default withPage(OrderConfirmationPage);
