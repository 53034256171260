import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./RadioButton.module.scss";

function RadioButton({ label, checked, name, onChange, disabled, id, customCssClass }) {
  return (
    <label className={classNames(styles.radioButton, disabled && styles.disabled, customCssClass)}>
      {label}
      <input id={id} type="radio" checked={checked} name={name} onChange={onChange} disabled={disabled} />
      <span className={styles.checkmark}></span>
    </label>
  );
}

RadioButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  customCssClass: PropTypes.string,
};

export default RadioButton;
