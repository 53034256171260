import {
  LOADING,
  LOADING_FREEZE,
  REGISTRATION_SUCCESS,
  REGISTRATION_COMPLETED,
  REGISTRATION_RESET,
} from "./commonVariables";

const initialState = {
  loading: false,
  loadingFreeze: false,
  registrationSuccess: false,
  registrationCompleted: false,
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case LOADING_FREEZE:
      return {
        ...state,
        loadingFreeze: action.loadingFreeze,
      };
    case REGISTRATION_SUCCESS:
      return {
        ...state,
        registrationSuccess: true,
      };

    case REGISTRATION_COMPLETED:
      return {
        ...state,
        registrationCompleted: true,
      };
    case REGISTRATION_RESET:
      return {
        ...state,
        registrationCompleted: false,
        registrationSuccess: false,
      };
    default:
      return state;
  }
};

export default commonReducer;
