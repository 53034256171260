import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useTranslate } from "../../../language/i18n";
import { openOrderPopup } from "../../../reducers/dialog/dialogAction";
import pages from "../../../utils/pages";
import { CircleAddFilled, Products, Menu, User } from "../../../assets/icons";
import { Button, THEME } from "@myloc/myloc-gui";
import styles from "./MobileMenu.module.scss";
import MenuDrawer from "./MenuDrawer/MenuDrawer";
import Search from "../../../assets/icons/Search";
import SearchDrawer from "./SearchDrawer/SearchDrawer";

function MobileMenu() {
  const translate = useTranslate();
  const userLoggedIn = !!useSelector(state => state.session.sessionId);
  const order = useSelector(state => state.appData.order);
  const [menuVisible, setMenuVisible] = useState("");

  const onItemClick = e => {
    e.preventDefault, menuVisible && toggleDrawer();
  };

  const toggleDrawer = menu => {
    setMenuVisible(menuVisible === menu ? "" : menu);
  };

  return (
    <>
      {userLoggedIn && (
        <>
          <section className={styles.menu}>
            <button
              className={classNames(styles.link, menuVisible && styles.active)}
              onClick={e => {
                e.preventDefault;
                toggleDrawer("SEARCH");
              }}
            >
              <Search />
              <span className={styles.text}>{translate("SEARCH")}</span>
            </button>

            <NavLink
              to={pages.PRODUCTS.PATH}
              className={styles.link}
              activeClassName={styles.active}
              onClick={e => onItemClick(e)}
            >
              <Products />
              <span className={styles.text}>{translate(pages.PRODUCTS.NAME)}</span>
            </NavLink>

            <Button
              theme={THEME.PLAIN}
              customCssClass={styles.link}
              onClick={e => {
                onItemClick(e), openOrderPopup({ receiverType: order?.receiver?.type });
              }}
            >
              <CircleAddFilled />
              <span className={styles.text}>{translate(order?.receiver?.type ? "ADD_TO_ORDER" : "NEW_ORDER")}</span>
            </Button>

            <NavLink
              to={pages.MY_MYLOC.PATH}
              className={styles.link}
              activeClassName={styles.active}
              onClick={e => onItemClick(e)}
            >
              <User />
              <span className={styles.text}>{translate("MY_MYLOC")}</span>
            </NavLink>

            <button
              className={classNames(styles.link, menuVisible && styles.active)}
              onClick={e => {
                e.preventDefault;
                toggleDrawer("MENU");
              }}
            >
              <Menu />
              <span className={styles.text}>{translate("MENU")}</span>
            </button>
          </section>

          <MenuDrawer visible={menuVisible === "MENU"} onMenuLinkClick={() => toggleDrawer("MENU")} />
          <SearchDrawer visible={menuVisible === "SEARCH"} onMenuLinkClick={() => toggleDrawer("SEARCH")} />
        </>
      )}
    </>
  );
}

export default MobileMenu;
