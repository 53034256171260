import PropTypes from "prop-types";
import { CLIENT_TYPE } from "../../../../utils/constants";
import Counter from "../../../shared/Counter/Counter";
import { DELIVERY_LOCATION } from "./productConstants";

const ProductQuantity = ({ isInventory, receiver, setQuantity, receiverLocation, isActiveReferencedOrder }) => {
  const disabled =
    !isActiveReferencedOrder &&
    (!receiver ||
      (receiver.type !== CLIENT_TYPE.BASE_STORAGE &&
        isInventory &&
        receiverLocation !== DELIVERY_LOCATION.DELIVERY.id));
  return <Counter disabled={disabled} startValue={1} min={1} onChange={setQuantity} />;
};

ProductQuantity.propTypes = {
  isInventory: PropTypes.bool,
  receiver: PropTypes.shape({ type: PropTypes.string }),
  receiverLocation: PropTypes.string,
  setQuantity: PropTypes.func,
  isActiveReferencedOrder: PropTypes.bool,
};

export default ProductQuantity;
