import { Button, THEME } from "@myloc/myloc-gui";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./Wizard.module.scss";

function Wizard({ steps, activeStep, setActiveStep, stepsCssClass, contentCssClass }) {
  if (!steps || !(activeStep >= 0)) return null;

  return (
    <>
      <section
        className={classNames(styles.steps, stepsCssClass)}
        style={{
          gridTemplateColumns: `repeat(${steps.length}, 1fr)`,
        }}
      >
        {steps.map((step, key) => (
          <Button
            key={key}
            theme={THEME.PLAIN}
            customCssClass={classNames(
              styles.step,
              activeStep === key && styles.currentStep,
              activeStep > key && styles.finishedStep,
            )}
            disabled={activeStep < key}
            onClick={() => setActiveStep(key)}
          >
            <div className={styles.indicator}>{key + 1}</div>
            <p className={styles.indicatorText}>{step.titleShort}</p>
          </Button>
        ))}
      </section>
      <section className={classNames(styles.content, contentCssClass)}>
        <h3 className={styles.subTitle}>{steps[activeStep].title}</h3>
        {steps[activeStep].children}
      </section>
    </>
  );
}

Wizard.propTypes = {
  title: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      titleShort: PropTypes.string,
      children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
    }),
  ),
  activeStep: PropTypes.number,
  setActiveStep: PropTypes.func,
  stepsCssClass: PropTypes.string,
  contentCssClass: PropTypes.string,
};

export default Wizard;
