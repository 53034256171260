import { Button } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslate } from "../../../language/i18n";
import AccordionItem from "../AccordionItem/AccordionItem";
import Checkbox from "../Checkbox/Checkbox";
import styles from "./InventoryAccessories.module.scss";
import InventoryAccessoryRow from "./InventoryAccessoryRow/InventoryAccessoryRow";
import RemoveAccessoryModal from "./RemoveAccessoryModal/RemoveAccessoryModal";

const InventoryAccessories = ({ fixedAssetId, accessories }) => {
  const translate = useTranslate();
  const [selected, setSelected] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const params = useParams();

  const toggleSelected = accessory => {
    if (!selected.length) setSelected(accessory);

    const exists = selected.find(selected => selected.id == accessory.id);
    const selection = exists ? selected?.filter(selected => selected.id !== accessory.id) : [...selected, accessory];
    setSelected(selection);
  };

  const isSelected = accessory => {
    if (!selected.length) return false;

    return selected.find(selected => selected.id == accessory.id);
  };

  const handleQuantityChange = (accessory, quantity) => {
    if (!selected) return;

    if (selected.some(selected => selected.id === accessory.id)) {
      const updatedSelected = selected.filter(selected => selected.id !== accessory.id);
      updatedSelected.push({ ...accessory, quantity });
      setSelected([...updatedSelected]);
    }
  };

  return (
    <AccordionItem title={translate("ACCESSORIES")}>
      <Checkbox
        onChange={() => (selected?.length === accessories.length ? setSelected([]) : setSelected([...accessories]))}
        checked={selected?.length === accessories?.length}
        label={translate("SELECT_ALL")}
      />
      {accessories?.map(accessory => (
        <InventoryAccessoryRow
          key={accessory.id}
          accessory={accessory}
          isSelected={isSelected(accessory)}
          onToggle={toggleSelected}
          handleQuantityChange={handleQuantityChange}
        />
      ))}
      <div className={styles.buttons}>
        <Button disabled={!selected?.length} onClick={() => setModalVisible(visible => !visible)}>
          {translate("REMOVE")}
        </Button>
      </div>
      <RemoveAccessoryModal
        clientId={params.id}
        holdingId={params.holding}
        fixedAssetId={fixedAssetId}
        visible={modalVisible}
        selectedAccessories={selected}
        onClose={() => setModalVisible(visible => !visible)}
      />
    </AccordionItem>
  );
};

InventoryAccessories.propTypes = {
  fixedAssetId: PropType.string,
  accessories: PropType.array.isRequired,
};
export default InventoryAccessories;
