import PropTypes from "prop-types";
import classNames from "classnames";
import { IconButton, Button, THEME } from "@myloc/myloc-gui";
import { CircleAddFilled, Pen } from "../../../../assets/icons";
import SelectField from "../../SelectField/SelectField";
import styles from "./Selector.module.scss";

function Selector({
  options,
  selected,
  label,
  displayValue,
  listTemplate,
  addNewButtonTitle,
  customCssClass,
  displaySelected,
  onAdd,
  onEdit,
  onChange,
  required,
}) {
  return (
    <div className={classNames(styles.selector, customCssClass)}>
      <SelectField
        label={label}
        options={options}
        selectedId={selected?.id}
        onSelect={onChange}
        customCssClass={styles.selectField}
        customSettings={{ template: listTemplate, display: displayValue }}
        required={required}
      />
      {onAdd && (
        <Button theme={THEME.PLAIN_PRIMARY} customCssClass={styles.addNewBtn} onClick={onAdd}>
          <CircleAddFilled />
          <span className={styles.text}>{addNewButtonTitle}</span>
        </Button>
      )}
      {selected && (
        <section className={classNames(styles.selected)}>
          {displaySelected(selected)}
          {selected.isEditable && onEdit && (
            <IconButton onClick={onEdit} className={styles.editBtn}>
              <Pen />
            </IconButton>
          )}
        </section>
      )}
    </div>
  );
}

const selectionPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isEditable: PropTypes.bool,
});

Selector.propTypes = {
  options: PropTypes.arrayOf(selectionPropType),
  selected: selectionPropType,

  label: PropTypes.string,
  displayValue: PropTypes.string,
  addNewButtonTitle: PropTypes.string,
  customCssClass: PropTypes.string,

  listTemplate: PropTypes.func,
  displaySelected: PropTypes.func,

  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

export default Selector;
