import { FormData } from "@myloc/myg-forms";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslate } from "../../../language/i18n";
import Label from "../Label/Label";
import styles from "./OrderInfoSections.module.scss";

const InfoSection = ({ title, children }) => (
  <article className={classNames(styles.orderSection)}>
    <h4 className={styles.title}>{title}</h4>
    <ul>{children}</ul>
  </article>
);

const Row = ({ title, content, noSpacing }) => (
  <li className={classNames(styles.row, noSpacing && styles.noSpacing)}>
    {title && <span className={styles.textTitle}>{title}: </span>}
    {content && <span className={styles.text}>{content}</span>}
  </li>
);

const AdditionalInfoSection = data => {
  const translate = useTranslate();
  return (
    <InfoSection title={translate("ADDITIONAL_INFO")}>
      <FormData form={data.form} />
    </InfoSection>
  );
};

const AddressSection = ({ address, contact, title = "DELIVERY_ADDRESS_AND_CONTACT", deliveryMethod }) => {
  const translate = useTranslate();
  return (
    <article className={classNames(styles.orderSection, styles.address)}>
      {title && <h4 className={styles.title}>{translate(title)}</h4>}
      {address && (
        <>
          <p className={styles.textTitle}>{translate("ADDRESS")}:</p>
          <p className={styles.text}>
            {address.description && (
              <span>
                {address?.facility?.category?.label === translate("BASE_STORAGE") && address?.facility?.value + " - "}
                {address?.facility?.category?.label === translate("BASE_STORAGE")
                  ? address?.facility?.label
                  : address?.description}
              </span>
            )}
            {address.careOf && <span>{address.careOf}</span>}
            <span>{address.street}</span>
            <span>
              {address.postalNo} {address.city}
            </span>
            {deliveryMethod && <Label label={translate("DELIVERY_METHOD")} value={deliveryMethod.name} inline />}
          </p>
        </>
      )}
      {contact && (
        <>
          <p className={styles.textTitle}>{translate("CONTACT_PERSON")}:</p>
          <p className={styles.text}>
            {contact.relation && <span>{contact.relation}</span>}
            <span>
              {contact.firstName} {contact.surname}
            </span>
            <span>{contact.phoneNo}</span>
            <span>{contact.email}</span>
          </p>
        </>
      )}
    </article>
  );
};

InfoSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  customCssClass: PropTypes.string,
};

Row.propTypes = {
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.array, PropTypes.node, PropTypes.string]),
  noSpacing: PropTypes.bool,
};

AdditionalInfoSection.propTypes = {
  data: PropTypes.array,
};

AddressSection.propTypes = {
  title: PropTypes.string,
  address: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    description: PropTypes.string,
    street: PropTypes.string,
    careOf: PropTypes.string,
    city: PropTypes.string,
    postalNo: PropTypes.string,
    facility: PropTypes.object,
  }),
  contact: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    relation: PropTypes.string,
    firstName: PropTypes.string,
    surname: PropTypes.string,
    email: PropTypes.string,
    phoneNo: PropTypes.string,
  }),
  deliveryMethod: PropTypes.object,
};

export { InfoSection, Row, AddressSection, AdditionalInfoSection };
