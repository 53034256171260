import classNames from "classnames";
import format from "date-fns/format";
import PropType from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Problem from "../../../assets/icons/Problem";
import { useTranslate } from "../../../language/i18n";
import clientService from "../../../services/client/clientService";
import { useMobile, useTablet } from "../../../utils/viewport";
import Label from "../../shared/Label/Label";
import Checkbox from "../Checkbox/Checkbox";
import Counter from "../Counter/Counter";
import { pageSize } from "../Pagination/PageSize";
import Paginate from "../Pagination/Paginate";
import RadioButton from "../RadioButton/RadioButton";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import styles from "./ClientHoldingList.module.scss";

function ClientHoldingList({
  onSelect,
  clientId,
  selected = [],
  singleSelect,
  multiSelect,
  readOnly,
  link,
  amountChangeable,
  onAmountChange,
  provider,
  referrer,
  maxSelectable,
  onlyInventories,
  skipSearch = false,
  disableAllocated,
  isReceiverMenu,
  isHistory,
}) {
  const translate = useTranslate();
  const [data, setData] = useState([]);
  const [holdingList, setHoldingList] = useState([]);
  const [holdingHistoryList, setHoldingHistoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (isHistory) {
      setHoldingHistoryList(data);
    } else {
      setHoldingList(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const getProvider = useCallback(
    filter => {
      if (provider) {
        return provider(filter);
      } else if (isHistory) {
        return clientService.getHoldingHistory({ clientId, filter: { ...filter, sort: "handedOverDate:desc" } });
      } else {
        return clientService.getHolding({
          clientId,
          filter: { ...filter, sort: "handedOverDate:desc" },
          onlyInventory: onlyInventories,
        });
      }
    },
    [provider, isHistory, clientId, onlyInventories],
  );

  const toggleSelected = row => {
    const exists = selected?.some(item => item?.id === row.id);
    const selection = exists ? selected?.filter(item => item.id !== row.id) : [...selected, row];
    onSelect(selection);
  };

  const getQuantity = row => {
    const item = data?.find(item => item.id === row.id);
    const maxQuantity = item?.maxQuantity >= 0 ? item?.maxQuantity : item?.quantity - item?.allocatedQuantity;
    return maxQuantity;
  };

  const itemsAvailable = data?.filter(item => item.maxQuantity > 0 || item.quantity - item.allocatedQuantity > 0);
  return (
    <>
      {multiSelect && !maxSelectable?.amount && holdingList?.length > 1 && (
        <Checkbox
          onChange={() => (selected.length === itemsAvailable.length ? onSelect([]) : onSelect([...itemsAvailable]))}
          checked={selected?.length === itemsAvailable?.length}
          label={translate("SELECT_ALL")}
          customCssClass={styles.selectAll}
        />
      )}
      <Paginate
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        onChange={setData}
        provider={getProvider}
        skipSearch={skipSearch}
        defaultPageSize={pageSize.LARGE}
      >
        <section className={styles.results}>
          {isLoading ? (
            <LoadingSpinner title="HOLDINGS_LOADING" />
          ) : holdingHistoryList.length ? (
            holdingHistoryList?.map(row => {
              return <HistoryReadOnlyRow row={row} key={row.id} />;
            })
          ) : (
            holdingList?.map(row => {
              if (readOnly) {
                return <ReadOnlyRow row={row} link={link} key={row.id} referrer={referrer} />;
              }
              if (multiSelect) {
                return (
                  <MultiSelectRow
                    row={row}
                    link={link}
                    selected={selected}
                    amountChangeable={amountChangeable}
                    onAmountChange={onAmountChange}
                    toggleSelected={toggleSelected}
                    getQuantity={getQuantity}
                    key={row.id}
                    referrer={referrer}
                    maxSelectableAmount={maxSelectable?.amount}
                    disableAllocated={disableAllocated}
                    isReceiverMenu={isReceiverMenu}
                  />
                );
              }
              if (singleSelect) {
                return (
                  <SingleSelectRow
                    row={row}
                    link={link}
                    selected={selected}
                    amountChangeable={amountChangeable}
                    onAmountChange={onAmountChange}
                    onSelect={onSelect}
                    getQuantity={getQuantity}
                    key={row.id}
                    referrer={referrer}
                  />
                );
              }
            })
          )}
        </section>
      </Paginate>
      {maxSelectable?.amount < selected.length && <p className={styles.error}>{maxSelectable.text}</p>}
    </>
  );
}

const ReadOnlyRow = ({ row, link, referrer }) => {
  const location = useLocation();
  const isMobile = useMobile();

  return (
    <article className={styles.item}>
      {isMobile ? (
        <div className={styles.mobileSection}>
          <p className>
            {link ? (
              <Link to={{ pathname: `${location.pathname}/${row.id}`, state: { referrer: referrer } }}>{row.name}</Link>
            ) : (
              row.name
            )}
            {row.hasDeviation && <Problem customCssClass={styles.deviation} />}
          </p>
          <p>
            {row.quantity} {row.unit}
          </p>
        </div>
      ) : (
        <section className={classNames(styles.titleSection, row.deviation && styles.titleDeviation)}>
          <p className={styles.itemTitle}>
            {link ? (
              <Link to={{ pathname: `${location.pathname}/${row.id}`, state: { referrer: referrer } }}>{row.name}</Link>
            ) : (
              row.name
            )}
            {row.hasDeviation && <Problem customCssClass={styles.deviation} />}
          </p>

          <p>
            {row.quantity} {row.unit}
          </p>
        </section>
      )}

      <InfoSection row={row} />
    </article>
  );
};

const HistoryReadOnlyRow = ({ row }) => {
  const translate = useTranslate();
  const positiveQuantity = row.quantity > 0 ? row.quantity : row.quantity * -1;
  return (
    <article className={classNames(styles.item)}>
      <section className={classNames(styles.titleSection, row.deviation && styles.titleDeviation)}>
        <p className={styles.itemTitle}>{row.itemDescription}</p>
        <p>
          {positiveQuantity} {row.unit ? row.unit : translate("UNITS_FULL")}
        </p>
      </section>
      <HistoryInfoSection row={row} />
    </article>
  );
};

const MultiSelectRow = ({
  selected,
  row,
  amountChangeable,
  onAmountChange,
  toggleSelected,
  getQuantity,
  link,
  referrer,
  maxSelectableAmount,
  disableAllocated,
  isReceiverMenu,
}) => {
  const location = useLocation();
  const isSelected = selected?.some(item => item?.id === row.id);
  const error = isSelected && maxSelectableAmount < selected.length;
  const maxQuantity = row.maxQuantity >= 0 ? row.maxQuantity : row.quantity - row.allocatedQuantity;
  const isMobile = useMobile();

  const isDisabled = () => {
    if (row.handedOverInformation != null) return true;
    if (disableAllocated) return maxQuantity === 0 ?? false;
    return false;
  };

  const disabled = isDisabled();

  return (
    <article className={classNames(styles.item, error && styles.error, disabled && styles.disabled)} key={row.id}>
      <Checkbox onChange={() => toggleSelected(row)} checked={isSelected} disabled={disabled} />
      {isMobile ? (
        <div className={styles.mobileSection}>
          <p className>
            {link ? (
              <Link to={{ pathname: `${location.pathname}/${row.id}`, state: { referrer: referrer } }}>{row.name}</Link>
            ) : (
              row.name
            )}
            {row.hasDeviation && <Problem customCssClass={styles.deviation} />}
          </p>
          <p>
            {row.quantity} {row.unit}
          </p>
        </div>
      ) : (
        <section className={classNames(styles.titleSection, row.deviation && styles.titleDeviation)}>
          <p className={styles.itemTitle}>
            {link ? (
              <Link
                to={{
                  pathname: `${location.pathname}/${row.id}`,
                  state: { referrer: referrer, isReceiverMenu: isReceiverMenu },
                }}
              >
                {row.name}
              </Link>
            ) : (
              row.name
            )}
            {row.hasDeviation && <Problem customCssClass={styles.deviation} />}
          </p>

          {amountChangeable ? (
            <>
              {isSelected ? (
                <Counter
                  onChange={num => onAmountChange(row, num)}
                  min={1}
                  max={maxQuantity}
                  startValue={getQuantity(row)}
                  customCssClass={classNames(styles.counter, isSelected && styles.selected)}
                />
              ) : (
                <p>
                  {row.quantity} {row.unit}
                </p>
              )}
            </>
          ) : (
            <p>
              {row.quantity} {row.unit}
            </p>
          )}
        </section>
      )}

      <InfoSection row={row} />
    </article>
  );
};

const SingleSelectRow = ({
  selected,
  row,
  onSelect,
  amountChangeable,
  onAmountChange,
  getQuantity,
  link,
  referrer,
}) => {
  const location = useLocation();
  const isSelected = selected.some(item => item?.id === row.id);
  const isMobile = useMobile();

  return (
    <article className={styles.item} key={row.id}>
      <RadioButton
        id={row.id}
        name="aid"
        onChange={() => {
          !isSelected ? onSelect(row) : onSelect();
        }}
        customCssClass={styles.check}
        checked={isSelected}
      />
      {isMobile ? (
        <div className={styles.mobileSection}>
          <p className>
            {link ? (
              <Link to={{ pathname: `${location.pathname}/${row.id}`, state: { referrer: referrer } }}>{row.name}</Link>
            ) : (
              row.name
            )}
            {row.hasDeviation && <Problem customCssClass={styles.deviation} />}
          </p>
          <p>
            {row.quantity} {row.unit}
          </p>
        </div>
      ) : (
        <section className={classNames(styles.titleSection, row.deviation && styles.titleDeviation)}>
          <p className={styles.itemTitle}>
            {link ? (
              <Link to={{ pathname: `${location.pathname}/${row.id}`, state: { referrer: referrer } }}>{row.name}</Link>
            ) : (
              row.name
            )}
            {row.hasDeviation && <Problem customCssClass={styles.deviation} />}
          </p>

          {amountChangeable ? (
            <>
              {isSelected ? (
                <Counter
                  onChange={num => onAmountChange(row, num)}
                  min={1}
                  max={row.quantity}
                  startValue={getQuantity(row)}
                  customCssClass={classNames(styles.counter, isSelected && styles.selected)}
                />
              ) : (
                <p>
                  {row.quantity} {row.unit}
                </p>
              )}
            </>
          ) : (
            <p>
              {row.quantity} {row.unit}
            </p>
          )}
        </section>
      )}

      <InfoSection row={row} />
    </article>
  );
};

const InfoSection = ({ row }) => {
  const translate = useTranslate();
  const isTablet = useTablet();
  return (
    <section className={styles.infoSection}>
      {isTablet ? (
        <>
          <div>
            <Label inline label={translate("PRODUCT_NUMBER_SHORT")} value={row.productNumber} />
            <Label inline label={translate("ARTICLE_CLASS")} value={row?.itemClass?.label} />
          </div>
          <div className={styles.right}>
            {row?.allocatedQuantity > 0 && (
              <Label inline label={translate("HOLDING_RESERVED")} value={`${row.allocatedQuantity} ${row.unit}`} />
            )}
            {row.handedOverInformation && (
              <p className={(styles.text, styles.highlightedYellow)}> {row.handedOverInformation} </p>
            )}
            {row.handedOverDate && <Label inline label={translate("DELIVERED_DATE")} value={row.handedOverDate} />}
            <Label inline label={translate("PRODUCT_OWNER")} value={row.goodsOwner?.label} />
          </div>
          <div>
            <Label inline label={translate("INVENTORY_NUMBER_SHORT")} value={row.inventoryNumber} />
            <Label inline label={translate("BATCH")} value={row.batch} />
          </div>
          {row.status && (
            <div>
              <div>{row.status}</div>
            </div>
          )}
        </>
      ) : (
        <>
          <div>
            <Label inline label={translate("PRODUCT_NUMBER_SHORT")} value={row.productNumber} />
            <Label inline label={translate("ARTICLE_CLASS")} value={row?.itemClass?.label} />
          </div>
          <div>
            <Label inline label={translate("INVENTORY_NUMBER_SHORT")} value={row.inventoryNumber} />
            <Label inline label={translate("BATCH_NO")} value={row.batch} />
          </div>
          <div>
            <div>{row.status}</div>
          </div>
          <div className={styles.right}>
            {row?.allocatedQuantity > 0 && (
              <Label inline label={translate("HOLDING_RESERVED")} value={`${row.allocatedQuantity} ${row.unit}`} />
            )}
            {row.handedOverInformation && (
              <p className={(styles.text, styles.highlightedYellow)}> {row.handedOverInformation} </p>
            )}
            {row.handedOverDate && <Label inline label={translate("DELIVERED_DATE")} value={row.handedOverDate} />}
            <Label inline label={translate("PRODUCT_OWNER")} value={row.goodsOwner?.label} />
          </div>
        </>
      )}
    </section>
  );
};

const HistoryInfoSection = ({ row }) => {
  const translate = useTranslate();
  const isTablet = useTablet();
  const unitValuePositive = row.allocatedQuantity > 0 ? row.allocatedQuantity : row.allocatedQuantity * -1;
  const transactionDate = new Date(row.transactionTimestamp);
  const formattedDate = format(transactionDate, "yyyy-MM-dd");
  return (
    <section className={styles.infoSection}>
      {isTablet ? (
        <>
          <div>
            <Label inline label={translate("PRODUCT_NUMBER_SHORT")} value={row.fixedAssetNumber} />
            <Label inline label={translate("ARTICLE_CLASS")} value={row.itemClass} />
          </div>
          <div className={styles.right}>
            {row?.allocatedQuantity > 0 && (
              <Label inline label={translate("HOLDING_RESERVED")} value={`${row.allocatedQuantity} ${row.unit}`} />
            )}
            {row.handedOverInformation && (
              <p className={(styles.text, styles.highlightedYellow)}> {row.handedOverInformation} </p>
            )}
            {row.handedOverDate && <Label inline label={translate("DELIVERED_DATE")} value={row.handedOverDate} />}
          </div>
          <div>
            <Label inline label={translate("INVENTORY_NUMBER_SHORT")} value={row.fixedAssetNumber} />
            <Label inline label={translate("BATCH")} value={row.batchNumber} />
          </div>
          {row.status && (
            <div>
              <div>{row.status}</div>
            </div>
          )}
        </>
      ) : (
        <>
          <div>
            <Label inline label={translate("PRODUCT_NUMBER_SHORT")} value={row.itemNumber} />
            <Label inline label={translate("ARTICLE_CLASS")} value={row.itemClass} />
          </div>
          <div>
            <Label inline label={translate("INVENTORY_NUMBER_SHORT")} value={row.fixedAssetNumber} />
            <Label inline label={translate("BATCH_NO")} value={row.batchNumber} />
          </div>
          <div>
            <div>{row.status}</div>
          </div>
          <div>
            {row?.allocatedQuantity > 0 && (
              <Label inline label={translate("HOLDING_RESERVED")} value={`${unitValuePositive} ${row.unit}`} />
            )}
            {row.handedOverInformation && (
              <p className={(styles.text, styles.highlightedYellow)}> {row.handedOverInformation} </p>
            )}
            {row.transactionTimestamp && <Label inline label={translate("RETURNED")} value={formattedDate} />}
          </div>
        </>
      )}
    </section>
  );
};
ClientHoldingList.propTypes = {
  onSelect: PropType.func,
  clientId: PropType.string,
  selected: PropType.arrayOf(
    PropType.shape({
      id: PropType.string,
      quantity: PropType.number,
    }),
  ),
  singleSelect: PropType.bool,
  multiSelect: PropType.bool,
  readOnly: PropType.bool,
  link: PropType.bool,
  amountChangeable: PropType.bool,
  onAmountChange: PropType.func,
  provider: PropType.func,
  referrer: PropType.string,
  maxSelectable: PropType.shape({
    amount: PropType.number,
    text: PropType.string,
  }),
  onlyInventories: PropType.bool,
  skipSearch: PropType.bool,
  disableAllocated: PropType.bool,
  isReceiverMenu: PropType.bool,
  isHistory: PropType.bool,
  holdingHistoryList: PropType.array,
};

Label.propTypes = {
  label: PropType.string,
  value: PropType.string,
};

ReadOnlyRow.propTypes = {
  row: PropType.object,
  link: PropType.bool,
  referrer: PropType.string,
};
HistoryReadOnlyRow.propTypes = {
  row: PropType.object,
  link: PropType.bool,
  referrer: PropType.string,
};

MultiSelectRow.propTypes = {
  selected: PropType.array,
  row: PropType.object,
  onSelect: PropType.func,
  amountChangeable: PropType.bool,
  onAmountChange: PropType.func,
  getQuantity: PropType.func,
  link: PropType.bool,
  toggleSelected: PropType.func,
  referrer: PropType.string,
  maxSelectableAmount: PropType.number,
  disableAllocated: PropType.bool,
  isReceiverMenu: PropType.bool,
};

SingleSelectRow.propTypes = {
  selected: PropType.array,
  row: PropType.object,
  onSelect: PropType.func,
  amountChangeable: PropType.bool,
  onAmountChange: PropType.func,
  getQuantity: PropType.func,
  link: PropType.bool,
  referrer: PropType.string,
};

InfoSection.propTypes = {
  row: PropType.object,
};
HistoryInfoSection.propTypes = {
  row: PropType.object,
};

export default ClientHoldingList;
