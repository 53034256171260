import { dialogVariables } from "@myloc/myloc-utils";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link, generatePath } from "react-router-dom";
import { useTranslate } from "../../../../language/i18n";
import { setMessage } from "../../../../reducers/dialog/dialogAction";
import clientService from "../../../../services/client/clientService";
import pages from "../../../../utils/pages";
import styles from "../InventoryDetailPage.module.scss";

export default function ClientLink({ location }) {
  const translate = useTranslate();
  const [client, setClient] = useState();
  useEffect(() => {
    clientService.getClient(location.warehouse.value).then(response => setClient(response.data));
  }, [location.warehouse.value]);

  return client?.hasAuthorizationForMunicipal ? (
    <Link
      to={generatePath(pages.CLIENT_INFO.PATH, { id: location.warehouse.value })}
    >{`${location?.facility?.category?.label} - ${location?.facility?.label}`}</Link>
  ) : (
    <p
      className={styles.link}
      onClick={() => setMessage(translate("NOT_AUTHORIZED_TO_MUNICIPALITY"), dialogVariables.ACTION.MESSAGE_ERROR)}
    >{`${location?.facility?.category?.label} - ${location?.facility?.label}`}</p>
  );
}

ClientLink.propTypes = {
  location: PropTypes.shape({
    warehouse: PropTypes.shape({
      value: PropTypes.number,
    }),
    facility: PropTypes.shape({
      category: PropTypes.shape({
        label: PropTypes.string,
      }),
      label: PropTypes.string,
    }),
  }),
};
