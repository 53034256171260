import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../../assets/logos/Myloc-logga.svg";
import { useTranslate } from "../../../language/i18n";
import page from "../../../utils/pages";
import MessageButton from "../../Message/MessageButton";
import CartButton from "./CartButton/CartButton";
import styles from "./Header.module.scss";
import Menu from "./Menu/Menu";
import MyMylocMenu from "./MyMylocMenu/MyMylocMenu";
import OrderMenu from "./OrderMenu/OrderMenu";
import ReceiverMenu from "./ReceiverMenu/ReceiverMenu";

function Header() {
  const userLoggedIn = !!useSelector(state => state.session.sessionId);
  const order = useSelector(state => state.appData.order);
  const translate = useTranslate();

  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <h1 className={styles.hidden}>{translate("SITE_TITLE")}</h1>
        <Link to={page.HOME}>
          <img src={logo} alt="Myloc logo" className={styles.logo} />
        </Link>
        {userLoggedIn && (
          <div className={styles.rightMenu}>
            <MessageButton />
            <MyMylocMenu />
            {order?.receiver && <ReceiverMenu />}
            <OrderMenu />
            <CartButton />
          </div>
        )}
      </div>
      <Menu userLoggedIn={userLoggedIn} />
    </header>
  );
}

export default Header;
