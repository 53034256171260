import {
  combine,
  createRecuder,
  dialogReducer as defaultDialogReducer,
  sessionReducer as defaultSessionReducer,
} from "@myloc/myloc-utils";
import { createStore } from "redux";
import { appDataReducer } from "./appData/appDataReducer";
import commonReducer from "./common/commonReducer";
import { dialogReducer } from "./dialog/dialogReducer";
import { embeddedReducer } from "./embedded/embeddedReducer";
import { sessionReducer } from "./session/sessionReducer";
import settingReducer from "./setting/settingReducer";

const appReducer = createRecuder({
  session: combine(defaultSessionReducer, sessionReducer),
  dialog: combine(defaultDialogReducer, dialogReducer),
  common: commonReducer,
  setting: settingReducer,
  appData: appDataReducer,
  embedded: embeddedReducer,
});

const reducer = (state, action) => {
  if (action.type === "RESET_USER") {
    state = undefined;
  }

  return appReducer(state, action);
};

const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;
