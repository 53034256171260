import { Button, IconButton, THEME } from "@myloc/myloc-gui";
import classNames from "classnames";
import PropType from "prop-types";
import { CircleAddFilled, Pen, Star, Trash } from "../../../assets/icons";
import styles from "./Components.module.scss";

const List = ({ title, addBtnTitle, onAddNew, children, customCssClass }) => {
  return (
    <section className={classNames(styles.listContainer, customCssClass)}>
      <div className={styles.header}>
        <h3 className={styles.contentTitle}>{title}</h3>
        <Button theme={THEME.PLAIN_PRIMARY} customCssClass={styles.addNewBtn} onClick={onAddNew}>
          <CircleAddFilled />
          <span className={styles.addNewText}>{addBtnTitle}</span>
        </Button>
      </div>
      <ul className={styles.list}>{children}</ul>
    </section>
  );
};

const ListItem = ({ title, item, onEdit, onDelete, isPrimaryContact, children }) => {
  return (
    <li className={styles.listItem}>
      <h4 className={styles.itemTitle}>{title}</h4>
      <p className={styles.itemContent}>{children}</p>
      {item.isEditable && (
        <section className={styles.actions}>
          {isPrimaryContact && (
            <span className={styles.primary}>
              <Star />
            </span>
          )}
          <IconButton onClick={() => onEdit(item)} customCssClass={styles.edit}>
            <Pen />
          </IconButton>
          <IconButton onClick={() => onDelete(item)} customCssClass={styles.delete}>
            <Trash />
          </IconButton>
        </section>
      )}
    </li>
  );
};

List.propTypes = {
  title: PropType.string,
  addBtnTitle: PropType.string,
  onAddNew: PropType.func,
  children: PropType.node,
  customCssClass: PropType.string,
};

ListItem.propTypes = {
  title: PropType.string,
  item: PropType.object,
  onEdit: PropType.func,
  onDelete: PropType.func,
  isPrimaryContact: PropType.bool,
  children: PropType.node,
};

export { List, ListItem };
