import { useState, useCallback } from "react";
import PropType from "prop-types";
import clientService from "../../../../services/client/clientService";
import ConfirmModal from "../../../shared/Modals/ConfirmModal";
import FacilitySelector from "../../../shared/FacilitySelector/FacilitySelector";
import { useTranslate } from "../../../../language/i18n";
import styles from "../Holding.module.scss";

const ReturnModal = ({ clientId, visible, onClose, selectedProducts }) => {
  const translate = useTranslate();
  const [selected, setSelected] = useState();

  const onSubmit = async () => {
    const response = await clientService.returnHoldingsToBaseStorage({
      clientId,
      baseStorageId: selected.id,
      data: selectedProducts,
    });

    if (response.isOk()) {
      return response;
    }
  };

  return (
    <ConfirmModal
      title={translate("RETURN_TO_BASE_STORAGE")}
      text={translate("RETURN_TO_BASE_STORAGE_DESCRIPTION")}
      visible={visible}
      cancel={onClose}
      small={false}
      submit={() => onSubmit().then(() => location.reload())}
      customCssClass={styles.returnModal}
      confirmButtonDisabled={!selected}
    >
      <FacilitySelector preSelected={selected} onSelect={useCallback(baseStorage => setSelected(baseStorage), [])} />
    </ConfirmModal>
  );
};

ReturnModal.propTypes = {
  clientId: PropType.string,
  visible: PropType.bool,
  onClose: PropType.func,
  selectedProducts: PropType.array,
};

export default ReturnModal;
