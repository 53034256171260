import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { api } from "../../config/settings";
import setQueryFilters from "../../utils/setQueryFilters";
import defaultRestOptions from "../utils/defaultRestOptions";

async function getInventory(filters = {}) {
  const url = api.baseStorage.inventory();
  if (filters) setQueryFilters(filters, url);

  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function createInventory(facilityId) {
  const url = api.baseStorage.inventory(facilityId);

  const request = new Request(url, Method.POST);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getInventoryLines(filters = {}) {
  if (filters?.amount) {
    const url = api.baseStorage.inventoryLines();
    if (filters) setQueryFilters(filters, url);
    const request = new Request(url, Method.GET);
    return await sendRequest(request, null, await defaultRestOptions());
  }
}

async function updateInventoryLine(lineId, fetchedTimestamp, reportedQty) {
  const url = api.baseStorage.inventoryLines(lineId);
  const request = new Request(url, Method.POST);

  let data;
  if (reportedQty != null) {
    data = {
      action: "REPORT_LINE",
      payload: {
        reportedQty: reportedQty,
        fetchedTimestamp: fetchedTimestamp,
      },
    };
  } else {
    data = {
      action: "RESET_REPORTING",
      payload: {
        fetchedTimestamp: fetchedTimestamp,
      },
    };
  }

  return await sendRequest(request, data, await defaultRestOptions());
}

async function confirmInventory(inventoryId) {
  const url = api.baseStorage.inventoryAction(inventoryId);

  const request = new Request(url, Method.POST);
  return await sendRequest(request, { action: "APPROVE" }, await defaultRestOptions());
}

async function deleteInventory(inventoryId) {
  const url = api.baseStorage.inventoryAction(inventoryId);

  const request = new Request(url, Method.DELETE);
  return await sendRequest(request, null, await defaultRestOptions());
}

export default {
  getInventory,
  getInventoryLines,
  createInventory,
  updateInventoryLine,
  confirmInventory,
  deleteInventory,
};
