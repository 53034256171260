import PropTypes from "prop-types";
import Login from "../Login/Login";
import pages from "../../../utils/pages";
import Welcome from "./Welcome";

const LoginWelcome = ({ ...props }) => {
  return (
    <Welcome {...props}>
      <Login {...props} registerUrl={pages.REGISTER} forgotPasswordUrl={"/"} />
    </Welcome>
  );
};

LoginWelcome.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  customCssClass: PropTypes.string,
};

export default LoginWelcome;
