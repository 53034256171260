import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { api } from "../../config/settings";
import defaultRestOptions from "../utils/defaultRestOptions";

async function getFacilities(personId) {
  const url = api.facility.facilities();
  if (personId) url.addParameter("personId", personId);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getFacility(facilityId) {
  const url = api.facility.facility(facilityId);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

export default { getFacility, getFacilities };
