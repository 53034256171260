import { useEffect, useState } from "react";
import { generatePath, Link } from "react-router-dom";
import PropType from "prop-types";
import { useTranslate } from "../../language/i18n";
import resourceGroupService from "../../services/resourceGroupService";
import pages from "../../utils/pages";
import { Car, Day, Place, Room } from "../../assets/icons";
import { getPlace } from "../../utils/getPlace";
import SelectField, { SIZE } from "../shared/SelectField/SelectField";
import Label from "./Label";
import styles from "./FittingsPage.module.scss";
import classNames from "classnames";
import { Checkbox, InputField } from "@myloc/myloc-gui";

const AdminEditRow = ({ fitting, showColumn, onMeetingUpdate }) => {
  const translate = useTranslate();
  const [options, setOptions] = useState([]);
  const [meeting, setMeeting] = useState({
    consultantId: fitting.nextMeeting?.consultant?.id,
    technicianId: fitting.nextMeeting?.technician?.id,
    roomId: fitting.nextMeeting?.room?.id,
    carId: fitting.nextMeeting?.car?.id,
    date: fitting.nextMeeting?.date || "",
    fromTime: fitting.nextMeeting?.fromTime || "",
    toTime: fitting.nextMeeting?.toTime || "",
    id: fitting.nextMeeting?.id,
    fittingId: fitting.id,
    address: fitting.nextMeeting?.address,
    addressType: fitting.nextMeeting?.addressType?.id,
  });

  useEffect(() => {
    if (!fitting?.fittingType) return;

    Promise.all([
      resourceGroupService.getResourceGroup(fitting?.fittingType?.roomResourceGroup?.id, { subResource: true }),
      resourceGroupService.getResourceGroup(fitting?.fittingType?.carResourceGroup?.id, { subResource: true }),
      resourceGroupService.getResourceGroup(fitting?.fittingType?.consultantResourceGroup?.id, { mainResource: true }),
      resourceGroupService.getResourceGroup(fitting?.fittingType?.technicianResourceGroup?.id, { mainResource: true }),
    ]).then(([room, car, consultant, technician]) => {
      setOptions({
        room: room?.data?.page,
        car: car?.data?.page,
        consultant: consultant?.data?.page,
        technician: technician?.data?.page,
      });
    });
  }, [fitting.fittingType]);

  const onInputChange = (key, value) => {
    setMeeting({ ...meeting, [key]: value });
    onMeetingUpdate({ ...meeting, [key]: value });
  };

  const isMeetingPlanned = () => {
    return fitting?.nextMeeting?.status === "PLANNED";
  };

  const getMinIfTodaysDate = () => {
    const todaysDate = new Date();
    if (meeting?.date == todaysDate.toISOString().substr(0, 10)) {
      return `${todaysDate.getHours()}:${todaysDate.getMinutes()}`;
    } else {
      return null;
    }
  };

  return (
    <tr key={fitting.id}>
      {showColumn("CHECKBOX") && (
        <td>
          <Checkbox disabled />
        </td>
      )}
      <td>
        <div className={styles.singleRow}>
          <Link
            to={{
              pathname: generatePath(pages.EMBEDDED_MY_FITTING.PATH, { fitting: fitting.id, type: "info" }),
              state: {
                path: `${window.location.pathname}${window.location.search}`,
                text: "FITTING_ADMIN_PLANNING",
                fromComponent: `${window.location.pathname}${window.location.search}`,
              },
            }}
          >
            {`${translate("FITTING")} ${fitting.contentNumber} / ${fitting.fittingType?.name}`}
          </Link>
        </div>
        <div className={styles.singleRow}>
          <Label label={translate("ORDERED")} value={fitting.orderDate} customCssClass={styles.cleanSpacing} />
        </div>
        <div className={styles.singleRow}>
          <Label
            label={translate("PRESCRIBER")}
            value={`${fitting.prescriber?.firstName} ${fitting.prescriber?.surname}`}
            customCssClass={styles.cleanSpacing}
          />
        </div>
      </td>
      {showColumn("BOOKING") && (
        <td>
          <div className={styles.singleRow}>{fitting.nextMeeting?.comment}</div>
        </td>
      )}
      {showColumn("MEETING") && (
        <td>
          <div className={styles.row}>
            <div>
              <Day customCssClass={styles.icon} />
              <InputField
                type="date"
                value={meeting.date}
                customCssClass={styles.input}
                customFieldIconCss={styles.fieldIcons}
                onChange={event => onInputChange("date", event.target.value)}
                onClear={() => onInputChange("date", "")}
                min={new Date().toISOString().substr(0, 10)}
                required={isMeetingPlanned()}
              />
            </div>
            <div>
              <InputField
                type="time"
                value={meeting.fromTime}
                customCssClass={styles.input}
                customFieldIconCss={styles.fieldIcons}
                onChange={event => onInputChange("fromTime", event.target.value)}
                onClear={() => onInputChange("fromTime", "")}
                min={getMinIfTodaysDate()}
                required={isMeetingPlanned()}
              />
              <InputField
                type="time"
                value={meeting.toTime}
                customCssClass={styles.input}
                customFieldIconCss={styles.fieldIcons}
                onChange={event => onInputChange("toTime", event.target.value)}
                onClear={() => onInputChange("toTime", "")}
                min={meeting?.fromTime > meeting?.toTime ? meeting?.fromTime : null}
                required={isMeetingPlanned()}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div>
              <Place customCssClass={styles.icon} />
              {getPlace(fitting?.nextMeeting, translate("CLIENT"))}
            </div>
          </div>
          <div className={styles.row}>
            <div>
              <Room customCssClass={styles.icon} />
              <SelectField
                customCssClass={styles.input}
                onSelect={({ id }) => onInputChange("roomId", id)}
                options={options.room}
                selectedId={meeting.roomId}
                disabled={fitting?.nextMeeting?.addressType?.value === translate("CLIENT")}
                customSettings={{ display: "label" }}
                size={SIZE.THIN}
              />
            </div>
            <div>
              <span className={styles.title}>{translate("CONSULTANT")}:</span>
              <SelectField
                customCssClass={styles.input}
                onSelect={({ id }) => onInputChange("consultantId", id)}
                options={options.consultant}
                selectedId={meeting.consultantId}
                customSettings={{ display: "label" }}
                size={SIZE.THIN}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div>
              <Car customCssClass={styles.icon} />
              <SelectField
                customCssClass={styles.input}
                onSelect={({ id }) => onInputChange("carId", id)}
                options={options.car}
                selectedId={meeting.carId}
                customSettings={{ display: "label" }}
                size={SIZE.THIN}
              />
            </div>
            <div>
              <span className={classNames(styles.title, styles.consultantMargin)}>{translate("TECHNICIAN")}:</span>
              <SelectField
                customCssClass={styles.input}
                onSelect={({ id }) => onInputChange("technicianId", id)}
                options={options.technician}
                selectedId={meeting.technicianId}
                customSettings={{ display: "label" }}
                size={SIZE.THIN}
              />
            </div>
          </div>
        </td>
      )}
    </tr>
  );
};

AdminEditRow.propTypes = {
  fitting: PropType.object,
  edit: PropType.bool,
  showColumn: PropType.func,
  onMeetingUpdate: PropType.func,
};
export default AdminEditRow;
