import { Button } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { useTranslate } from "../../../language/i18n";
import Label from "../../shared/Label/Label";
import styles from "../ReceiveDeliveryOrderPage.module.scss";

const ReceiveOrderEvaluate = ({ data, action, showAttributes = false }) => {
  const translate = useTranslate();

  return (
    <article>
      <section className={styles.evaluateContainer}>
        <div>
          <p className={styles.title}>{translate("PRODUCT")}</p>

          <p>{data?.product?.name}</p>
          <Label label={translate("PRODUCT_NUMBER_SHORT")} value={data?.product?.productNumber} inline />
          <Label label={translate("SUPPLIER")} value={data?.product?.supplier?.label} inline />
          {data?.batchNr && <Label label={translate("BATCH_NO")} value={data?.batchNr} inline />}
          {data?.serialNr && <Label label={translate("SERIAL_NUMBER_SHORT")} value={data?.serialNr} inline />}
        </div>
      </section>

      <section className={styles.evaluateContainer}>
        <div>
          <p className={styles.title}>{translate("RECEIVED_DELIVERY_AMOUNT")}</p>
          <Label label={translate("AMOUNT")} value={`${data?.amount} ${translate("UNITS")}`} inline />
        </div>
      </section>

      {showAttributes && (
        <section className={styles.evaluateContainer}>
          <div>
            <p className={styles.title}>{translate("PERFORMANCE")}</p>

            {data?.Attribute1 && (
              <Label label={data?.product?.attributes?.attribute1?.label} value={data?.Attribute1} inline />
            )}
            {data?.Attribute2 && (
              <Label label={data?.product?.attributes?.attribute2?.label} value={data?.Attribute2} inline />
            )}
            {data?.Attribute3 && (
              <Label label={data?.product?.attributes?.attribute3?.label} value={data?.Attribute3} inline />
            )}
            {data?.Attribute4 && (
              <Label label={data?.product?.attributes?.attribute4?.label} value={data?.Attribute4} inline />
            )}
            {data?.Attribute5 && (
              <Label label={data?.product?.attributes?.attribute5?.label} value={data?.Attribute5} inline />
            )}
            {data?.Attribute6 && (
              <Label label={data?.product?.attributes?.attribute6?.label} value={data?.Attribute6} inline />
            )}
            {data?.Attribute7 && (
              <Label label={data?.product?.attributes?.attribute7?.label} value={data?.Attribute7} inline />
            )}
          </div>
        </section>
      )}

      <Button customCssClass={styles.button} onClick={() => action()}>
        {translate("CONFIRM")}
      </Button>
    </article>
  );
};

ReceiveOrderEvaluate.propTypes = {
  data: PropType.object,
  action: PropType.func,
  showAttributes: PropType.bool,
};

export default ReceiveOrderEvaluate;
