import PropTypes from "prop-types";
import { Link, generatePath } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslate } from "../../../../language/i18n";
import { CLIENT_TYPE } from "../../../../utils/constants";
import pages from "../../../../utils/pages";
import styles from "./ActionList.module.scss";

function OrderTypeList({ onClick, receiverType }) {
  const translate = useTranslate();
  const order = useSelector(state => state.appData.order);
  const actionList = [
    {
      title: translate(pages.FUNCTION_ORDER.NAME),
      description: translate("FUNCTION_ORDER_DESCRIPTION"),
      url: pages.FUNCTION_ORDER.PATH,
    },
    {
      title: translate(pages.PRODUCTS.NAME),
      description: translate("PRODUCTS_DESCRIPTION"),
      url: pages.PRODUCTS.PATH,
    },
    {
      title: translate(pages.FITTING.NAME),
      description: translate("FITTING_DESCRIPTION"),
      hidden: receiverType === CLIENT_TYPE.BASE_STORAGE,
      url: pages.FITTING.PATH,
    },
    {
      title: translate(pages.WORK_ORDER.NAME),
      description: translate("WORK_ORDER_DESCRIPTION"),
      hidden: receiverType === CLIENT_TYPE.BASE_STORAGE,
      url: pages.WORK_ORDER.PATH,
    },
    {
      title: translate(pages.PICKUP_ORDER.NAME),
      description: translate("PICKUP_ORDER_DESCRIPTION"),
      hidden: receiverType === CLIENT_TYPE.BASE_STORAGE,
      url: pages.PICKUP_ORDER.PATH,
    },
    {
      title: translate(pages.PRODUCTS_EXTERNAL_ASSORTMENT.NAME),
      description: translate("EXTERNAL_ASSORTMENT_ORDER_DESCRIPTION"),
      hidden: receiverType === CLIENT_TYPE.BASE_STORAGE,
      url: pages.PRODUCTS_EXTERNAL_ASSORTMENT.PATH,
    },
  ];

  return (
    <ul className={styles.selectionList}>
      {actionList.map((item, index) => {
        return (
          !item.hidden && (
            <li key={`selection-${index}`} className={styles.selectionItem}>
              <Link
                to={{
                  pathname: generatePath(item.url, { order: order?.id }),
                  state: { referrer: `${window.location.pathname}${window.location.search}` },
                }}
                onClick={onClick}
                className={styles.actionItem}
              >
                <span className={styles.blueText}>{item.title}</span> {item.description}
              </Link>
            </li>
          )
        );
      })}
    </ul>
  );
}

OrderTypeList.propTypes = {
  onClick: PropTypes.func,
  receiverType: PropTypes.string,
};

export default OrderTypeList;
