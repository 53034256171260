import PropTypes from "prop-types";
import colors from "../../style/exports.module.scss";

const CheckCircle = ({ strokeColor = colors.grey, size = 24, customCssClass }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    color={strokeColor}
    strokeLinecap="round"
    strokeLinejoin="round"
    className={customCssClass}
  >
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
    <polyline points="22 4 12 14.01 9 11.01"></polyline>
  </svg>
);

CheckCircle.propTypes = {
  strokeColor: PropTypes.string,
  size: PropTypes.string,
  customCssClass: PropTypes.string,
};

export default CheckCircle;
