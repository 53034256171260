import { RadioButton } from "@myloc/myloc-gui";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslate } from "../../../../../language/i18n";
import clientService from "../../../../../services/client/clientService";
import { ADDRESS_TYPE, CLIENT_TYPE } from "../../../../../utils/constants";
import UserAddress from "../../../../shared/AddressAndContact/UserAddress/UserAddress";
import styles from "../../../FittingDetailPage.module.scss";

const UserOrFacilityAddress = ({ client, meeting, fitting, onInputChange, setShowRoom, edit }) => {
  const translate = useTranslate();
  const [receiverAddressType, setReceiverAddressType] = useState(
    edit
      ? fitting?.nextMeeting?.address?.type === ADDRESS_TYPE.COMPANY
        ? ADDRESS_TYPE.COMPANY
        : ADDRESS_TYPE.CLIENT
      : meeting?.addressType === ADDRESS_TYPE.COMPANY
      ? ADDRESS_TYPE.COMPANY
      : ADDRESS_TYPE.CLIENT,
  );
  const [clientAddresses, setClientAddresses] = useState([]);

  const getDefaultAddress = useCallback(() => {
    if (clientAddresses?.length) {
      if (receiverAddressType === ADDRESS_TYPE.CLIENT) {
        const found = clientAddresses?.find(
          address =>
            address?.city == meeting?.address?.city &&
            address?.street == meeting?.address?.street &&
            address?.postalNo == meeting?.address?.postalNo &&
            address?.careOf == meeting?.address?.careOf,
        );
        return found ? found : client?.defaultAddress;
      } else {
        return fitting?.fittingType?.executingFacility;
      }
    }
    if (receiverAddressType === CLIENT_TYPE.USER && meeting?.address?.type === ADDRESS_TYPE.COMPANY) {
      return client?.defaultAddress;
    }
    if (meeting?.address) {
      return meeting?.address;
    }
  }, [client, clientAddresses, meeting, fitting, receiverAddressType]);

  const [selectedClientAddress, setSelectedClientAddress] = useState(getDefaultAddress());
  const addressExist = useCallback(
    list => {
      if (meeting?.address?.type === ADDRESS_TYPE.CLIENT) {
        const found = list?.addresses?.find(
          address =>
            address.city == meeting.address.city &&
            address.street == meeting.address.street &&
            address.postalNo == meeting.address.postalNo &&
            address.careOf == meeting.address.careOf,
        );
        if (!found && meeting?.address) {
          list.addresses.push(meeting.address);
        }
      }
      return list;
    },
    [meeting?.address],
  );

  useEffect(() => {
    if (receiverAddressType != meeting?.addressType) {
      setShowRoom(receiverAddressType == ADDRESS_TYPE.COMPANY);
    }
    if (selectedClientAddress && meeting?.address?.id !== selectedClientAddress.id) {
      onInputChange("address", selectedClientAddress);
      onInputChange("addressType", selectedClientAddress?.type);
    }
  }, [selectedClientAddress, receiverAddressType, meeting, onInputChange, setShowRoom]);

  const loadClientAddresses = useCallback(
    async user => {
      if (user) {
        const response = await clientService.getAddress(user.id);
        const addressList = addressExist(response.data);

        if (response.isOk()) return addressList;
      }
    },
    [addressExist],
  );

  const reloadClientAddresses = useCallback(async () => {
    if (!clientAddresses?.length) {
      if (client && receiverAddressType === CLIENT_TYPE.USER) {
        const addresses = await loadClientAddresses(client);
        if (!(addresses?.length === 0)) setClientAddresses(addresses?.addresses);
      }
    }
  }, [client, receiverAddressType, clientAddresses, loadClientAddresses]);

  useEffect(() => {
    if (client) {
      reloadClientAddresses();
    }
  }, [client, reloadClientAddresses]);

  useEffect(() => {
    if (receiverAddressType === CLIENT_TYPE.USER) {
      setSelectedClientAddress(getDefaultAddress());
    } else {
      setSelectedClientAddress(fitting?.fittingType?.executingFacility);
    }
  }, [receiverAddressType, setShowRoom, fitting, getDefaultAddress]);

  const onClientAddressChange = address => {
    setSelectedClientAddress(address);
  };

  function getSelectedAddress() {
    if (selectedClientAddress) {
      if (selectedClientAddress.id === client?.defaultAddress?.id) {
        return client?.defaultAddress;
      } else {
        return clientAddresses?.find(address => address?.id === selectedClientAddress.id);
      }
    } else if (receiverAddressType === meeting?.addressType) {
      if (
        meeting?.address?.city == client?.defaultAddress?.city &&
        meeting?.address?.street == client?.defaultAddress?.street &&
        meeting?.address?.postalNo == client?.defaultAddress?.postalNo &&
        meeting?.address?.careOf == client?.defaultAddress?.careOf
      ) {
        return client?.defaultAddress;
      } else {
        return clientAddresses?.find(address => address?.id === fitting?.nextMeeting?.address?.id);
      }
    } else {
      onClientAddressChange(
        receiverAddressType === ADDRESS_TYPE.CLIENT ? client?.defaultAddress : fitting?.fittingType?.executingFacility,
      );
      return receiverAddressType === ADDRESS_TYPE.CLIENT
        ? client?.defaultAddress
        : fitting?.fittingType?.executingFacility;
    }
  }

  return (
    <section>
      <>
        <div className={styles.radioButtonGroup}>
          <p className={styles.place}>{translate("PLACE")}: </p>
          <RadioButton
            label={translate("USER")}
            id="user"
            name="address-type"
            checked={receiverAddressType === ADDRESS_TYPE.CLIENT}
            onChange={() => setReceiverAddressType(ADDRESS_TYPE.CLIENT)}
          />
          <RadioButton
            label={fitting?.fittingType?.executingFacility?.facility?.label}
            id="hul"
            name="address-type"
            onChange={() => setReceiverAddressType(ADDRESS_TYPE.COMPANY)}
            checked={receiverAddressType === ADDRESS_TYPE.COMPANY}
          />
        </div>
        {receiverAddressType === CLIENT_TYPE.USER ? (
          <UserAddress
            user={client}
            addresses={clientAddresses}
            selectedAddress={getSelectedAddress()}
            onSelect={onClientAddressChange}
            onReload={reloadClientAddresses}
            showAddButton={false}
            customCssClass={styles.selector}
          />
        ) : (
          <address className={(styles.address, styles.hul)}>
            {fitting?.fittingType?.executingFacility?.description && (
              <p>{fitting.fittingType.executingFacility.description}</p>
            )}
            {fitting?.fittingType?.executingFacility?.careOf && (
              <p>
                {translate("CARE_OF")} {fitting.fittingType.executingFacility.careOf}
              </p>
            )}
            {fitting?.fittingType?.executingFacility?.street && <p>{fitting.fittingType.executingFacility.street}</p>}
            <p>
              {fitting?.fittingType?.executingFacility?.postalNo} {fitting.fittingType.executingFacility.city}
            </p>
          </address>
        )}
      </>
    </section>
  );
};
UserOrFacilityAddress.propTypes = {
  contact: PropTypes.object,
  disableFacility: PropTypes.bool,
  onNext: PropTypes.func,
  client: PropTypes.object,
  meeting: PropTypes.object,
  onInputChange: PropTypes.func,
  fitting: PropTypes.object,
  setShowRoom: PropTypes.func,
  edit: PropTypes.bool,
};
export default UserOrFacilityAddress;
