import PropTypes from "prop-types";
import { CheckCircle } from "../../../assets/icons";
import { useTranslate } from "../../../language/i18n";
import styles from "./PasswordRule.module.scss";

const PasswordRules = ({ rules, password }) => {
  const translate = useTranslate();
  return (
    <section className={styles.container}>
      {rules && (
        <>
          <p>{translate("PASSWORD_HAS_TO_HAVE")}</p>
          {rules?.map((rule, index) => (
            <PasswordRule key={index} rule={rule} password={password} />
          ))}
        </>
      )}
    </section>
  );
};

const PasswordRule = ({ rule, password }) => {
  return (
    <label className={styles.rule}>
      <CheckCircle customCssClass={isRuleFulfilled(rule, password) ? styles.accepted : ""} />
      {rule.label}
    </label>
  );
};

function isRuleFulfilled(rule, password) {
  const regex = new RegExp(rule.regex, "g");
  return password?.match(regex)?.length >= rule.amount;
}

export function isRulesFulfilled(rules, password) {
  if (!rules) return true;
  for (const rule of rules) {
    if (!isRuleFulfilled(rule, password)) return false;
  }

  return true;
}

PasswordRule.propTypes = {
  password: PropTypes.string,
  rule: PropTypes.shape({
    label: PropTypes.string,
    amount: PropTypes.number,
    regex: PropTypes.string,
  }),
};

PasswordRules.propTypes = {
  password: PropTypes.string,
  rules: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      amount: PropTypes.number,
      regex: PropTypes.string,
    }),
  ),
};

export default PasswordRules;
