import PropTypes from "prop-types";
import classNames from "classnames";
import { Image as ImageIcon } from "../../../assets/icons";
import { useMobile } from "../../../utils/viewport";
import Image from "../../shared/Image/Image";
import styles from "./ProductImage.module.scss";
import { useTranslate } from "../../../language/i18n";

const ProductImage = ({ images, preset = ImagePreset.SMALL, customCssClass, isOrderable }) => {
  const translate = useTranslate();
  const isMobile = useMobile();

  if (!images || images.length == 0)
    return (
      <figure
        style={{ height: isMobile ? preset.MOBILE : preset.FULL }}
        className={classNames(styles.image, styles.missingImage)}
      >
        <ImageIcon size={[ImagePreset.ICON, ImagePreset.TINY].includes(preset) ? "25" : "80"} />
        {isOrderable === false && (
          <div className={classNames(styles.notOrderable, customCssClass)}>{translate("NOT_ORDERABLE_SINGULAR")}</div>
        )}
      </figure>
    );

  const image = images[0];
  const imageUrl = new URL(image.url);
  if (preset) {
    imageUrl.searchParams.append("width", preset.SCALE);
    imageUrl.searchParams.append("height", preset.SCALE);
  }

  return (
    <Image
      style={{ height: isMobile ? preset.MOBILE : preset.FULL }}
      src={imageUrl.toString()}
      alt={image.name}
      customCssClass={classNames(styles.image, customCssClass)}
      isOrderable={isOrderable}
      infoMessage={translate("NOT_ORDERABLE_SINGULAR")}
    />
  );
};

export const ImagePreset = {
  ICON: { FULL: 50, MOBILE: 50, SCALE: 100 },
  TINY: { FULL: 100, MOBILE: 100, SCALE: 250 },
  SMALL: { FULL: 250, MOBILE: 130, SCALE: 500 },
  MEDIUM: { FULL: 512, MOBILE: 400, SCALE: 1024 },
};

ProductImage.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  preset: PropTypes.oneOf([ImagePreset.ICON, ImagePreset.SMALL, ImagePreset.MEDIUM]),
  customCssClass: PropTypes.string,
  isOrderable: PropTypes.bool,
};

export default ProductImage;
