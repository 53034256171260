import { withPage } from "@myloc/myloc-utils";
import PropType from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, Redirect, Route, Switch, useLocation, useParams } from "react-router-dom";
import { useTranslate } from "../../language/i18n";
import clientService from "../../services/client/clientService";
import pages from "../../utils/pages";
import TaskDetails from "../MyMyloc/TaskDetails/TaskDetails";
import Page from "../shared/Page/Page";
import SubPageMenu from "../shared/SubPageMenu/SubPageMenu";
import AddressList from "./AddressAndContact/AddressList";
import ContactList from "./AddressAndContact/ContactList";
import ClientInfo from "./ClientInfo/ClientInfo";
import styles from "./ClientsPage.module.scss";
import Holding from "./Holding/Holding";
import HoldingDetail from "./Holding/HoldingDetail";
import HoldingHistory from "./Holding/HoldingHistory";
import ClientOrders from "./Orders/ClientOrders";
import Tasks from "./Tasks/Tasks";

function ClientsPage() {
  const translate = useTranslate();
  const location = useLocation();
  const fromReceiverMenu = useSelector(state => state?.appData?.receiverMenu);
  const { id } = useParams();
  const [receiver, setReceiver] = useState({});
  const [fetchReceiver, setFetchReceiver] = useState(false);
  const referrer = location.state?.referrer;
  const params = useParams();

  useEffect(() => {
    clientService.getClient(id).then(response => setReceiver(response.data));
    if (fetchReceiver) setFetchReceiver(false);
  }, [id, fetchReceiver]);

  const clientName = `${receiver?.firstName} ${receiver?.surname}, ${receiver?.identityNumber}`;

  const links = [
    { NAME: pages.CLIENT_INFO.NAME, PATH: generatePath(pages.CLIENT_INFO.PATH, { id }) },
    { NAME: pages.CLIENT_HOLDINGS.NAME, PATH: generatePath(pages.CLIENT_HOLDINGS.PATH, { id }) },
    { NAME: pages.CLIENT_ADDRESSES.NAME, PATH: generatePath(pages.CLIENT_ADDRESSES.PATH, { id }) },
    { NAME: pages.CLIENT_CONTACTS.NAME, PATH: generatePath(pages.CLIENT_CONTACTS.PATH, { id }) },
    { NAME: pages.CLIENT_TASKS.NAME, PATH: generatePath(pages.CLIENT_TASKS.PATH, { id }) },
    { NAME: pages.CLIENT_ORDERS.NAME, PATH: generatePath(pages.CLIENT_ORDERS.PATH, { id }) },
    {
      NAME: pages.CLIENT_HOLDING_TRANSACTIONS.NAME,
      PATH: generatePath(pages.CLIENT_HOLDING_TRANSACTIONS.PATH, { id }),
    },
  ];

  const [breadcrumbs, setBreadcrumbs] = useState([
    { text: translate("SEARCH_CLIENT"), link: referrer || pages.CLIENTS.PATH },
    { text: `${translate(pages.CLIENTS.NAME)} - ${translate(pages.CLIENT_INFO.NAME)} ` },
  ]);

  return (
    <Page
      title={translate(pages.CLIENTS.NAME)}
      description={clientName}
      breadcrumbs={breadcrumbs}
      customCssClass={styles.page}
    >
      <div className={styles.pageContent}>
        <SubPageMenu links={links} />

        <Switch>
          <Route exact path={pages.CLIENT_INFO.PATH}>
            <ClientInfo
              setBreadcrumbs={setBreadcrumbs}
              receiver={receiver}
              referrer={referrer}
              setFetchReceiver={setFetchReceiver}
              fromReceiverMenu={fromReceiverMenu}
            />
          </Route>

          <Route exact path={`${pages.CLIENT_HOLDINGS.PATH}/:holding`}>
            <HoldingDetail
              setBreadcrumbs={setBreadcrumbs}
              receiver={receiver}
              referrer={referrer}
              fromReceiverMenu={fromReceiverMenu}
            />
          </Route>
          <Route exact path={pages.CLIENT_HOLDINGS.PATH}>
            <Holding
              setBreadcrumbs={setBreadcrumbs}
              receiver={receiver}
              referrer={referrer}
              fromReceiverMenu={fromReceiverMenu}
            />
          </Route>

          <Route exact path={pages.CLIENT_ADDRESSES.PATH}>
            <AddressList
              setBreadcrumbs={setBreadcrumbs}
              receiver={receiver}
              referrer={referrer}
              fromReceiverMenu={fromReceiverMenu}
            />
          </Route>

          <Route exact path={pages.CLIENT_CONTACTS.PATH}>
            <ContactList
              setBreadcrumbs={setBreadcrumbs}
              receiver={receiver}
              referrer={referrer}
              fromReceiverMenu={fromReceiverMenu}
            />
          </Route>

          <Route exact path={pages.CLIENT_TASKS.PATH}>
            <Tasks
              setBreadcrumbs={setBreadcrumbs}
              receiver={receiver}
              referrer={referrer}
              fromReceiverMenu={fromReceiverMenu}
            />
          </Route>

          <Route exact path={pages.CLIENT_ORDERS.PATH}>
            <ClientOrders
              setBreadcrumbs={setBreadcrumbs}
              receiver={receiver}
              referrer={referrer}
              fromReceiverMenu={fromReceiverMenu}
            />
          </Route>

          <Route exact path={pages.CLIENT_HOLDING_TRANSACTIONS.PATH}>
            <HoldingHistory
              setBreadcrumbs={setBreadcrumbs}
              receiver={receiver}
              referrer={referrer}
              fromReceiverMenu={fromReceiverMenu}
            />
          </Route>

          <Route exact path={`${pages.CLIENT_TASKS.PATH}/:id`}>
            <TaskDetails id={params.id} setBreadcrumbs={setBreadcrumbs} fromReceiverMenu={fromReceiverMenu} />
          </Route>

          <Route exact path={`${pages.CLIENTS.PATH}/:id`}>
            <Redirect
              to={{ pathname: `${generatePath(pages.CLIENT_INFO.PATH, { id })}`, state: { referrer: referrer } }}
            />
          </Route>
        </Switch>
      </div>
    </Page>
  );
}

ClientsPage.propTypes = {
  receiver: PropType.object,
  from: PropType.string,
};

export default withPage(ClientsPage);
