import PropType from "prop-types";
import { useTranslate } from "../../../language/i18n";
import Label from "../Label/Label";
import styles from "./OrderHistory.module.scss";

const OrderHistoryRow = ({ row }) => {
  const translate = useTranslate();

  if (!row) return null;

  return (
    <div>
      <div className={styles.results}>
        <Label
          label={translate("ORDER_NUMBER_HÖS")}
          value={row.description ? `${row.loNumber} - ${row.description}` : row.loNumber}
          inline
        />
        <Label label={translate("ITEM")} value={row.itemDescription} inline />
        <Label label={translate("PLANNED_START_DATE")} value={row.plannedStartDate} inline />
        <Label label={translate("ORDERED_BY")} value={row.createdBy} inline />
        <Label label={translate("STATUS")} value={row.status?.label} inline />
        <Label label={translate("ACTUAL_END_DATE")} value={row.actualEndDate} inline />
      </div>
      {row.orderInstruction && <p className={styles.orderInstruction}>{row.orderInstruction}</p>}
    </div>
  );
};

OrderHistoryRow.propTypes = {
  row: PropType.object,
};
export default OrderHistoryRow;
