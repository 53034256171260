import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslate } from "../../../../language/i18n";
import SelectField, { SIZE } from "../../../shared/SelectField/SelectField";
import styles from "./StorageSelector.module.scss";

const StorageSelector = ({ storageBalances, selectedStorage, onSelectStorage }) => {
  const [storageBalancesList, setStorageBalancesList] = useState([]);

  const updateStorageList = useCallback(
    storageBalancesList => {
      const newBalanceList = storageBalancesList.filter(balance => balance.quantity - balance.allocatedQuantity > 0);
      setStorageBalancesList(newBalanceList);

      const isFound = newBalanceList?.some(storage => storage.id === selectedStorage?.id);

      if (!isFound) {
        onSelectStorage(null);
      }
    },
    [setStorageBalancesList, onSelectStorage, selectedStorage],
  );

  useEffect(() => {
    if (!selectedStorage || !storageBalances) return;

    updateStorageList(storageBalances);
  }, [selectedStorage, storageBalances, updateStorageList]);

  const translate = useTranslate();

  return (
    <div className={styles.storageSelectorContainer}>
      <SelectField
        customCssClass={styles.storageSelector}
        label={translate(storageBalancesList?.length ? "BASE_STORAGE_STOCK_BALANCE" : "NOT_AVAILABLE_IN_BASE_STORAGE")}
        options={storageBalancesList?.map(current => {
          return {
            id: current.id,
            value: current.value,
            label: current.label,
            category: current.category,
            text: `${current.value} - ${current.label} (${current.quantity - current.allocatedQuantity})`,
          };
        })}
        customSettings={{ display: "text" }}
        onSelect={onSelectStorage}
        selectedId={selectedStorage?.id}
        size={SIZE.THIN}
        customSelectionCss={styles.selectField}
      />
    </div>
  );
};

StorageSelector.propTypes = {
  storageBalances: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string,
      quantity: PropTypes.number,
    }),
  ),
  selectedStorage: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    quantity: PropTypes.number,
  }),
  onSelectStorage: PropTypes.func,
};

export default StorageSelector;
