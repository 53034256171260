import { STATUS, REFERENCED_ORDER, FITTING, CLEAR_REFERENCED_ORDER_STATE } from "./embeddedVariables";

const initialState = {
  status: null,
  referencedOrder: null,
  fitting: { path: null, text: null },
};

export const embeddedReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATUS:
      return Object.assign({}, state, { status: action.status });
    case REFERENCED_ORDER:
      return Object.assign({}, state, { referencedOrder: action.referencedOrder });
    case FITTING:
      return Object.assign({}, state, { fitting: action.fitting });
    case CLEAR_REFERENCED_ORDER_STATE:
      return Object.assign({}, state, { referencedOrder: initialState.referencedOrder });
    default:
      return state;
  }
};
