import { Button } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useTranslate } from "../../../language/i18n";
import productService from "../../../services/product/productService";
import pages from "../../../utils/pages";
import FileIcon from "../../shared/File/FileIcon/FileIcon";
import Page from "../../shared/Page/Page";
import LoadingSpinner from "../../shared/Spinner/LoadingSpinner";
import styles from "../ReceiveDeliveryOrderPage.module.scss";

const ReceiveOrderContinue = ({ inventoryNumber, reference }) => {
  const translate = useTranslate();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const queryParams = useParams();

  function returnToSearch() {
    let path = pages.RECEIVE_DELIVERIES.PATH;
    let parameters = "?reference=";
    if (reference?.reference) parameters = "?reference=" + reference?.reference;
    history.push({
      pathname: path,
      search: parameters + "&basestorage=" + queryParams?.baseStorage,
    });
  }

  const getLabel = async () => {
    setIsLoading(true);
    const response = await productService.getFixedAssetLabel(inventoryNumber);
    setIsLoading(false);
    if (response.isOk()) {
      const newWindow = window.open(response.data.url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    }
  };

  return (
    <>
      <Page>
        <article className={(styles.container, styles.pageContent)}>
          <section className={styles.section}>
            <h3>
              <b>{translate("RECEIVE_DELIVERY_COMPLETE")}!</b>
            </h3>
            <p>{translate("RECEIVE_DELIVERY_COMPLETE_TEXT")}</p>

            {inventoryNumber && (
              <div>
                {isLoading ? (
                  <div className={styles.fileLoader}>
                    <div>
                      <LoadingSpinner />
                    </div>
                  </div>
                ) : (
                  <div className={styles.file}>
                    <FileIcon fileType={"pdf"} size={50} />

                    <a className={styles.link} onClick={() => getLabel()}>
                      <div>{translate("INVENTORY_LABEL")}</div>
                    </a>
                  </div>
                )}
              </div>
            )}
          </section>

          <section className={styles.section}>
            <Button type="submit" customCssClass={styles.button} onClick={() => returnToSearch()}>
              {translate("CONTINUE_WITH_REGISTRATING")}
            </Button>
          </section>
        </article>
      </Page>
    </>
  );
};

ReceiveOrderContinue.propTypes = {
  inventoryNumber: PropType.string,
  reference: PropType.string,
};

export default ReceiveOrderContinue;
