import PropTypes from "prop-types";

const Grid = ({ color = "currentColor", size = 24, customCssClass }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={color}
    className={customCssClass}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M16,20H20V16H16M16,14H20V10H16M10,8H14V4H10M16,8H20V4H16M10,14H14V10H10M4,14H8V10H4M4,20H8V16H4M10,20H14V16H10M4,8H8V4H4V8Z" />
  </svg>
);

Grid.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  customCssClass: PropTypes.string,
};

export default Grid;
