import PropType from "prop-types";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useTranslate } from "../../../language/i18n";
import pages from "../../../utils/pages";
import styles from "../FittingDetailPage.module.scss";
import FileUpload from "../../shared/File/FileUpload/FileUpload";

const Files = ({ files, setBreadcrumbs, embeddedFitting, setFetchFittings, isCompleted = false }) => {
  const translate = useTranslate();
  const params = useParams();

  useEffect(() => {
    setBreadcrumbs([
      {
        text: embeddedFitting?.text
          ? translate(embeddedFitting?.text)
          : `${translate(pages.MY_MYLOC.NAME)} - ${translate(pages.FITTINGS.NAME)}`,
        link: embeddedFitting?.path ? embeddedFitting?.path : pages.MY_FITTINGS.PATH,
      },
      {
        text: `${translate(pages.FITTING_DETAIL.NAME)} - ${translate(pages.MY_FITTINGS_ATTACHMENTS.NAME)}`,
      },
    ]);
  }, [embeddedFitting, setBreadcrumbs, translate]);

  return (
    <section>
      <h3 className={styles.title}>{translate("FITTING_SUB_NAV_ATTACHMENTS")}</h3>
      <FileUpload
        contentId={params.fitting}
        files={files}
        authUploadId={"FSP_UploadWebOrderFittingFiles"}
        authDeleteId={"FSP_DeleteWebOrderFittingFile"}
        setFetchFittings={setFetchFittings}
        isCompleted={isCompleted}
      />
    </section>
  );
};

Files.propTypes = {
  files: PropType.array,
  setBreadcrumbs: PropType.func,
  embeddedFitting: PropType.object,
  setFetchFittings: PropType.func,
  isCompleted: PropType.bool,
};

export default Files;
