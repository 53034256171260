import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { dialogVariables } from "@myloc/myloc-utils";
import Snackbar, { SEVERITY } from "../Snackbar/Snackbar";

const SnackbarMessage = ({ onReset }) => {
  const message = useSelector(state => state.dialog.message);

  return (
    <Snackbar
      severity={message.type === dialogVariables.MESSAGE_TYPE.ERROR ? SEVERITY.ERROR : SEVERITY.SUCCESS}
      onClose={onReset}
      message={!message?.error?.message?.includes("503") && message?.value}
    />
  );
};

SnackbarMessage.propTypes = {
  onReset: PropTypes.func.isRequired,
};

export default SnackbarMessage;
