import PropType from "prop-types";

export const accessoryProps = {
  id: PropType.string.isRequired,
  productNumber: PropType.string,
  description: PropType.string,
  quantity: PropType.number,
  unit: PropType.string,
};

export const inventoryProps = {
  id: PropType.string.isRequired,
  inventoryNumber: PropType.string,
  productNumber: PropType.string,
  description: PropType.string,
  quantity: PropType.number,
  unit: PropType.string,
  accessories: PropType.arrayOf(PropType.shape(accessoryProps)),
  makes: PropType.array,
};
