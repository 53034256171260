import { withPage } from "@myloc/myloc-utils";
import PropType from "prop-types";
import { useParams } from "react-router";
import useOrderWizard from "../../hooks/useOrderWizard";
import { useTranslate } from "../../language/i18n";
import pages from "../../utils/pages";
import Accessories from "../shared/Accessories/Accessories";
import AdditionalInfo from "../shared/AdditionalInfo/AdditionalInfo";
import AddressAndContact from "../shared/AddressAndContact/AddressAndContact";
import OrderOverview from "../shared/OrderOverview/OrderOverview";
import WizardPage, { getActiveStep } from "../shared/WizardPage/WizardPage";
import Customization from "./Customization/Customization";
import OrderInfo from "./OrderInfo";
import OrderSelector from "./OrderSelector/OrderSelector";

const FunctionOrderPage = ({ currentActiveStep }) => {
  const queryParams = useParams();
  const translate = useTranslate();
  const contentId = queryParams.content;

  const { data, stepper, handleNext } = useOrderWizard(pages.FUNCTION_ORDER.PATH);

  const steps = [
    {
      title: translate("CHOOSE_FUNCTION"),
      titleShort: translate("FUNCTION"),
      children: <OrderSelector onNext={handleNext} />,
    },
    {
      title: translate("CHOOSE_CUSTOMIZATION"),
      titleShort: translate("CUSTOMIZATION"),
      children: <Customization onNext={handleNext} />,
    },
    {
      title: translate("CHOOSE_ACCESSORIES"),
      titleShort: translate("ACCESSORIES"),
      children: <Accessories onNext={handleNext} />,
    },
    {
      title: translate("ADD_ADDITIONAL_INFO"),
      titleShort: translate("ADDITIONAL_INFO_SHORT"),
      children: <AdditionalInfo onNext={handleNext} />,
    },
    {
      title: translate("ADD_DELIVERY_ADDRESS_AND_CONTACT"),
      titleShort: translate("ADDRESS"),
      children: <AddressAndContact onNext={handleNext} data={data} defaultBaseStorage showDeliveryMethods />,
    },
    {
      title: translate("ORDER_OVERVIEW"),
      titleShort: translate("ORDER_OVERVIEW_SHORT"),
      children: (
        <OrderOverview>
          <OrderInfo />
        </OrderOverview>
      ),
    },
  ];

  return (
    <WizardPage
      title={translate("FUNCTION_ORDER_TITLE")}
      steps={steps}
      activeStep={getActiveStep(contentId, currentActiveStep, data.currentStep)}
      setActiveStep={step => stepper.setActiveStep(step)}
    />
  );
};

FunctionOrderPage.propTypes = {
  search: PropType.string,
  id: PropType.string,
  currentStep: PropType.number,
  currentActiveStep: PropType.number,
  contentType: PropType.string,
  subContentType: PropType.shape({
    id: PropType.string,
    value: PropType.string,
  }),
  function: PropType.object,
  files: PropType.array,
  make: PropType.array,
  functionAccessories: PropType.array,
  address: PropType.object,
  contact: PropType.object,
  form: PropType.object,
  text: PropType.string,
};

export default withPage(FunctionOrderPage);
