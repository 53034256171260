import { useState, useEffect } from "react";
import PropType from "prop-types";

import classNames from "classnames";
import { withPage } from "@myloc/myloc-utils";
import { generatePath, NavLink, Route, Switch, useParams } from "react-router-dom";
import { useTranslate } from "../../../language/i18n";
import pages from "../../../utils/pages";
import Page from "../../shared/Page/Page";
import DeviationInfo from "./Sections/DeviationInfo";
import DeviationAttachments from "./Sections/DeviationAttachments";
import styles from "./DeviationDetailsPage.module.scss";
import deviationService from "../../../services/deviation/deviationService";

function DeviationDetailsPage({ referrer }) {
  const translate = useTranslate();
  const [deviation, setDeviation] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      text: `${translate(pages.MY_MYLOC.NAME)} - ${translate(pages.MY_DEVIATIONS.NAME)}`,
      link: referrer?.path || pages.MY_DEVIATIONS.PATH,
    },
    {
      text: `${translate("DEVIATION")} - ${translate("DEVIATION_INFO")}`,
    },
  ]);

  const params = useParams();

  const links = [pages.MY_DEVIATIONS_INFO, pages.MY_DEVIATIONS_ATTACHMENTS];

  useEffect(() => {
    deviationService.getDeviation(params.deviation).then(response => setDeviation(response.data));
  }, [params.deviation]);

  if (!deviation) {
    return <></>;
  }

  return (
    <Page customCssClass={styles.page} breadcrumbs={breadcrumbs}>
      <h2 className={styles.title}>
        {translate(pages.MY_DEVIATION_DETAILS.NAME)}
        <span className={styles.description}>
          {`- ${deviation.deviationNumber} `}
          {deviation?.status?.id !== "10" && (
            <span>
              (
              <span className={classNames(styles.status, styles[`status${deviation?.status?.id}`])}>
                {translate("DEVIATION_STATUS_" + deviation.status.id)}
              </span>
              )
            </span>
          )}
        </span>
      </h2>
      <div className={styles.pageContent}>
        <nav className={styles.navigation}>
          <ul>
            {links.map(link => (
              <li key={link.PATH} className={classNames(styles.navButton)}>
                <NavLink
                  activeClassName={styles.active}
                  to={{
                    pathname: generatePath(link.PATH, { deviation: params.deviation }),
                    state: { referrer: referrer },
                  }}
                >
                  {translate(link.NAME)}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>

        <Switch>
          <Route exact path={pages.MY_DEVIATIONS_INFO.PATH}>
            <DeviationInfo setBreadcrumbs={setBreadcrumbs} deviation={deviation} referrer={referrer} />
          </Route>

          <Route exact path={pages.MY_DEVIATIONS_ATTACHMENTS.PATH}>
            <DeviationAttachments setBreadcrumbs={setBreadcrumbs} deviation={deviation} referrer={referrer} />
          </Route>
        </Switch>
      </div>
    </Page>
  );
}

DeviationDetailsPage.propTypes = {
  referrer: PropType.object,
};

export default withPage(DeviationDetailsPage);
