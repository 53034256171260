import { useState } from "react";
import PropType from "prop-types";
import { useTranslate } from "../../../language/i18n";
import { Button, THEME } from "@myloc/myloc-gui";
import Modal from "../Modals/Modal";
import styles from "./LogisticsConfirmModal.module.scss";
import { myloc } from "../../../config/settings";
import { ExternalLink, Type } from "../Link";

function LogisticsConfirmModal({
  title,
  text,
  isHtmlContent,
  visible,
  cancel,
  submit,
  children,
  small = true,
  customCssClass,
  showButtons = true,
  showCloseButton,
  showConfirmButton = true,
  showDeclineButton = true,
  confirmButtonText,
  confirmButtonDisabled,
  declineButtonText,
  showSupportEmail = false,
}) {
  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal
      title={translate(title)}
      visible={visible}
      onClose={cancel}
      small={small}
      customCssClass={customCssClass}
      showCloseButton={showCloseButton}
    >
      {isHtmlContent ? (
        <div dangerouslySetInnerHTML={{ __html: text }} />
      ) : (
        <p>
          {translate(text)}
          {showSupportEmail && (
            <ExternalLink url={myloc.supportEmail} type={Type.EMAIL}>
              {myloc.supportEmail.toString}
            </ExternalLink>
          )}
        </p>
      )}
      {children}
      {showButtons && (
        <footer className={styles.buttons}>
          {showConfirmButton && (
            <Button
              isLoading={isLoading}
              disabled={confirmButtonDisabled}
              onClick={() => {
                setIsLoading(true);
                submit()
                  .then(() => {
                    setIsLoading(false);
                    cancel();
                  })
                  .catch(() => setIsLoading(false));
              }}
            >
              {translate(confirmButtonText ? confirmButtonText : "YES")}
            </Button>
          )}

          {showDeclineButton && (
            <Button theme={THEME.SECONDARY} onClick={cancel}>
              {translate(declineButtonText ? declineButtonText : "NO")}
            </Button>
          )}
        </footer>
      )}
    </Modal>
  );
}

LogisticsConfirmModal.propTypes = {
  title: PropType.string,
  text: PropType.string,
  isHtmlContent: PropType.bool,
  visible: PropType.bool,
  cancel: PropType.func,
  submit: PropType.func,
  children: PropType.node,
  small: PropType.bool,
  customCssClass: PropType.string,
  showButtons: PropType.bool,
  showCloseButton: PropType.bool,
  showConfirmButton: PropType.bool,
  showDeclineButton: PropType.bool,
  confirmButtonText: PropType.string,
  confirmButtonDisabled: PropType.bool,
  declineButtonText: PropType.string,
  showSupportEmail: PropType.bool,
};

export default LogisticsConfirmModal;
