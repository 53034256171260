import PropType from "prop-types";
import { Form, InputField, Modal, Button } from "@myloc/myloc-gui";
import { useTranslate } from "../../../language/i18n";
import styles from "../ClientsPage.module.scss";
import { useEffect, useState } from "react";
import clientService from "../../../services/client/clientService";
import isEqual from "../../../utils/compareObjects";

const EditClientModal = ({ visible, client, onClose, setFetchReceiver }) => {
  const translate = useTranslate();
  const [contactInfo, setContactInfo] = useState({
    phoneNo: client.contactInformation?.phoneNo,
    email: client.contactInformation?.email,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setContactInfo({ phoneNo: client.contactInformation?.phoneNo, email: client.contactInformation?.email });
  }, [client]);

  const handleFieldChange = (event, key) => {
    setContactInfo({ ...contactInfo, [key]: event.target.value });
  };

  const handleSubmit = () => {
    if (contactInfo && isEqual(client.contactInformation, contactInfo)) {
      onClose();
    } else {
      setLoading(true);
      clientService.updateClient(client.id, { contactInformation: contactInfo }).then(() => {
        setLoading(false);
        setFetchReceiver(true);
        onClose();
      });
    }
  };

  return (
    <Modal visible={visible} title={translate("EDIT_CLIENT")} onClose={onClose} customCssClass={styles.modal}>
      <Form onSubmit={handleSubmit} className={styles.form}>
        <InputField
          label={translate("PHONE_NO")}
          name="phoneNo"
          type="text"
          value={contactInfo?.phoneNo}
          onChange={e => handleFieldChange(e, "phoneNo")}
          autoFocus
        />
        <InputField
          label={translate("EMAIL")}
          name="email"
          type="text"
          value={contactInfo?.email}
          onChange={e => handleFieldChange(e, "email")}
        />
        <Button type="submit" isLoading={loading} customCssClass={styles.saveBtn}>
          {translate("SAVE")}
        </Button>
      </Form>
    </Modal>
  );
};

EditClientModal.propTypes = {
  visible: PropType.bool,
  client: PropType.shape({
    id: PropType.string,
    contactInformation: PropType.shape({
      phoneNo: PropType.string,
      email: PropType.string,
    }),
  }),
  onClose: PropType.func,
  setFetchReceiver: PropType.func,
};

export default EditClientModal;
