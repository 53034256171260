import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { CircleRemove, CircleAdd } from "../../../assets/icons";
import { Button, THEME } from "@myloc/myloc-gui";
import styles from "./Counter.module.scss";

function Counter({ min, max, onChange, startValue, disabled, customCssClass }) {
  const [value, setValue] = useState(startValue);

  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleDecrese = () => {
    setValue(value - 1);
  };

  const handleIncrese = () => {
    setValue(value + 1);
  };

  const handleOnFocus = e => {
    if (parseInt(e.target.value) === 0) {
      setValue("");
    }
  };

  const handleOnChange = e => {
    const newVal = e.target.value;
    if (!newVal) {
      setValue(newVal);
      return;
    }

    const parsedVal = parseInt(newVal, 10);
    if (parsedVal <= max && parsedVal >= min) {
      setValue(parsedVal);
    }
  };

  const handleOnBlur = e => {
    if (!e.target.value) {
      setValue(min);
    }
  };

  return (
    <div className={classNames(styles.counter, customCssClass)}>
      <Button
        theme={THEME.PLAIN_PRIMARY}
        onClick={handleDecrese}
        disabled={disabled || value === min}
        customCssClass={styles.roundBtn}
      >
        <CircleRemove />
      </Button>
      <input
        name="count"
        type="number"
        className={styles.count}
        min={min}
        max={max}
        value={value}
        onFocus={handleOnFocus}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        disabled={disabled}
      />
      <Button
        theme={THEME.PLAIN_PRIMARY}
        onClick={handleIncrese}
        disabled={disabled || value === max}
        customCssClass={styles.roundBtn}
      >
        <CircleAdd />
      </Button>
    </div>
  );
}

Counter.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  startValue: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  customCssClass: PropTypes.string,
};

Counter.defaultProps = {
  min: 0,
  max: 999,
  startValue: 1,
};

export default Counter;
