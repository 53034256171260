import { Cross, IconButton } from "@myloc/myloc-gui";
import classNames from "classnames";
// import classNames from "classnames";
import PropTypes from "prop-types";

import { useTranslate } from "../../../../language/i18n";
import Label from "../../Label/Label";
// import Counter from "../../Counter/Counter";
import styles from "./ChosenSpecificAccessories.module.scss";

const DEFAULT_QUANTITY = 1;
const ChosenSpecificAccessories = ({ chosenSpecificAccessories, setChosenSpecificAccessories }) => {
  const translate = useTranslate();

  const handleQuantityChange = (accessory, newQuantity) => {
    if (newQuantity < DEFAULT_QUANTITY) newQuantity = DEFAULT_QUANTITY;

    let _selectedAccessories = [...chosenSpecificAccessories];
    const index = _selectedAccessories.indexOf(accessory);
    _selectedAccessories[index].quantity = newQuantity;
    setChosenSpecificAccessories(_selectedAccessories);
  };

  const onDelete = accessory => {
    let _selectedAccessories = [...chosenSpecificAccessories];
    const index = _selectedAccessories.indexOf(accessory);
    _selectedAccessories[index].quantity = 0;
    _selectedAccessories.splice(index, 1);
    setChosenSpecificAccessories(_selectedAccessories);
  };

  const getStartValue = accessory => {
    if (accessory.quantity == 0) handleQuantityChange(accessory, DEFAULT_QUANTITY);
  };

  return (
    <div>
      <div className={styles.container}>
        <p className={styles.listTitle}>{translate("CHOSEN_ACCESSORIES")}</p>
      </div>
      <div className={styles.list}>
        {!!chosenSpecificAccessories &&
          chosenSpecificAccessories.map(accessory => (
            <div className={styles.row} key={accessory.id}>
              <div>
                <p>{accessory.description}</p>
                <Label label={translate("PRODUCT_NUMBER")} value={accessory.productNumber} inline />
              </div>
              <div className={styles.actions}>
                <div className={styles.inputField}>
                  <div className={classNames(styles.counter)}>
                    <input
                      name="count"
                      type="number"
                      className={styles.count}
                      defaultValue={getStartValue(accessory)}
                      min={DEFAULT_QUANTITY}
                      value={accessory.quantity}
                      onChange={num => handleQuantityChange(accessory, num.target.value)}
                    />
                  </div>
                  <p className={styles.unit}>{accessory.unit}</p>
                </div>
                <div className={styles.delete}>
                  <IconButton onClick={() => onDelete(accessory)}>
                    <Cross size={"16"} />
                  </IconButton>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

ChosenSpecificAccessories.propTypes = {
  chosenSpecificAccessories: PropTypes.array,
  setChosenSpecificAccessories: PropTypes.func,
};
export default ChosenSpecificAccessories;
