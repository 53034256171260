import PropType from "prop-types";

import classNames from "classnames";
import { generatePath, Link } from "react-router-dom";
import Problem from "../../assets/icons/Problem";
import { useTranslate } from "../../language/i18n";
import { useTablet } from "../../utils/viewport";
import Label from "../shared/Label/Label";

import { Button, THEME } from "@myloc/myloc-gui";
import { useState } from "react";
import baseStorageService from "../../services/baseStorage/baseStorageService";
import pages from "../../utils/pages";
import ScrapModal from "../Scrap/ScrapModal";
import styles from "./BaseStoragesPage.module.scss";

const BaseStorageItem = ({ item, baseStorage, hideLinks }) => {
  const isTablet = useTablet();
  const translate = useTranslate();
  const [showScrapModal, setShowScrapModal] = useState(false);

  const scrapSubmit = async (reasonCodeId, quantity) => {
    const data = { balances: [{ balanceId: item.id, quantity }], reasonCodeId };
    return await baseStorageService.scrapBalance(data);
  };

  return (
    <article className={styles.item}>
      <section className={classNames(styles.section, item.deviation && styles.titleDeviation)}>
        <div>
          <p className={styles.itemTitle}>
            {item?.product?.name || item?.product?.id}
            {item?.product?.hasDeviation && <Problem customCssClass={styles.deviation} />}
          </p>
        </div>
        {!isTablet && (
          <div className={styles.itemBalance}>
            <Label
              label={translate("STOCK_BALANCE")}
              value={item?.product?.quantity + " " + item?.product?.unit}
              inline
            />
          </div>
        )}
      </section>
      <section className={styles.section}>
        <div>
          {hideLinks ? (
            <Label label={translate("PRODUCT_NUMBER_SHORT")} value={item?.product?.productNumber} inline />
          ) : (
            <Link
              to={{
                pathname: generatePath(pages.PRODUCT_DETAIL.PATH, {
                  product: item?.product?.productId,
                }),
                state: { referrer: `${window.location.pathname}${window.location.search}` },
              }}
            >
              <Label label={translate("PRODUCT_NUMBER_SHORT")} value={item?.product?.productNumber} inline />
            </Link>
          )}
          <Label label={translate("ARTICLE_CLASS")} value={item?.product?.itemClass?.label} inline />
        </div>
        <div>
          {hideLinks || !item?.product?.inventoryNumber ? (
            <Label label={translate("INVENTORY_NUMBER_SHORT")} value={item?.product?.inventoryNumber} inline />
          ) : (
            <Link
              to={{
                pathname: generatePath(pages.FIXED_ASSET.PATH, {
                  baseStorage: baseStorage.id,
                  product: item?.product?.id,
                }),
                state: {
                  baseStorage: baseStorage,
                  referrer: `${window.location.pathname}${window.location.search}`,
                },
              }}
            >
              <Label label={translate("INVENTORY_NUMBER_SHORT")} value={item?.product?.inventoryNumber} inline />
            </Link>
          )}
          <Label label={translate("BATCH_NO")} value={item?.product?.batch} inline />
        </div>
        <div>
          <Label label={translate("STORAGE_PLACE")} value={item?.location?.value} inline />
          <Label label={translate("SUPPLIER")} value={item?.product?.supplier?.label} inline />
        </div>
        <div>
          {isTablet && (
            <Label
              label={translate("STOCK_BALANCE")}
              value={item?.product?.quantity + " " + item?.product?.unit}
              inline
            />
          )}
          <Label label={translate("RESERVED")} value={item?.product?.allocatedQuantity} inline />
          <Label
            label={translate("AVAILABLE_BALANCE")}
            value={item?.product?.quantity - item?.product?.allocatedQuantity}
            inline
          />
        </div>
      </section>
      {item?.product?.quantity - item?.product?.allocatedQuantity > 0 && (
        <div className={styles.scrapButton}>
          <Button theme={THEME.PLAIN_PRIMARY} onClick={() => setShowScrapModal(true)}>
            {translate("SCRAP")}
          </Button>
        </div>
      )}
      {showScrapModal && (
        <ScrapModal
          visible={showScrapModal}
          onClose={() => setShowScrapModal(false)}
          currentAvailableBalance={item?.product?.quantity - item?.product?.allocatedQuantity}
          currentAvailableBalanceUnit={item?.product?.unit}
          isInventory={item?.product?.inventoryNumber != null}
          showCounter
          onSubmit={scrapSubmit}
        />
      )}
    </article>
  );
};

BaseStorageItem.propTypes = {
  item: PropType.object,
  baseStorage: PropType.object,
  hideLinks: PropType.bool,
};

export default BaseStorageItem;
