import { Checkbox } from "@myloc/myloc-gui";
import classNames from "classnames";
import PropType from "prop-types";
import { useEffect, useState } from "react";
import { ChevronDown, ChevronLeft, ChevronUp } from "../../../assets/icons";
import { useTranslate } from "../../../language/i18n";
import productService from "../../../services/product/productService";
import OnClickOutside from "../../../utils/OnClickOutside";
import RadioButtonGroup from "../../shared/RadioButton/RadioButtonGroup";
import SelectField from "../../shared/SelectField/SelectField";
import SideMenu from "../../shared/SideMenu/SideMenu";
import styles from "./ProductFilter.module.scss";

const ProductFilter = ({ showFilter, hideFilter, productFilters, setProductFilters, children }) => {
  const translate = useTranslate();
  const [open, setOpen] = useState("");
  const [filters, setFilters] = useState({});

  useEffect(() => {
    async function getFilters() {
      const filters = await productService.getFilters();
      let notOrderableArray = [
        { include: productFilters?.includeNotOrderable?.include, label: `${translate("SHOW_NOT_ORDERABLE")}` },
      ];
      filters.data.includeNotOrderable = notOrderableArray;
      setFilters(filters.data);
    }
    getFilters();
  }, [productFilters, translate]);

  const toggle = (type, item) => {
    let _filters = { ...productFilters };
    if (type === "includeNotOrderable") {
      _filters[type].include = !_filters[type].include;
    } else {
      _filters[type].id.includes(item.id)
        ? (_filters[type].id = _filters[type].id.filter(id => id !== item.id))
        : _filters[type].id.push(item.id);
    }

    setProductFilters(_filters);
  };

  const toggleRadio = (type, item) => {
    let _filters = { ...productFilters };
    _filters[type].id = [item.id];
    setProductFilters(_filters);
  };

  const toggleMenu = menu => {
    setOpen(open !== menu ? menu : "");
  };

  const hasFilters = () => {
    for (const filter in productFilters) {
      if (productFilters[filter].id.length || productFilters[filter].include === true) {
        return true;
      }
    }

    return false;
  };

  const countFilters = category => {
    return !!productFilters[category].length && ` (${productFilters[category].length})`;
  };

  const setFilter = (dataFilter, filter) => {
    if (productFilters[dataFilter]?.id.length && productFilters[dataFilter].id.find(id => id === filter.id)) return;

    let ids = [];

    if (filter) {
      ids.push(filter.id);
    }

    let _filters = { ...productFilters };
    _filters[dataFilter].id = ids;
    setProductFilters(_filters);
  };

  const clear = (filter, event) => {
    event.stopPropagation();
    if (filter) {
      let _filters = { ...productFilters };
      if (_filters[filter].include === true) {
        _filters[filter].include = false;
      }
      _filters[filter].id = [];
      setProductFilters(_filters);
    } else {
      const emptyFilters = { ...productFilters };
      Object.keys(productFilters).forEach(category => {
        if (category === "includeNotOrderable") {
          emptyFilters[category].include = false;
        }
        emptyFilters[category].id = [];
      });

      setProductFilters({ ...emptyFilters });
    }
  };

  const printFilterArray = (data, dataFilter) => {
    if (data?.length < 2) {
      return data?.map(row => {
        return (
          <Checkbox
            customCssClass={dataFilter !== "includeNotOrderable" && styles.item}
            key={`${row.id}-${row.label}`}
            label={row.label}
            checked={productFilters[dataFilter].id.includes(row.id) || productFilters[dataFilter].include === true}
            onChange={() => toggle(dataFilter, row)}
          />
        );
      });
    }

    return (
      <SelectField
        options={data?.map(current => {
          return {
            id: current.id,
            value: dataFilter == "itemClasses" ? `${current.label}` : `${current.value} - ${current.label}`,
          };
        })}
        key={dataFilter}
        onSelect={filter => setFilter(dataFilter, filter)}
        selectedId={productFilters[dataFilter].id?.[0]}
        customSettings={{ autocomplete: true }}
        onSelectOnMount={false}
      />
    );
  };

  const getRadioButtonGroup = (type, dataFilter) => {
    const vertical = dataFilter.presentation === "RADIO_BUTTON_VERTICAL";
    return (
      <RadioButtonGroup
        values={dataFilter.values}
        onChange={a => toggleRadio(type, a)}
        displayValue="label"
        selected={productFilters[type].id[0]}
        customCssClass={classNames(styles.radioButtons, vertical && styles.vertical)}
      />
    );
  };
  return (
    <>
      {showFilter && (
        <OnClickOutside call={hideFilter}>
          <SideMenu id="productFilter" className={styles.section}>
            <h2 className={styles.header} onClick={hideFilter}>
              <p>
                <ChevronLeft customCssClass={styles.icon} />
                {translate("FILTER")}
              </p>
              {hasFilters() && (
                <button className={styles.ghost} onClick={event => clear(null, event)}>
                  {translate("CLEAR")}
                </button>
              )}
            </h2>

            {Object.keys(productFilters).map(dataFilter => {
              const isRadioGroup =
                filters[dataFilter]?.presentation && filters[dataFilter]?.presentation.includes("RADIO_BUTTON");
              const isCheckList = filters[dataFilter]?.length < 2;

              return (
                <section
                  key={dataFilter}
                  className={classNames(styles.filterSection, open === dataFilter && styles.active)}
                >
                  <h3 className={styles.title} onClick={() => toggleMenu(dataFilter)}>
                    {translate(productFilters[dataFilter].title)}
                    {!isRadioGroup && countFilters(dataFilter)}
                    <div className={styles.arrow}>
                      {(!!productFilters[dataFilter]?.id?.length || productFilters[dataFilter]?.include === true) && (
                        <button className={styles.ghost} onClick={event => clear(dataFilter, event)}>
                          {translate("CLEAR")}
                        </button>
                      )}
                      {open === dataFilter ? <ChevronUp /> : <ChevronDown />}
                    </div>
                  </h3>

                  {open === dataFilter && (
                    <div
                      className={classNames(
                        "sideMenuList",
                        styles.list,
                        (isRadioGroup || isCheckList) && styles.overflowScroll,
                      )}
                    >
                      {filters[dataFilter] && isRadioGroup
                        ? getRadioButtonGroup(dataFilter, filters[dataFilter])
                        : printFilterArray(filters[dataFilter], dataFilter)}
                    </div>
                  )}
                </section>
              );
            })}
          </SideMenu>
        </OnClickOutside>
      )}
      {children}
    </>
  );
};

ProductFilter.defaultProps = {
  productFilters: { facilities: [], baseStorages: [] },
};

ProductFilter.propTypes = {
  showFilter: PropType.bool,
  hideFilter: PropType.func,
  productFilters: PropType.object,
  setProductFilters: PropType.func,
  facilities: PropType.array,
  baseStorages: PropType.object,
  children: PropType.node,
};

export default ProductFilter;
