//Actions
export const INITIALIZED = "INITIALIZED";
export const LOGIN_TOKEN = "SET_MYLOC_LOGIN_TOKEN";
export const REMEMBER_ME = "REMEMBER_ME";
export const AUTHORIZATION_LIST = "AUTHORIZATION_LIST";
export const USER_HAS_MESSAGE_TO_ACCEPT = "USER_HAS_MESSAGE_TO_ACCEPT";
export const IS_SINGLE_SIGN_ON = "IS_SINGLE_SIGN_ON";
export const SSO_SESSION_DATA = "SSO_SESSION_DATA";
export const SESSION_ID = "SESSION_ID";
export const SINGLE_SIGN_OUT = "SINGLE_SIGN_OUT";
