import { memo } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { useTranslate } from "../../../../language/i18n";
import { createMenu } from "../../../../utils/menu";
import Drawer from "../../../shared/Drawer/Drawer";
import styles from "../MenuDrawer/MenuDrawer.module.scss";

function SearchDrawer({ visible, onMenuLinkClick }) {
  const translate = useTranslate();
  const menuItems = createMenu("SEARCH_DRAWER");

  const toggleMenu = () => {
    onMenuLinkClick();
  };

  return (
    <Drawer title={translate("SEARCH")} visible={visible} customCssClass={styles.drawerMenu}>
      <nav className={styles.navContent}>
        <ul className={styles.menu}>
          {menuItems.map(item => (
            <li key={item.id} className={styles.menuItem}>
              <NavLink to={item.to || "/"} className={styles.menuLink} onClick={() => toggleMenu()}>
                {translate(item.label)}
              </NavLink>
            </li>
          ))}
          <li className={styles.version}>Version: {process.env.REACT_APP_VERSION}</li>
        </ul>
      </nav>
    </Drawer>
  );
}

SearchDrawer.propTypes = {
  visible: PropTypes.bool,
  onMenuLinkClick: PropTypes.func,
};

export default memo(SearchDrawer);
