import { useEffect } from "react";
import PropType from "prop-types";
import pages from "../../../../utils/pages";
import { useTranslate } from "../../../../language/i18n";
import Label from "../../../shared/Label/Label";
import styles from "./DeviationInfo.module.scss";

const DeviationInfo = ({ referrer, deviation, setBreadcrumbs }) => {
  const translate = useTranslate();

  useEffect(() => {
    setBreadcrumbs([
      {
        text: `${translate(pages.MY_MYLOC.NAME)} - ${translate(pages.MY_DEVIATIONS.NAME)}`,
        link: referrer?.path || pages.MY_DEVIATIONS.PATH,
      },
      {
        text: `${translate("DEVIATION")} - ${translate("DEVIATION_INFO")}`,
      },
    ]);
  }, [referrer, setBreadcrumbs, translate]);

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.left}>
          <h4 className={styles.title}>{`${translate("DEVIATION_INFO")}`}</h4>
        </div>
      </div>

      <div className={styles.content}>
        <div>
          <Label label={translate("CATEGORY")} value={deviation?.category?.label} inline />
          <Label label={translate("DEVIATION_CODE")} value={`${deviation?.type?.label}`} inline />
        </div>

        <div>
          <Label label={translate("CREATED")} value={deviation?.createdDateTime} inline />
          <Label
            label={translate("REFERENCE_TO")}
            value={deviation?.reference ? `${deviation?.reference?.valueLabel} - ${deviation?.reference?.value}` : ""}
            inline
          />
        </div>
      </div>

      <div>
        <Label label={translate("DESCRIPTION")} value={`${deviation?.description}`} />
      </div>
    </div>
  );
};

DeviationInfo.propTypes = {
  referrer: PropType.object,
  deviation: PropType.shape({
    category: PropType.shape({
      label: PropType.string,
    }),
    id: PropType.string,
    description: PropType.string,
    status: PropType.shape({
      id: PropType.string,
    }),
    deviationNumber: PropType.number,
    type: PropType.shape({
      label: PropType.string,
    }),
    reference: PropType.shape({
      valueLabel: PropType.string,
      value: PropType.string,
    }),
    createdDateTime: PropType.string,
  }),
  setBreadcrumbs: PropType.func,
};

export default DeviationInfo;
