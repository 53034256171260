import {
  CLIENT,
  ORDER,
  USERNAME,
  ADDRESS,
  COMPANY,
  FACILITY,
  INFORMATION,
  RECEIVER_MENU,
  CLEAR_STATE,
  CLEAR_ORDER_STATE,
  CURRENT_STEP,
  CLEAR_RECEIVER_MENU,
} from "./appDataVariables";

const initialState = {
  order: null,
  receiverMenu: null,
  user: {
    username: "",
    address: {
      addressRow1: "",
      addressRow2: "",
      postalNo: "",
      city: "",
      country: "",
      description: "",
    },
    company: {
      id: "",
      description: "",
    },
    facility: {
      id: "",
      description: "",
      value: "",
      category: { label: "", value: "" },
    },
    information: {
      firstName: "",
      surname: "",
      phoneNo: "",
      email: "",
      referenceId: "",
    },
  },
  currentStep: 0,
};

export const appDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER:
      return Object.assign({}, state, { order: action.order });
    case CLIENT:
      return Object.assign({}, state, { order: Object.assign({}, state.order, { receiver: action.client }) });
    case USERNAME:
      return Object.assign({}, state, { user: Object.assign({}, state.user, { username: action.username }) });
    case ADDRESS:
      return Object.assign({}, state, { user: Object.assign({}, state.user, { address: action.address }) });
    case COMPANY:
      return Object.assign({}, state, { user: Object.assign({}, state.user, { company: action.company }) });
    case FACILITY:
      return Object.assign({}, state, { user: Object.assign({}, state.user, { facility: action.facility }) });
    case INFORMATION:
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, {
          information: {
            ...action.information,
            id: action.information.id || state.user.information.id,
          },
        }),
      });
    case RECEIVER_MENU:
      return Object.assign({}, state, { receiverMenu: action.receiverMenu });
    case CLEAR_STATE:
      return initialState;
    case CLEAR_ORDER_STATE:
      return Object.assign({}, state, { order: initialState.order });
    case CLEAR_RECEIVER_MENU:
      return Object.assign({}, state, { receiverMenu: initialState.receiverMenu });
    case CURRENT_STEP:
      return Object.assign({}, state, { currentStep: action.currentStep });
    default:
      return state;
  }
};
