import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, Button } from "@myloc/myloc-gui";

import { useTranslate } from "../../language/i18n";
import orderService from "../../services/order/orderService";

import SelectField from "../shared/SelectField/SelectField";

import styles from "./WorkType.module.scss";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

const WorkType = ({ selected, data, onSelect, onNext }) => {
  const translate = useTranslate();
  const currentActiveStep = useSelector(state => state.appData.currentStep);

  const [isLoading, setIsLoading] = useState(false);
  const [workTypes, setWorkTypes] = useState([]);
  const params = useParams();

  useEffect(() => {
    orderService.getType(params.order, params.content).then(response => setWorkTypes(response.data.subContentType));
  }, [params]);

  const submit = async () => {
    setIsLoading(true);

    const dataToSend = { ...data };
    dataToSend.currentStep = currentActiveStep;
    dataToSend.subContentType = {
      id: selected.subContentType.id,
    };
    dataToSend.priority = {
      id: selected.priority.id,
    };

    const response = await orderService.updateContent(params.content, dataToSend);

    setIsLoading(false);

    if (response.isOk()) {
      onNext(response.data);
    }
  };

  const btnDisabled = !selected.priority;
  const priorityOptions = workTypes.find(worktype => worktype.id === selected?.subContentType?.id)?.priority || [];

  return (
    <Form className={styles.content} onSubmit={submit}>
      <SelectField
        options={workTypes}
        onSelect={subContentType =>
          selected?.subContentType?.id !== subContentType.id && onSelect({ subContentType, priority: {} })
        }
        label={translate("WORK_TYPE")}
        selectedId={selected?.subContentType?.id}
        required
      />

      <SelectField
        options={priorityOptions}
        onSelect={priority => onSelect({ priority })}
        label={translate("PRIORITY")}
        selectedId={selected?.priority?.id}
        disabled={!priorityOptions.length}
        required
      />
      <Button type="submit" isLoading={isLoading} customCssClass={styles.submitBtn} disabled={btnDisabled}>
        {translate("NEXT")}
      </Button>
    </Form>
  );
};

WorkType.propTypes = {
  orderId: PropTypes.string.isRequired,
  contentId: PropTypes.string,
  subContentType: PropTypes.array,
  data: PropTypes.object,
  selected: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  onNext: PropTypes.func,
};

WorkType.defaultProps = {
  subContentType: [],
};

export default WorkType;
