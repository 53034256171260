import PropType from "prop-types";
import { useSelector } from "react-redux";
import { withPage } from "@myloc/myloc-utils";

import pages from "../../utils/pages";
import { useTranslate } from "../../language/i18n";

import Aid from "./Aid";
import WorkType from "./WorkType";
import OrderInfo from "./OrderInfo";
import AccessoryList from "./AccessoryList";
import OrderOverview from "../shared/OrderOverview/OrderOverview";
import AdditionalInfo from "../shared/AdditionalInfo/AdditionalInfo";
import AddressAndContact from "../shared/AddressAndContact/AddressAndContact";
import WizardPage, { setActiveStep } from "../shared/WizardPage/WizardPage";
import { useParams } from "react-router";
import { CONTENT_TYPE } from "../../utils/constants";
import useOrderWizard from "../../hooks/useOrderWizard";

const WorkOrderPage = ({ currentActiveStep }) => {
  const queryParams = useParams();
  const translate = useTranslate();
  const isEmbedded = Boolean(window.location.pathname.match(/^\/embedded\//));
  const { data, setData, stepper, handleNext } = useOrderWizard(
    isEmbedded ? pages.EMBEDDED_WORK_ORDER.PATH : pages.WORK_ORDER.PATH,
    {
      contentType: CONTENT_TYPE.WORK,
    },
  );

  const order = useSelector(state => state.appData.order);
  const steps = [
    {
      title: translate("CHOOSE_ONE_AID"),
      titleShort: translate("AID"),
      children: <Aid order={order} data={data} setData={aid => setData({ ...data, ...aid })} onNext={handleNext} />,
    },
    {
      title: translate("CHOOSE_ACCESSORIES"),
      titleShort: translate("ACCESSORIES"),
      children: (
        <AccessoryList
          onSelect={accessories => {
            setData({ ...data, accessories });
          }}
          order={order}
          data={data}
          setData={accessoryList => setData({ ...data, ...accessoryList })}
          inventoryId={data?.product?.id}
          onNext={handleNext}
        />
      ),
    },
    {
      title: translate("CHOOSE_WORK_TYPE"),
      titleShort: translate("WORK_TYPE"),
      children: (
        <WorkType
          orderId={order?.id}
          data={data}
          selected={{ subContentType: data.subContentType, priority: data.priority }}
          onSelect={workType => setData({ ...data, ...workType })}
          onNext={handleNext}
        />
      ),
    },
    {
      title: translate("ADD_ADDITIONAL_INFO"),
      titleShort: translate("ADDITIONAL_INFO_SHORT"),
      children: <AdditionalInfo onNext={handleNext} />,
    },
    {
      title: translate("ADD_WORK_ORDER_ADDRESS_AND_CONTACT"),
      titleShort: translate("ADDRESS"),
      children: <AddressAndContact onNext={handleNext} />,
    },
    {
      title: translate("ORDER_OVERVIEW"),
      titleShort: translate("ORDER_OVERVIEW_SHORT"),
      children: (
        <OrderOverview>
          <OrderInfo />
        </OrderOverview>
      ),
    },
  ];

  return (
    <WizardPage
      title={translate("WORK_ORDER_TITLE")}
      steps={steps}
      activeStep={setActiveStep(queryParams.content, currentActiveStep, data.currentStep)}
      setActiveStep={stepper.setActiveStep}
    />
  );
};

WorkOrderPage.propTypes = {
  product: PropType.object,
  text: PropType.string,
  accessories: PropType.array,
  files: PropType.array,
  subContentType: PropType.object,
  priority: PropType.object,
  address: PropType.object,
  contact: PropType.object,
  search: PropType.string,
  currentStep: PropType.number,
  currentActiveStep: PropType.number,
};

export default withPage(WorkOrderPage);
