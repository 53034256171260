import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Drawer.module.scss";

const Drawer = ({ title, children, visible, customCssClass }) => {
  document.body.style.overflow = visible ? "hidden" : "";

  return (
    <div className={classNames(styles.drawer, !visible && styles.closed, customCssClass)}>
      {title && (
        <section className={styles.title}>
          <h2>{title}</h2>
        </section>
      )}

      <section className={classNames(styles.content, title && styles.withTitle)}>{children}</section>
    </div>
  );
};

Drawer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
  customCssClass: PropTypes.string,
};

export default Drawer;
