import { Button, Form, InputField, THEME } from "@myloc/myloc-gui";
import { useHistory } from "@myloc/myloc-utils";
import classNames from "classnames";
import PropType from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import FilterListIcon from "../../../assets/icons/FilterList";
import Search from "../../../assets/icons/Search";
import { useTranslate } from "../../../language/i18n";
import pages from "../../../utils/pages";
import { useMobile } from "../../../utils/viewport";
import styles from "./TopFilter.module.scss";

const TopFilter = ({ children, onSubmit, filters, setFilters, fields, label, showFilterButton = true }) => {
  const history = useHistory();
  const translate = useTranslate();
  const isMobile = useMobile();
  const params = useMemo(() => new URLSearchParams(window.location.search), []);

  const inputFieldId = "inputFieldId";

  const onClickToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const choicesParamsEmpty = params => !Object.values(getChoicesFromParams(params)).find(choice => choice);

  const getChoicesFromParams = useCallback(
    params => {
      return fields.reduce(
        (choice, key) => ({
          ...choice,
          [key]: params.get(key) || "",
        }),
        {},
      );
    },
    [fields],
  );

  const [showFilters, setShowFilters] = useState(!choicesParamsEmpty(params));
  const [search, setSearch] = useState(params.get("q") || "");

  useEffect(() => {
    if (params) {
      setFilters(getChoicesFromParams(params));
    }
  }, [getChoicesFromParams, params, setFilters]);

  const saveFilters = useCallback(() => {
    let paramsChanged = false;
    Object.keys(filters).forEach(key => {
      if (filters[key] && filters[key] != params.get(key)) {
        if (Array.isArray(filters[key])) {
          filters[key].forEach(filter => {
            params.set(key, filter.id);
            paramsChanged = true;
          });
        } else {
          params.set(key, filters[key]);
          paramsChanged = true;
        }
      } else if (!filters[key] && params.get(key)) {
        params.delete(key);
        paramsChanged = true;
      }
    });
    if (paramsChanged) {
      history.replace(pages.CLIENTS.PATH, params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, params]);

  const clearFilters = useCallback(() => Object.keys(filters).forEach(key => params.delete(key)), [filters, params]);

  useEffect(() => {
    let paramsChanged = false;
    if (search && search !== params.get("q")) {
      params.set("q", search);
      paramsChanged = true;
    } else if (!search && params.get("q")) {
      params.delete("q");
      paramsChanged = true;
    }

    showFilters ? saveFilters() : clearFilters();

    if (paramsChanged) {
      history.replace(pages.CLIENTS.PATH, params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearFilters, params, saveFilters, search, showFilters]);

  const clear = () => {
    let _filters = { ...filters };

    for (let key in _filters) {
      _filters[key] = "";
    }

    setFilters(_filters);
    setSearch("");
    selectInputField();
  };

  const clearSearch = () => {
    setSearch("");
    selectInputField();
  };

  function selectInputField() {
    const input = document.getElementById(inputFieldId);
    input.select();
  }

  return (
    <section>
      <Form onSubmit={onSubmit}>
        <div className={styles.generalSearch}>
          <InputField
            id={inputFieldId}
            label={label}
            value={search}
            onChange={event => setSearch(event.target.value)}
            customCssClass={styles.noErrorField}
            customInputCssClass={styles.input}
            onClear={clearSearch}
          />
          <div className={classNames(styles.buttons, !showFilterButton && styles.button)}>
            {showFilterButton && (
              <Button
                type="button"
                customCssClass={classNames(styles.icon, isMobile ? styles.mobileSearch : "")}
                onClick={onClickToggleFilters}
              >
                <FilterListIcon customCssClass={styles.space} />
              </Button>
            )}

            <Button type="submit" customCssClass={isMobile ? styles.mobileSearch : ""}>
              {isMobile ? <Search /> : translate("SEARCH")}
            </Button>
          </div>
        </div>

        {showFilters && (
          <div className={styles.filters}>
            {children}

            <div className={styles.clearButton}>
              <Button type="button" customCssClass={styles.noBorder} theme={THEME.SECONDARY} onClick={clear}>
                {translate("CLEAR")}
              </Button>
            </div>
          </div>
        )}
      </Form>
    </section>
  );
};

TopFilter.propTypes = {
  onSubmit: PropType.func.isRequired,
  children: PropType.object,
  filters: PropType.object,
  setFilters: PropType.func,
  fields: PropType.array,
  label: PropType.string,
  showFilterButton: PropType.bool,
};

export default TopFilter;
