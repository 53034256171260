import PropTypes from "prop-types";
import { Target } from "../Constants/constants";

const DownloadLink = ({ url, target = Target.FULL, download, children, customCssClass }) => (
  <a href={url instanceof URL ? url.toString : url} download={download} target={target} className={customCssClass}>
    {children}
  </a>
);

export default DownloadLink;

DownloadLink.propTypes = {
  url: PropTypes.oneOfType([PropTypes.instanceOf(URL), PropTypes.string]).isRequired,
  target: PropTypes.oneOf([Target.SELF, Target.NEW_TAB, Target.PARENT, Target.FULL]),
  download: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  customCssClass: PropTypes.string,
};
