import { Button } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { useCallback, useState } from "react";
import { useTranslate } from "../../../language/i18n";
import orderService from "../../../services/order/orderService";
import CommentRow from "./CommentRow/CommentRow";
import styles from "./Comments.module.scss";
import CreateCommentModal from "./Modal/CreateCommentModal";
import DeleteCommentModal from "./Modal/DeleteCommentModal";

const Comments = ({ fittingId, comments, type, onCommentCreated, onCommentDeleted }) => {
  const translate = useTranslate();
  const [chosenCommentId, setChosenCommentId] = useState();
  const [createCommentVisible, setCreateCommentVisible] = useState(false);
  const [deleteCommentVisible, setDeleteCommentVisible] = useState(false);

  const createComment = useCallback(
    async comment => {
      const data = {
        type,
        comment,
      };
      const response = await orderService.createFittingComment(fittingId, data);

      if (response.isOk()) {
        setCreateCommentVisible(false);
        onCommentCreated(type, response.data);
      }
    },
    [fittingId, type, onCommentCreated],
  );

  const onDeleteClicked = comment => {
    setChosenCommentId(comment.id);
    setDeleteCommentVisible(true);
  };

  const deleteComment = async () => {
    const response = await orderService.deleteFittingComment(chosenCommentId);

    if (response.isOk()) {
      setDeleteCommentVisible(false);
      onCommentDeleted(type, chosenCommentId);
      setChosenCommentId(undefined);
    }
  };

  return (
    <section>
      <h3 className={styles.title}>{translate(`${type}_COMMENTS`)}</h3>
      {comments?.map(comment => {
        return <CommentRow key={comment.id} comment={comment} onDeleteClicked={onDeleteClicked} />;
      })}
      <div className={styles.button}>
        <Button onClick={() => setCreateCommentVisible(true)}>{translate("CREATE")}</Button>
      </div>

      <CreateCommentModal
        visible={createCommentVisible}
        setVisible={setCreateCommentVisible}
        type={type}
        onSubmit={createComment}
        onCancel={() => setCreateCommentVisible(false)}
      />
      <DeleteCommentModal
        visible={deleteCommentVisible}
        setVisible={setDeleteCommentVisible}
        onSubmit={deleteComment}
        onCancel={() => setDeleteCommentVisible(false)}
      />
    </section>
  );
};

Comments.propTypes = {
  fittingId: PropType.string,
  comments: PropType.object,
  type: PropType.string,
  onCommentCreated: PropType.func,
  onCommentDeleted: PropType.func,
};

export default Comments;
