import PropTypes from "prop-types";
import classNames from "classnames";
import Check from "../../../assets/icons/Check";

import styles from "./Checkbox.module.scss";

function Checkbox({ label, name, onChange, checked, disabled, id, customCssClass }) {
  return (
    <label className={classNames(styles.checkbox, disabled && styles.disabled, customCssClass)}>
      {label}
      <input id={id} type="checkbox" checked={checked} name={name} disabled={disabled} onChange={onChange} />
      <span className={classNames(styles.checkmark, checked && styles.checked)}>{checked && <Check size="14" />}</span>
    </label>
  );
}

Checkbox.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.node,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  customCssClass: PropTypes.string,
};

export default Checkbox;
