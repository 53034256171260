import PropType from "prop-types";
import { useEffect, useState } from "react";
import { useTranslate } from "../../language/i18n";
import productService from "../../services/product/productService";
import Counter from "../shared/Counter/Counter";
import ConfirmModal from "../shared/Modals/ConfirmModal";
import SelectField from "../shared/SelectField/SelectField";
import styles from "./ScrapModal.module.scss";

const ScrapModal = ({
  visible,
  onClose,
  showCounter,
  isInventory,
  currentAvailableBalance,
  currentAvailableBalanceUnit,
  onSubmit,
}) => {
  const translate = useTranslate();
  const [chosenBalance, setChosenBalance] = useState(null);
  const [availableScrapReasons, setAvailableScrapReasons] = useState(null);
  const [selectedScrapReasonId, setSelectedScrapReasonId] = useState(null);

  const submit = async () => {
    const response = await onSubmit(selectedScrapReasonId, chosenBalance);
    if (response.isOk()) {
      onCancel();
      window.location.reload();
    }
  };

  const onCancel = () => {
    setChosenBalance(null);
    setSelectedScrapReasonId(null);
    onClose();
  };
  const handleScrapReason = reason => {
    setSelectedScrapReasonId(reason.id);
  };

  useEffect(() => {
    if (visible)
      productService
        .getReasonCodes({ filters: { all: true, reasonCodeCategory: "Scrapping:=" } })
        .then(response => setAvailableScrapReasons(response.data.page));
  }, [visible]);

  return (
    <ConfirmModal
      small={false}
      visible={visible}
      cancel={onCancel}
      title={translate("SCRAP")}
      confirmButtonDisabled={!selectedScrapReasonId}
      submit={submit}
    >
      <div className={styles.section}>
        <p>{translate("SCRAP_REASON_AND_CONFIRM_INFORMATION")}</p>
        {showCounter && (
          <p className={styles.amountToScrap}>
            <span>{translate("AMOUNT_TO_SCRAP")}:</span>

            {isInventory ? (
              <span
                className={styles.availableBalance}
              >{`${currentAvailableBalance} ${currentAvailableBalanceUnit}`}</span>
            ) : (
              <span className={styles.counter}>
                <Counter
                  onChange={setChosenBalance}
                  min={1}
                  max={currentAvailableBalance}
                  startValue={currentAvailableBalance}
                />
              </span>
            )}
          </p>
        )}

        <SelectField
          options={availableScrapReasons}
          label={translate("SCRAPPING_REASON")}
          onSelect={handleScrapReason}
          customSettings={{ display: "description" }}
          customListCss={styles.list}
          selectedId={selectedScrapReasonId}
          required
        />
      </div>
    </ConfirmModal>
  );
};

ScrapModal.propTypes = {
  visible: PropType.bool,
  onClose: PropType.func,
  showCounter: PropType.bool,
  isInventory: PropType.bool,
  currentAvailableBalance: PropType.number,
  currentAvailableBalanceUnit: PropType.string,
  onSubmit: PropType.func,
};

export default ScrapModal;
