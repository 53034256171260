import { useEffect, useState } from "react";

import { withPage } from "@myloc/myloc-utils";
import { useTranslate } from "../../language/i18n";
import orderService from "../../services/order/orderService";
import pages from "../../utils/pages";
import { CLIENT_TYPE, CONTENT_TYPE } from "../../utils/constants";

import Page from "../shared/Page/Page";
import RecieverInformation from "../shared/RecieverInformation/RecieverInformation";
import {
  Function,
  NewProduct,
  Pickup,
  ProductDelivery,
  ProductPickup,
  Fitting,
  Work,
} from "../shared/OrderListComponents/Types/Types";
import styles from "./OrderDetailPage.module.scss";
import { useLocation, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useMobile } from "../../utils/viewport";
import TotalPrice from "../shared/Price/TotalPrice";

function OrderDetailPage() {
  const translate = useTranslate();
  const location = useLocation();
  const isMobile = useMobile();
  const receiverMenu = useSelector(state => state?.appData?.receiverMenu);
  const [groupedContentList, setGroupedContentList] = useState([]);
  const queryParams = useParams();
  const [order, setOrder] = useState(null);

  useEffect(() => {
    orderService.getOrder(queryParams.order).then(response => setOrder(response.data));
  }, [queryParams.order]);

  useEffect(() => {
    if (order?.content) {
      const groupedOrders = order.content
        .sort((a, b) => a.status - b.status)
        .reduce(
          (accumulator, item) => (
            (accumulator[item.contentType] || (accumulator[item.contentType] = [])).push(item), accumulator
          ),
          {},
        );
      setGroupedContentList(groupedOrders);
    }
  }, [order?.content]);

  const breadcrumbs = [
    {
      text: location?.state?.referrer?.match(/clients/)
        ? `${receiverMenu ? translate("CLIENT") : translate("SEARCH_CLIENT")} - ${translate(pages.CLIENT_ORDERS.NAME)}`
        : `${translate(pages.MY_MYLOC.NAME)} - ${translate(pages.MY_ORDERS.NAME)}`,
      link: location?.state?.referrer ? location?.state?.referrer : pages.MY_ORDERS.PATH,
    },
    {
      text: `${translate("ORDER")} ${order?.orderNumber}`,
    },
  ];

  if (!order) {
    return <></>;
  }

  return (
    <Page
      title={translate(pages.ORDER_DETAIL.NAME)}
      description={`${order?.orderNumber} (${translate(`ORDER_STATUS_${order?.status}`)}` + ")"}
      customHeaderCssClass={isMobile ? styles.page : undefined}
      breadcrumbs={breadcrumbs}
    >
      {isMobile && (
        <h3>
          {order?.receiver?.type === CLIENT_TYPE.USER
            ? `${order?.receiver?.firstName} ${order?.receiver?.surname} - ${order?.receiver?.identityNumber}`
            : `${order?.receiver?.value} - ${order?.receiver.label} `}
        </h3>
      )}
      <div className={styles.layout}>
        <section className={styles.content}>
          {groupedContentList[CONTENT_TYPE.FUNCTION] && (
            <Function list={groupedContentList[CONTENT_TYPE.FUNCTION]} readOnly />
          )}
          {groupedContentList[CONTENT_TYPE.PRODUCT_PICKUP] && (
            <ProductPickup list={groupedContentList[CONTENT_TYPE.PRODUCT_PICKUP]} readOnly />
          )}
          {groupedContentList[CONTENT_TYPE.PRODUCT_DELIVERY] && (
            <ProductDelivery list={groupedContentList[CONTENT_TYPE.PRODUCT_DELIVERY]} readOnly />
          )}
          {groupedContentList[CONTENT_TYPE.NEW_PRODUCT] && (
            <NewProduct list={groupedContentList[CONTENT_TYPE.NEW_PRODUCT]} readOnly />
          )}
          {groupedContentList[CONTENT_TYPE.FITTING] && (
            <Fitting list={groupedContentList[CONTENT_TYPE.FITTING]} readOnly />
          )}
          {groupedContentList[CONTENT_TYPE.WORK] && <Work list={groupedContentList[CONTENT_TYPE.WORK]} readOnly />}
          {groupedContentList[CONTENT_TYPE.PICKUP] && (
            <Pickup list={groupedContentList[CONTENT_TYPE.PICKUP]} readOnly />
          )}
          <TotalPrice list={groupedContentList} />
        </section>
        {!isMobile && <RecieverInformation customCssClass={styles.info} receiverData={order?.receiver} />}
      </div>
    </Page>
  );
}

export default withPage(OrderDetailPage);
