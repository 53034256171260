import PropType from "prop-types";
import { useTranslate } from "../../../language/i18n";
import styles from "./EmptyList.module.scss";

const EmptyList = ({ text }) => {
  const translate = useTranslate();

  return (
    <section className={styles.emptyList}>
      <p>{translate(text)}</p>
    </section>
  );
};

EmptyList.propTypes = {
  text: PropType.string.isRequired,
};

export default EmptyList;
