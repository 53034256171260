import { Box, Button, Form, InputField, THEME } from "@myloc/myloc-gui";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "../../language/i18n";
import { setFacility, setInformation } from "../../reducers/appData/appDataActions";
import { setMessage } from "../../reducers/dialog/dialogAction";
import accountService from "../../services/account/accountService";
import isEqual from "../../utils/compareObjects";
import FacilityAddress from "../shared/AddressAndContact/FacilityAddress/FacilityAddress";
import Page from "../shared/Page/Page";
import PasswordChange from "./PasswordChange";
import styles from "./UserSettingsPage.module.scss";

const UserSettingsPage = () => {
  const user = useSelector(state => state.appData.user);
  const info = user?.information;
  const facility = user?.facility;
  const translate = useTranslate();

  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [currentFacility, setCurrentFacility] = useState(facility?.id ? facility : null);

  const [passwordChangeVisible, setPasswordChangeVisible] = useState(false);
  const infoHasChanged = !info || !userInfo || !isEqual(info, userInfo);
  const facilityHasChanged = facility?.id !== currentFacility?.id;
  const btnDisabled = !infoHasChanged && !facilityHasChanged;

  useEffect(() => {
    if (info) {
      setUserInfo(info);
    }
  }, [info]);

  const handleFieldChange = e => {
    const field = e.target;
    setUserInfo({ ...userInfo, [field.name]: field.value });
  };

  const handleSelect = useCallback(val => {
    setCurrentFacility(val);
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    let success = true;
    if (infoHasChanged) {
      const response = await accountService.updateAccountSettings({
        firstName: userInfo.firstName,
        surname: userInfo.surname,
        email: userInfo.email,
        phoneNo: userInfo.phoneNo,
      });

      if (response.isOk()) {
        setInformation(response.data);
      } else {
        success = false;
      }
    }

    if (facilityHasChanged) {
      const response = await accountService.updateStandardLocation({
        facilityId: currentFacility?.id,
      });

      if (response.isOk()) {
        const { id, description, value, category } = currentFacility;
        setFacility({ id, description, value, category });
      } else {
        success = false;
      }
    }

    if (success) {
      setMessage(translate("CHANGES_SAVED"));
    }

    setLoading(false);
  };

  return (
    <Page customCssClass={styles.userPage} spaceBetween>
      <h2 className={styles.title}>{translate("USER_SETTINGS")}</h2>
      <Form className={styles.form} onSubmit={handleSubmit}>
        <Box title={translate("PERSONAL_DATA")}>
          <InputField
            label={translate("FIRST_NAME")}
            name="firstName"
            customCssClass={styles.input}
            value={userInfo?.firstName || ""}
            onChange={handleFieldChange}
            required
          />
          <InputField
            label={translate("LAST_NAME")}
            name="surname"
            customCssClass={styles.input}
            value={userInfo?.surname || ""}
            onChange={handleFieldChange}
            required
          />
          <InputField
            label={translate("PHONE_NO")}
            name="phoneNo"
            customCssClass={styles.input}
            value={userInfo?.phoneNo || ""}
            onChange={handleFieldChange}
            required
          />
          <InputField
            label={translate("EMAIL")}
            name="email"
            type="email"
            customCssClass={styles.input}
            value={userInfo?.email || ""}
            onChange={handleFieldChange}
            required
          />
        </Box>
        <Box title={translate("DEFAULT_BASE_STORAGE")}>
          <FacilityAddress preSelected={currentFacility} onSelect={handleSelect} />
        </Box>
        <Button type="submit" customCssClass={styles.btn} isLoading={isLoading} disabled={btnDisabled}>
          {translate("SAVE")}
        </Button>
      </Form>

      <h2 className={styles.title}>{translate("PASSWORD_MANAGEMENT")}</h2>
      <Box title={translate("PASSWORD")} customCssClass={styles.password}>
        <section className={styles.section}>
          <div>
            <p className={styles.header}>{translate("USERNAME")}</p>
            <p className={styles.text}>{decodeURIComponent(info?.id)}</p>
          </div>
        </section>
        <section className={styles.section}>
          <div>
            <p className={styles.header}>{translate("PASSWORD")}</p>
            <p className={styles.text}>************</p>
          </div>

          <Button theme={THEME.SECONDARY} onClick={() => setPasswordChangeVisible(true)} customCssClass={styles.button}>
            {translate("CHANGE_PASSWORD")}
          </Button>
        </section>
      </Box>
      <PasswordChange visible={passwordChangeVisible} onClose={() => setPasswordChangeVisible(false)} />
    </Page>
  );
};
export default UserSettingsPage;
