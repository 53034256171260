import PropTypes from "prop-types";
import OrderControl from "./OrderControl";
import StorageSelector from "./StorageSelector";
import styles from "./StorageInformation.module.scss";

const StorageInformation = ({
  product: { storageBalances, isOrderable, orderControl },
  selectedStorage,
  onSelectStorage,
  receiverLocation,
  checked,
  onToggle,
}) => {
  return (
    <div className={styles.container}>
      <StorageSelector
        storageBalances={storageBalances}
        selectedStorage={selectedStorage}
        onSelectStorage={onSelectStorage}
      />
      {isOrderable && (
        <OrderControl
          receiverLocation={receiverLocation.id}
          checked={checked}
          onToggle={onToggle}
          orderControl={orderControl}
        />
      )}
    </div>
  );
};
StorageInformation.propTypes = {
  product: PropTypes.object,
  selectedStorage: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    quantity: PropTypes.number,
  }),
  onSelectStorage: PropTypes.func,
  receiverLocation: PropTypes.string,
  checked: PropTypes.bool,
  onToggle: PropTypes.func,
};
export default StorageInformation;
