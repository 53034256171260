import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, InputField, Modal, Button } from "@myloc/myloc-gui";
import clientService from "../../../../services/client/clientService";
import { useTranslate } from "../../../../language/i18n";
import isEqual from "../../../../utils/compareObjects";
import styles from "./AddressModal.module.scss";

function AddressModal({ userId, visible, address, onClose }) {
  const [currAddress, setCurrAddress] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const translate = useTranslate();

  useEffect(() => {
    if (address) {
      setCurrAddress(address);
    }
  }, [address]);

  const handleFieldChange = e => {
    const field = e.target;
    setCurrAddress({ ...currAddress, [field.name]: field.value });
  };

  const handleOnClose = addr => {
    setCurrAddress(null);
    onClose(addr);
  };

  const handleSubmit = async () => {
    if (address && isEqual(address, currAddress)) {
      handleOnClose();
    } else {
      setLoading(true);
      const addressId = currAddress.id || "";
      const response = addressId
        ? await clientService.updateAddress(userId, addressId, {
            description: currAddress.description,
            careOf: currAddress.careOf,
            street: currAddress.street,
            city: currAddress.city,
            postalNo: currAddress.postalNo,
          })
        : await clientService.createAddress(userId, {
            description: currAddress.description,
            careOf: currAddress.careOf,
            street: currAddress.street,
            city: currAddress.city,
            postalNo: currAddress.postalNo,
          });
      setLoading(false);
      if (response.isOk()) {
        handleOnClose(response.data);
      }
    }
  };

  const modalTitle = address ? translate("EDIT_ADDRESS") : translate("NEW_ADDRESS");

  return (
    <Modal visible={visible} title={modalTitle} onClose={handleOnClose} customCssClass={styles.modal}>
      <div className={styles.addressModal}>
        {!address && <p className={styles.info}>{translate("NEW_ADDRESS_INFO")}</p>}
        <Form onSubmit={handleSubmit} className={styles.form}>
          <InputField
            label={translate("ADDRESS_DESCRIPTION")}
            name="description"
            type={"text"}
            value={currAddress?.description || ""}
            onChange={handleFieldChange}
            autoFocus
            required
          />
          <InputField
            label={translate("CARE_OF")}
            name="careOf"
            type={"text"}
            value={currAddress?.careOf || ""}
            onChange={handleFieldChange}
          />
          <InputField
            label={translate("ADDRESS")}
            name="street"
            type={"text"}
            value={currAddress?.street || ""}
            onChange={handleFieldChange}
            required
          />
          <InputField
            label={translate("POSTAL_NO")}
            name="postalNo"
            type={"text"}
            value={currAddress?.postalNo || ""}
            onChange={handleFieldChange}
            required
            placeholder="XXXXX"
          />
          <InputField
            label={translate("CITY")}
            name="city"
            type={"text"}
            value={currAddress?.city || ""}
            onChange={handleFieldChange}
            required
          />
          <Button type="submit" isLoading={isLoading} customCssClass={styles.saveBtn}>
            {translate("SAVE")}
          </Button>
        </Form>
      </div>
    </Modal>
  );
}

AddressModal.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  visible: PropTypes.bool,
  address: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    careOf: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    postalNo: PropTypes.string,
    type: PropTypes.string,
  }),
  onClose: PropTypes.func,
};

export default AddressModal;
