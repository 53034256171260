import PropType from "prop-types";
import { Button, TextArea } from "@myloc/myloc-gui";
import { useState } from "react";
import { useTranslate } from "../../../language/i18n";
import AccordionItem from "../AccordionItem/AccordionItem";
import styles from "./Note.module.scss";

const Note = ({ productNote, onSaveNote }) => {
  const translate = useTranslate();
  const [note, setNote] = useState(productNote);
  const [editNote, setEditNote] = useState(false);

  const onCancelEditNote = e => {
    e.preventDefault();
    setEditNote(false);
    setNote(productNote);
  };

  const saveNote = e => {
    e.preventDefault();
    onSaveNote(note);
    setEditNote(false);
  };

  return (
    <AccordionItem title={translate("NOTES")}>
      <TextArea
        disabled={!editNote}
        placeholder={!note && translate("NO_NOTE_SET")}
        defaultValue={note}
        value={note}
        onChange={e => setNote(e.target.value)}
      />
      {editNote ? (
        <div className={styles.buttons}>
          <Button onClick={e => onCancelEditNote(e)}>{translate("CANCEL")}</Button>
          <Button onClick={e => saveNote(e)}>{translate("SAVE")}</Button>
        </div>
      ) : (
        <Button onClick={() => setEditNote(editNote => !editNote)}>
          {note ? translate("EDIT") : translate("ADD_NOTE")}
        </Button>
      )}
    </AccordionItem>
  );
};
Note.propTypes = {
  productNote: PropType.string,
  onSaveNote: PropType.func.isRequired,
};
export default Note;
