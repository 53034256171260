import PropType from "prop-types";
import { useTranslate } from "../../../language/i18n";
import Modal from "../../shared/Modals/Modal";
import { Button, THEME } from "@myloc/myloc-gui";
import styles from "./CartModals.module.scss";

const SendOrderModal = ({ visible, isLoading, onSend, onClose }) => {
  const translate = useTranslate();
  return (
    <Modal visible={visible} isLoading={isLoading} title={translate("SEND_ORDER")} small onClose={onClose}>
      <p>{translate("SEND_ORDER_TEXT")}</p>
      <footer className={styles.buttons}>
        <Button theme={THEME.SECONDARY} onClick={onClose}>
          {translate("NO")}
        </Button>
        <Button onClick={() => onSend()}>{translate("YES")}</Button>
      </footer>
    </Modal>
  );
};

SendOrderModal.propTypes = {
  visible: PropType.bool,
  isLoading: PropType.bool,
  onSend: PropType.func.isRequired,
  onClose: PropType.func.isRequired,
};

export default SendOrderModal;
