import { Button, Form, InputField, THEME } from "@myloc/myloc-gui";
import { withPage } from "@myloc/myloc-utils";
import PropTypes from "prop-types";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslate } from "../../../language/i18n";
import { setMessage } from "../../../reducers/dialog/dialogAction";
import accountService from "../../../services/account/accountService";
import pages from "../../../utils/pages";
import styles from "./ResetPasswordPage.module.scss";

function ResetPasswordPage({ search }) {
  const translate = useTranslate();
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const searchParams = new URLSearchParams(search);
  const token = searchParams.get("resettoken");

  const handleNewPassword = async () => {
    setIsLoading(true);
    const data = {
      password: password,
      passwordRepeat: repeatPassword,
    };
    const response = await accountService.updatePassword(token, data);

    if (response.isOk()) {
      setMessage(response.data.message);
      history.push("/");
    }
    setIsLoading(false);
  };

  const disabled = password === "" || password !== repeatPassword;

  //TODO add password pattern?
  return (
    <div className={styles.passwordContainer}>
      <Form onSubmit={handleNewPassword} className={styles.form}>
        <h2 className={styles.title}>{translate(pages.RESET_PASSWORD.NAME)}</h2>
        <InputField
          label={translate("NEW_PASSWORD")}
          onChange={e => setPassword(e.currentTarget.value)}
          value={password}
          required
          type={"password"}
        />
        <InputField
          label={translate("REPEAT_PASSWORD")}
          onChange={e => setRepeatPassword(e.currentTarget.value)}
          value={repeatPassword}
          required
          type={"password"}
        />
        <div className={styles.buttons}>
          <Button theme={THEME.PLAIN_PRIMARY} onClick={() => history.push("/")}>
            {translate("CANCEL")}
          </Button>
          <Button type="submit" isLoading={isLoading} disabled={disabled}>
            {translate("SAVE_NEW_PASSWORD")}
          </Button>
        </div>
      </Form>
    </div>
  );
}

ResetPasswordPage.propTypes = {
  search: PropTypes.string,
};

export default withPage(ResetPasswordPage);
