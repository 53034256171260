import PropType from "prop-types";
import { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Link, generatePath } from "react-router-dom";
import { useHistory } from "@myloc/myloc-utils";
import { Button, InputField } from "@myloc/myloc-gui";
import { useTranslate } from "../../../language/i18n";
import { useMobile } from "../../../utils/viewport";
import pages from "../../../utils/pages";
import deviationService from "../../../services/deviation/deviationService";

import Page from "../../shared/Page/Page";
import styles from "./Deviations.module.scss";
import Label from "../../shared/Label/Label";
import Paginate from "../../shared/Pagination/Paginate";
import RadioButtonGroup from "../../shared/RadioButton/RadioButtonGroup";
import LoadingSpinner from "../../shared/Spinner/LoadingSpinner";
import Search from "../../../assets/icons/Search";

const DEFAULT_FILTER_STATE = "OPEN";

const Deviations = ({ setBreadcrumbs }) => {
  const history = useHistory();
  const isMobile = useMobile();
  const translate = useTranslate();
  const params = new URLSearchParams(window.location.search);
  const userInformation = useSelector(state => state?.appData?.user?.information);
  const [deviations, setDeviations] = useState([]);
  const [filterState, setFilterState] = useState(params.get("state") || DEFAULT_FILTER_STATE);
  const [freeText, setFreeText] = useState(params.get("query") || "");
  const [freeTextSearch, setFreeTextSearch] = useState(freeText);
  const [isLoading, setIsLoading] = useState(false);

  const filterSearch = () => {
    setFreeTextSearch(freeText);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (!freeText) {
      params.delete("query");
    } else if (freeText !== params.get("query")) {
      params.set("query", freeText);
    }
    history.replace(pages.PRODUCTS.PATH, params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freeText]);

  useEffect(() => {
    setBreadcrumbs([{ text: `${translate(pages.MY_MYLOC.NAME)} - ${translate(pages.MY_DEVIATIONS.NAME)}` }]);
  }, [setBreadcrumbs, translate]);

  const filterStates = [
    { id: "OPEN", value: translate("OPEN") },
    { id: "CLOSED", value: translate("CLOSED") },
    { id: "ALL", value: translate("ALL") },
  ];

  const handleFilterStateChange = async selected => {
    setHistory(selected);
    setFilterState(selected.id);
  };

  const setHistory = selected => {
    const params = new URLSearchParams(window.location.search);
    params.set("state", selected.id);
    history.replace(pages.MY_DEVIATIONS.PATH, params);
  };

  const onKeyDown = event => {
    if (event.key === "Enter") {
      setFreeTextSearch(event.target.value);
    }
  };

  const onClearSearch = () => {
    setFreeText("");
  };

  return (
    <Page customCssClass={styles.page} title={translate(pages.MY_DEVIATIONS.NAME)}>
      <Paginate
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        onChange={setDeviations}
        provider={useCallback(
          filter =>
            deviationService.getDeviations({
              freeTextSearch,
              state: filterState === "ALL" ? "" : filterState,
              sort: "createdDateTime:desc",
              userId: userInformation?.id ? decodeURIComponent(userInformation?.id) : null,
              ...filter,
            }),
          [freeTextSearch, filterState, userInformation?.id],
        )}
      >
        <section>
          <div className={styles.generalSearch}>
            <InputField
              label={translate("SEARCH_FOR_DEVIATION")}
              value={freeText}
              onChange={event => setFreeText(event.target.value)}
              customCssClass={styles.noErrorField}
              onKeyDown={onKeyDown}
              onClear={onClearSearch}
            />

            <Button type="button" onClick={filterSearch} customCssClass={styles.button}>
              {isMobile ? <Search /> : translate("SEARCH")}
            </Button>
          </div>
          <RadioButtonGroup
            values={filterStates}
            onChange={handleFilterStateChange}
            selected={filterState}
            customCssClass={styles.filter}
          />
        </section>

        <ul className={styles.wrapper}>
          {isLoading ? (
            <LoadingSpinner title="DEVIATIONS_LOADING" />
          ) : (
            !!deviations?.length &&
            deviations?.map(deviation => {
              return (
                <li className={styles.card} key={deviation.id}>
                  <div className={styles.content}>
                    <div className={styles.deviationInfo}>
                      <div className={styles.bold}>
                        <Link
                          to={{
                            pathname: generatePath(pages.MY_DEVIATIONS_INFO.PATH, {
                              deviation: deviation.deviationNumber,
                            }),
                            state: {
                              referrer: {
                                path: `${window.location.pathname}${window.location.search}`,
                              },
                            },
                          }}
                        >{`${translate("DEVIATION")}: ${deviation.deviationNumber}`}</Link>
                      </div>
                      <div>
                        <p>{deviation.description}</p>
                      </div>
                    </div>

                    <div>
                      <Label label={translate("CATEGORY")} value={deviation.category?.value} inline />
                      <Label label={translate("DEVIATION_CODE")} value={deviation.type?.label} inline />
                      {deviation.reference && (
                        <Label
                          label={translate("REFERENCE_TO")}
                          value={`${deviation?.reference?.valueLabel} - ${deviation?.reference?.value}`}
                          inline
                        />
                      )}
                    </div>

                    <div>
                      <div className={styles.right}>
                        <Label label={translate("CREATED")} value={deviation.createdDateTime} inline />
                      </div>
                      {deviation.status.id !== "10" && (
                        <div
                          className={classNames(styles.status, styles.right, styles[`status${deviation.status.id}`])}
                        >
                          {translate(`DEVIATION_STATUS_${deviation.status.id}`)}
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              );
            })
          )}
        </ul>
      </Paginate>
    </Page>
  );
};

Deviations.propTypes = {
  setBreadcrumbs: PropType.func,
};

export default Deviations;
