import { Button } from "@myloc/myloc-gui";
import { withPage } from "@myloc/myloc-utils";
import { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useTranslate } from "../../../language/i18n";
import pages from "../../../utils/pages";
import Page from "../../shared/Page/Page";
import SubPageMenu from "../../shared/SubPageMenu/SubPageMenu";
import Message from "./Message/Message";
import styles from "./MessageList.module.scss";
import { MESSAGE_TYPE } from "./MessageTypes";
import NewMessageModal from "./NewMessage/NewMessageModal";

const MessageList = () => {
  const translate = useTranslate();
  const isEmbedded = Boolean(window.location.pathname.match(/^\/embedded\//));

  const [newMessageModalVisible, setNewMessageModalVisible] = useState(false);

  const [breadcrumbs, setBreadcrumbs] = useState([
    { text: `${translate(pages.MESSAGES.NAME)} - ${translate(pages.MESSAGES_INBOX.NAME)}` },
  ]);

  const createPath = (path, displayEmbeddedPath) => (isEmbedded || displayEmbeddedPath ? "/embedded" + path : path);

  const links = [
    { NAME: pages.MESSAGES_INBOX.NAME, PATH: createPath(pages.MESSAGES_INBOX.PATH) },
    { NAME: pages.MESSAGES_SENT.NAME, PATH: createPath(pages.MESSAGES_SENT.PATH) },
    { NAME: pages.MESSAGES_ARCHIVED.NAME, PATH: createPath(pages.MESSAGES_ARCHIVED.PATH) },
    { NAME: pages.MESSAGES_ALL.NAME, PATH: createPath(pages.MESSAGES_ALL.PATH) },
  ];

  return (
    <Page breadcrumbs={breadcrumbs} title={translate(pages.MESSAGES.NAME)}>
      <div className={styles.pageContent}>
        <SubPageMenu links={links} childrenBeforeList={true}>
          <div className={styles.newMessage}>
            <Button onClick={() => setNewMessageModalVisible(visible => !visible)}>{translate("NEW_MESSAGE")}</Button>
          </div>
        </SubPageMenu>
        <Switch>
          <Route exact path={[pages.MESSAGES_INBOX.PATH, createPath(pages.MESSAGES_INBOX.PATH, true)]}>
            <Message setBreadcrumbs={setBreadcrumbs} messageType={MESSAGE_TYPE.INBOX} />
          </Route>

          <Route exact path={[pages.MESSAGES_SENT.PATH, createPath(pages.MESSAGES_SENT.PATH, true)]}>
            <Message setBreadcrumbs={setBreadcrumbs} messageType={MESSAGE_TYPE.SENT} />
          </Route>

          <Route exact path={[pages.MESSAGES_ARCHIVED.PATH, createPath(pages.MESSAGES_ARCHIVED.PATH, true)]}>
            <Message setBreadcrumbs={setBreadcrumbs} messageType={MESSAGE_TYPE.ARCHIVED} />
          </Route>

          <Route exact path={[pages.MESSAGES_ALL.PATH, createPath(pages.MESSAGES_ALL.PATH, true)]}>
            <Message setBreadcrumbs={setBreadcrumbs} messageType={MESSAGE_TYPE.ALL} />
          </Route>
          <Route path="/">
            <Redirect to={createPath(pages.MESSAGES_INBOX.PATH)} />
          </Route>
        </Switch>

        <NewMessageModal visible={newMessageModalVisible} setVisible={setNewMessageModalVisible} />
      </div>
    </Page>
  );
};

export default withPage(MessageList);
