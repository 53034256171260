import { IconButton } from "@myloc/myloc-gui";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { CircleAddFilled, Trash } from "../../../../assets/icons";
import { useTranslate } from "../../../../language/i18n";
import orderService from "../../../../services/order/orderService";
import { FileDownload } from "../FileDownload/FileDownload";
import styles from "./FileUpload.module.scss";

function FileUpload({
  contentId,
  files,
  acceptedFilesList,
  authUploadId = "FSP_UploadWebOrderContentFiles",
  authDeleteId = "FSP_DeleteWebOrderContentFile",
  setFetchFittings,
  customCssClass,
  iconSize,
  noFitting,
  isCompleted,
}) {
  const params = useParams();
  const authorizationList = useSelector(state => state.session.authorizationList);

  let uploadIsAuthorized;
  let deleteIsAuthorized;
  if (setFetchFittings) {
    uploadIsAuthorized = authorizationList?.filter(item => item.type === "FUNCTION" && item.id === authUploadId);
    deleteIsAuthorized = authorizationList?.filter(item => item.type === "FUNCTION" && item.id === authDeleteId);
  } else {
    uploadIsAuthorized = authUploadId;
    deleteIsAuthorized = authDeleteId;
  }

  const translate = useTranslate();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (files) {
      setFileList(files);
    }
  }, [files]);

  const handleOnChange = event => {
    const fileList = event.target.files;
    for (let i = 0; i < fileList.length; i++) {
      uploadFile(fileList[i]);
    }
  };

  const uploadFile = async file => {
    const data = new FormData();
    data.append("file", file);
    const response = await orderService.uploadFile(contentId, !noFitting ? params.fitting : "", data);

    if (response.isOk()) {
      if (setFetchFittings) {
        setFetchFittings(true);
      } else {
        setFileList(prevState => [...prevState, response.data?.files[0]]);
      }
    }
  };

  const onDelete = async fileId => {
    const response = await orderService.deleteFile(fileId, params.fitting, params.order, contentId);
    if (response.isOk()) {
      if (setFetchFittings) {
        setFetchFittings(true);
      } else {
        setFileList(prevState => prevState.filter(item => item.id !== fileId));
      }
    }
  };

  return (
    <section className={classNames(styles.fileUploadWrapper, customCssClass)}>
      {!isCompleted && uploadIsAuthorized && !!uploadIsAuthorized.length && (
        <>
          <label htmlFor={`fileupload-${contentId}`} className={styles.label}>
            <CircleAddFilled /> <span className={styles.text}>{translate("UPLOAD_DOCUMENT")}</span>
          </label>
          <input
            type="file"
            name="file"
            id={`fileupload-${contentId}`}
            multiple
            onChange={handleOnChange}
            onClick={e => (e.target.value = "")} //reset field so same file can be uploaded again
            className={styles.inputFile}
            accept={acceptedFilesList}
          />
        </>
      )}

      <ul className={styles.selectedList}>
        {fileList &&
          fileList.map(file => (
            <li key={file.id} className={styles.selected}>
              <FileDownload file={file} iconSize={iconSize} />
              {!isCompleted && deleteIsAuthorized && !!deleteIsAuthorized.length && (
                <IconButton onClick={() => onDelete(file.id)} customCssClass={styles.iconBtn}>
                  <Trash size={"20"} />
                </IconButton>
              )}
            </li>
          ))}
      </ul>
    </section>
  );
}
FileUpload.propTypes = {
  selectedFiles: PropTypes.array,
  acceptedFilesList: PropTypes.string,
  contentId: PropTypes.string,
  files: PropTypes.array,
  authUploadId: PropTypes.string,
  authDeleteId: PropTypes.string,
  customCssClass: PropTypes.string,
  setFetchFittings: PropTypes.func,
  iconSize: PropTypes.number,
  noFitting: PropTypes.bool,
  isCompleted: PropTypes.bool,
};

FileUpload.defaultProps = {
  acceptedFilesList:
    "image/*, .pdf, .xls, .xlsx, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
};

export default FileUpload;
