import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Image.module.scss";

const Image = ({ src, alt, customCssClass, isOrderable, infoMessage, ...props }) => (
  <figure className={classNames(styles.imgWrapper, customCssClass)} {...props}>
    <img src={src} alt={alt} className={styles.image} />
    {isOrderable === false && <div className={styles.notOrderable}>{infoMessage}</div>}
  </figure>
);

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  customCssClass: PropTypes.string,
  isOrderable: PropTypes.bool,
  infoMessage: PropTypes.string,
};

export default Image;
