import { Button, THEME } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { useState } from "react";
import { useTranslate } from "../../../language/i18n";
import Modal from "../../shared/Modals/Modal";
import styles from "./CartModals.module.scss";

function AddMessageModal({ visible, isLoading, handleAddMessage, onClose, currentMessage }) {
  const translate = useTranslate();
  const [message, setMessage] = useState(currentMessage);

  return (
    <Modal visible={visible} isLoading={isLoading} title={translate("ADD_CART_MESSAGE")} small onClose={onClose}>
      <p>{translate("ADD_CART_MESSAGE_TEXT")}</p>
      <textarea className={styles.cartMessageTextArea} value={message} onChange={e => setMessage(e.target.value)} />
      <footer className={styles.buttons}>
        <Button theme={THEME.SECONDARY} onClick={onClose}>
          {translate("CANCEL")}
        </Button>
        <Button onClick={() => handleAddMessage(message)}>{translate("SAVE")}</Button>
      </footer>
    </Modal>
  );
}

AddMessageModal.propTypes = {
  visible: PropType.bool,
  isLoading: PropType.bool,
  handleAddMessage: PropType.func.isRequired,
  onClose: PropType.func.isRequired,
  currentMessage: PropType.string,
};

export default AddMessageModal;
