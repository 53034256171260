import PropTypes from "prop-types";
import classNames from "classnames";
import { DownloadLink } from "../../Link";
import FileIcon from "../FileIcon/FileIcon";
import styles from "./FileDownload.module.scss";

export const FileDownload = ({ file, iconSize = 40, customCssClass }) => {
  return (
    <DownloadLink url={file.url} attribute={file.name} customCssClass={classNames(styles.link, customCssClass)}>
      <FileIcon size={iconSize} customCssClass={styles.checkIcon} fileName={file.name} />
      <div className={styles.title}>
        <p>{file.name}</p>
        {file.createdDateTime && <sub>{file.createdDateTime}</sub>}
        {file.createdBy && <sub className={styles.subCreatedBy}>{file.createdBy}</sub>}
      </div>
    </DownloadLink>
  );
};

const FileDownloadList = ({ files, customCssClass }) => {
  return (
    <ul className={customCssClass}>
      {files.map(file => (
        <li key={file.id}>
          <FileDownload file={file} />
        </li>
      ))}
    </ul>
  );
};

export default FileDownloadList;

FileDownload.propTypes = {
  file: PropTypes.shape({
    url: PropTypes.string.isRequired,
    name: PropTypes.string,
    createdBy: PropTypes.string,
    createdDateTime: PropTypes.string,
  }).isRequired,
  iconSize: PropTypes.number,
  customCssClass: PropTypes.string,
};

FileDownloadList.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      name: PropTypes.string,
    }).isRequired,
  ).isRequired,
  customCssClass: PropTypes.string,
};
