import PropTypes from "prop-types";
import styles from "./HULAddress.module.scss";
import { useTranslate } from "../../../../language/i18n";

function HULAddress({ address }) {
  const translate = useTranslate();
  return (
    <address className={styles.address}>
      {address?.description && <p>{address.description}</p>}
      {address?.careOf && (
        <p>
          {translate("CARE_OF")} {address.careOf}
        </p>
      )}
      {address?.street && <p>{address.street}</p>}
      <p>
        {address?.postalNo} {address.city}
      </p>
    </address>
  );
}

HULAddress.propTypes = {
  address: PropTypes.object,
};

export default HULAddress;
