import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Collapse.module.scss";

const Collapse = ({ children, visible, duration, easing, customCssClass }) => {
  const [targetHeight, setTargetHeight] = useState(0);
  const [shouldRender, setShouldRender] = useState(false);
  const ref = useRef();

  useEffect(() => {
    if (visible) {
      setShouldRender(true);
    }
  }, [visible]);

  const onTransitionEnd = () => {
    if (!visible) setShouldRender(false);
  };

  useEffect(() => {
    if (visible && shouldRender) {
      setTargetHeight(ref?.current?.scrollHeight);
    } else {
      setTargetHeight(0);
    }
  }, [visible, shouldRender, children]);

  /* 
  Calculation taken from MUI collapse component- getAutoHeightDuration 
  https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/styles/transitions.js
   */
  const getDuration = () => {
    const height = ref?.current?.scrollHeight;
    if (!height) {
      return 0;
    }
    const constant = height / 36;
    return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10);
  };

  const innerStyles = {
    maxHeight: targetHeight,
    transitionDuration: (duration === "auto" ? getDuration() : duration) + "ms",
    transitionTimingFunction: easing,
  };

  return (
    shouldRender && (
      <div
        className={classNames(styles.collapse, customCssClass)}
        style={innerStyles}
        ref={ref}
        onTransitionEnd={onTransitionEnd}
      >
        {children}
      </div>
    )
  );
};

Collapse.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.node,
  customCssClass: PropTypes.string,
  duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  easing: PropTypes.oneOf(["linear", "ease", "ease-in", "ease-out", "ease-in-out"]),
};

Collapse.defaultProps = {
  visible: false,
  duration: "auto",
  easing: "ease-in-out",
};

export default Collapse;
