import styles from "./FittingsPage.module.scss";
import PropType from "prop-types";
import classNames from "classnames";

const Label = ({ label, value, customCssClass }) => (
  <div className={classNames(styles.label, customCssClass)}>
    <span className={styles.title}>{label}:</span>
    <span className={styles.value}>{value}</span>
  </div>
);

Label.propTypes = {
  label: PropType.string,
  value: PropType.oneOfType([PropType.string, PropType.node]),
  customCssClass: PropType.string,
};

export default Label;
