import classNames from "classnames";
import PropType from "prop-types";
import styles from "./TabMenu.module.scss";

const TabTitle = ({ displayTitle, category, active, onChange }) => {
  return (
    <li onClick={e => onChange(e, category?.value)} className={classNames(styles.title, active && styles.active)}>
      {displayTitle ?? category?.label}
    </li>
  );
};

TabTitle.propTypes = {
  displayTitle: PropType.string,
  category: PropType.object,
  active: PropType.bool,
  onChange: PropType.func,
};

export default TabTitle;
