import PropTypes from "prop-types";
import { Checkbox } from "@myloc/myloc-gui";
import { useTranslate } from "../../../../language/i18n";
import { ORDER_CONTROL, DELIVERY_LOCATION } from "./productConstants";
import styles from "./OrderControl.module.scss";
import { useTablet } from "../../../../utils/viewport";
import { useSelector } from "react-redux";
import { CLIENT_TYPE } from "../../../../utils/constants";

const OrderControl = ({ orderControl, receiverLocation, checked, onToggle }) => {
  const isCompact = useTablet();
  const translate = useTranslate();
  const receiver = useSelector(state => state.appData.order?.receiver);
  const disabled =
    orderControl === ORDER_CONTROL.INSTALLATION_MANDATORY.id || orderControl === ORDER_CONTROL.ASSEMBLY_MANDATORY.id;

  const show =
    receiver?.type !== CLIENT_TYPE.BASE_STORAGE &&
    receiverLocation !== DELIVERY_LOCATION.BASE_STORAGE.id &&
    !(!orderControl || orderControl === ORDER_CONTROL.NONE.id);

  return !show ? (
    <>{!isCompact && <div className={styles.checkboxReplacement} />}</>
  ) : (
    <Checkbox
      label={translate(Object.values(ORDER_CONTROL).find(current => current.id === orderControl)?.value)}
      disabled={disabled}
      checked={checked}
      onChange={onToggle}
    />
  );
};

OrderControl.propTypes = {
  orderControl: PropTypes.string,
  receiverLocation: PropTypes.string,
  checked: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default OrderControl;
