import PropType from "prop-types";
import { withPage } from "@myloc/myloc-utils";
import { useTranslate } from "../../../language/i18n";
import WizardPage, { getActiveStep } from "../../shared/WizardPage/WizardPage";
import AdditionalInfo from "../../shared/AdditionalInfo/AdditionalInfo";
import AddressAndContact from "../../shared/AddressAndContact/AddressAndContact";
import Product from "./Product";
import pages from "../../../utils/pages";
import { useParams } from "react-router";
import useOrderWizard from "../../../hooks/useOrderWizard";
import OrderOverview from "../../shared/OrderOverview/OrderOverview";
import OrderInfo from "./OrderInfo";

const ExternalAssortmentPage = ({ currentActiveStep }) => {
  const queryParams = useParams();
  const translate = useTranslate();
  const { data, setData, stepper, handleNext } = useOrderWizard(pages.PRODUCTS_EXTERNAL_ASSORTMENT.PATH, {
    contentType: "NEW_PRODUCT",
    product: { quantity: 1 },
  });

  const steps = [
    {
      title: translate("ADD_PRODUCT"),
      titleShort: translate("PRODUCT"),
      children: <Product data={data} setData={setData} onNext={handleNext} />,
    },
    {
      title: translate("ADD_ADDITIONAL_INFO"),
      titleShort: translate("ADDITIONAL_INFO_SHORT"),
      children: <AdditionalInfo onNext={handleNext} />,
    },
    {
      title: translate("ADD_DELIVERY_ADDRESS_AND_CONTACT"),
      titleShort: translate("ADDRESS"),
      children: <AddressAndContact onNext={handleNext} />,
    },
    {
      title: translate("ORDER_OVERVIEW"),
      titleShort: translate("ORDER_OVERVIEW_SHORT"),
      children: (
        <OrderOverview>
          <OrderInfo />
        </OrderOverview>
      ),
    },
  ];
  return (
    <WizardPage
      title={translate("EXTERNAL_ASSORTMENT_TITLE")}
      steps={steps}
      activeStep={getActiveStep(queryParams.content, currentActiveStep, data.currentStep)}
      setActiveStep={step => stepper.setActiveStep(step)}
    />
  );
};

ExternalAssortmentPage.propTypes = {
  search: PropType.string,
  id: PropType.string,
  currentStep: PropType.number,
  currentActiveStep: PropType.number,
};

export default withPage(ExternalAssortmentPage);
