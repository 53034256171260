import { Method, Request, Response as ResponseUtil, sendRequest } from "@myloc/myloc-utils";

import { api } from "../../config/settings";
import defaultRestOptions from "../utils/defaultRestOptions";

async function getUserData() {
  const customErrorHandler = exception => {
    return new ResponseUtil(exception?.response?.statusCode, "", exception);
  };
  const url = api.singleSignOn.getUserInfo();
  const request = new Request(url, Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions(customErrorHandler));
}

export default { getUserData };
