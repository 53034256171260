import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { api } from "../../config/settings";
import defaultRestOptions from "../utils/defaultRestOptions";

async function getSessionData() {
  const request = new Request(api.account.authentication(), Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions());
}

export default { getSessionData };
