import storage from "../../utils/store/storage";
import {
  LOGIN_TOKEN as STORE_LOGIN_TOKEN,
  REMEMBER_ME as STORE_REMEMBER_ME,
  SESSION_ID as STORE_SESSION_ID,
} from "../../utils/store/storeVariables";
import store from "../rootReducer";

import {
  AUTHORIZATION_LIST,
  INITIALIZED,
  IS_SINGLE_SIGN_ON,
  LOGIN_TOKEN,
  REMEMBER_ME,
  SESSION_ID,
  SINGLE_SIGN_OUT,
  SSO_SESSION_DATA,
  USER_HAS_MESSAGE_TO_ACCEPT,
} from "./sessionVariables";

// Action creators
export function setSessionId(sessionId, rememberMe) {
  saveItem(STORE_SESSION_ID, sessionId, rememberMe);
  store.dispatch({ type: SESSION_ID, sessionId });
}

export function setAuthorizationList(authorizationList) {
  store.dispatch({ type: AUTHORIZATION_LIST, authorizationList });
}

export function setRememberMe(rememberMe) {
  saveItem(STORE_REMEMBER_ME, rememberMe, rememberMe);
  return { type: REMEMBER_ME, rememberMe };
}

export function setLoginToken(loginToken, rememberMe) {
  saveItem(STORE_LOGIN_TOKEN, loginToken, rememberMe);
  store.dispatch({ type: LOGIN_TOKEN, loginToken });
}

export function resetSession() {
  let state = store.getState();
  const rememberMe = state.session.rememberMe;
  storage.saveItem(STORE_SESSION_ID, null, rememberMe);
  const sessionId = null;
  return setSessionId(sessionId, false);
}

export function setInitialized(initialized = true) {
  store.dispatch({ type: INITIALIZED, initialized });
}

export function setUserHasMessageToAccept(message) {
  store.dispatch({ type: USER_HAS_MESSAGE_TO_ACCEPT, message });
}

function saveItem(variableId, value, rememberMe) {
  storage.saveItem(variableId, value, rememberMe);
}

export function setSSOSessionData(ssoSessionData) {
  return {
    type: SSO_SESSION_DATA,
    sessionData: ssoSessionData,
  };
}
export function setIsSingleSignOn(isSingleSignOn) {
  return {
    type: IS_SINGLE_SIGN_ON,
    isSingleSignOn: isSingleSignOn,
  };
}
export function setSingleSignOut() {
  return {
    type: SINGLE_SIGN_OUT,
    singleSignOut: true,
  };
}
