import { Button, InputField } from "@myloc/myloc-gui";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import useDebounce from "../../../../hooks/useDebounce";
import { useTranslate } from "../../../../language/i18n";
import productService from "../../../../services/product/productService";
import Label from "../../Label/Label";
import Paginate from "../../Pagination/Paginate";
import styles from "./ChooseSpecificAccessories.module.scss";

const ChooseSpecificAccessories = ({ onSelect }) => {
  const translate = useTranslate();
  const [specificAccessories, setSpecificAccessories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState();
  const [searchValue, setSearchValue] = useState();

  const debouncedSearchValue = useDebounce(search);

  return (
    <div>
      <div className={styles.searchField}>
        <InputField customCssClass={styles.input} onChange={e => setSearch(e.target.value)} />
        <Button
          isLoading={isLoading}
          customCssClass={styles.button}
          onClick={() => setSearchValue(debouncedSearchValue)}
        >
          {translate("SEARCH")}
        </Button>
      </div>
      <Paginate
        loadOnMount={false}
        errorMessage={{ show: searchValue != null ? true : false }}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        onChange={setSpecificAccessories}
        hidePageSize
        provider={useCallback(
          filter =>
            productService.getProducts({
              searchValue,
              sort: "description:asc",
              ...filter,
            }),

          [searchValue],
        )}
      >
        {specificAccessories &&
          specificAccessories.map(accessory => (
            <div key={accessory.id} className={styles.row}>
              <div>
                <p>{accessory.description} </p>
                <Label label={translate("PRODUCT_NUMBER")} value={accessory.productNumber} inline />
              </div>
              <div className={styles.button}>
                <Button onClick={() => onSelect(accessory)}>{translate("CHOOSE")}</Button>
              </div>
            </div>
          ))}
      </Paginate>
    </div>
  );
};

ChooseSpecificAccessories.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default ChooseSpecificAccessories;
