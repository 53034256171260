import PropType from "prop-types";
import ProductImage, { ImagePreset } from "../ProductImage/ProductImage";
import styles from "./ProductCard.module.scss";

function ProductCard({ product: { productNumber, description, images, isOrderable } }) {
  return (
    <article className={styles.productCard}>
      <ProductImage images={images} preset={ImagePreset.SMALL} isOrderable={isOrderable} showLabel={!isOrderable} />
      <h3 className={styles.title}>{description}</h3>
      <p className={styles.number}>{productNumber}</p>
    </article>
  );
}
ProductCard.propTypes = {
  product: PropType.shape({
    description: PropType.string,
    images: PropType.arrayOf(
      PropType.shape({
        name: PropType.string,
        url: PropType.string,
        id: PropType.string,
      }),
    ),
    productNumber: PropType.string,
    quantityDecimals: PropType.number,
    quantity: PropType.number,
    id: PropType.string,
    isOrderable: PropType.bool,
  }),
};

export default ProductCard;
