import { InputField } from "@myloc/myloc-gui";
import { useHistory } from "@myloc/myloc-utils";
import classNames from "classnames";
import PropType from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslate } from "../../../language/i18n";
import clientService from "../../../services/client/clientService";
import orderService from "../../../services/order/orderService";
import Page from "../../shared/Page/Page";
import Paginate from "../../shared/Pagination/Paginate";
import RadioButtonGroup from "../../shared/RadioButton/RadioButtonGroup";
import SelectField from "../../shared/SelectField/SelectField";
import TopFilter from "../../shared/TopFilter/TopFilter";

import { CLIENT_TYPE } from "../../../utils/constants";
import pages from "../../../utils/pages";
import { useTablet } from "../../../utils/viewport";
import Label from "../../shared/Label/Label";
import LoadingSpinner from "../../shared/Spinner/LoadingSpinner";
import styles from "./Orders.module.scss";

const FILTERS = ["clients", "facilities", "fromDate", "toDate"];
const DEFAULT_FILTER = "OPEN";

const Orders = ({ setBreadcrumbs, fromClient }) => {
  const history = useHistory();
  const translate = useTranslate();
  const isTablet = useTablet();
  const params = new URLSearchParams(window.location.search);
  const [paramsExist, setParamsExist] = useState(false);

  const defaultFilterValues = () =>
    FILTERS.reduce((c, key) => {
      return { ...c, [key]: "" };
    }, {});

  const getSelectedFiltersFromParams = params => {
    return FILTERS.reduce(
      (choice, key) => ({
        ...choice,
        [key]: params.get(key) || "",
      }),
      {},
    );
  };

  const [orders, setOrders] = useState(null);
  const [status, setStatus] = useState(params.get("status") || DEFAULT_FILTER);
  const [isLoading, setLoading] = useState(true);
  const [availableFilters, setAvailableFilters] = useState(params, defaultFilterValues());
  const [selectedFilters, setSelectedFilters] = useState(getSelectedFiltersFromParams(params, defaultFilterValues()));
  const [filters, setFilters] = useState({ ...selectedFilters });
  const [freeTextSearch, setFreeTextSearch] = useState(params.get("q") || "");
  const isClientOrders = Boolean(location.pathname.match(/^\/clients/));

  const filterStates = [
    { id: "OPEN", value: translate("OPEN") },
    { id: "CLOSED", value: translate("CLOSED") },
    { id: "ALL", value: translate("ALL") },
  ];

  useEffect(() => {
    orderService
      .getFiltersForOrders({ clients: true, facilities: true })
      .then(response => setAvailableFilters({ ...response.data }));
  }, []);

  const filterSearch = () => {
    setSelectedFilters({
      clientId: filters?.clients || "",
      facilityId: decodeURI(filters?.facilities) || "",
      fromOrderDate: filters?.fromDate || "",
      toOrderDate: filters?.toDate || "",
    });
    setFreeTextSearch(params.get("q") || "");
  };

  const onClickSearch = () => {
    filterSearch();
  };

  const handleStatusChange = async selected => {
    setHistory(selected);
    setStatus(selected.id);
  };

  const setHistory = selected => {
    const params = new URLSearchParams(window.location.search);
    params.set("status", selected.id);
    history.replace(`${window.location.pathname}${window.location.search}`, params);
  };

  const onFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const onSelectFilter = (option, key) => {
    if (!option) {
      setFilters(prev => ({ ...prev, [key]: "" }));
    } else {
      setFilters(prev => {
        return { ...prev, [key]: option.id };
      });
    }
  };

  useEffect(() => {
    if (paramsExist) {
      filterSearch();
      setParamsExist(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsExist]);

  useEffect(() => {
    FILTERS.forEach(filter => {
      if (params.get(filter)) {
        setParamsExist(true);
      }
    });
    if (params.get("q") != null) {
      filterSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBreadcrumbs([{ text: `${translate(pages.MY_MYLOC.NAME)} - ${translate(pages.MY_ORDERS.NAME)}` }]);
  }, [setBreadcrumbs, translate]);

  const getStatus = useCallback(() => {
    switch (status) {
      case "OPEN":
        return "20:>=;80:<=";
      case "CLOSED":
        return "90:>=";
      default:
        return "20:>=";
    }
  }, [status]);

  const getProvider = useCallback(
    filter => {
      if (!isClientOrders) {
        return orderService.getOrders({
          status: getStatus(),
          sort: "orderDate:desc",
          ...selectedFilters,
          ...filter,
          freeTextSearch,
        });
      } else {
        if (fromClient) {
          return clientService.getOrders(fromClient, {
            status: getStatus(),
            sort: "orderDate:desc",
            ...filter,
          });
        }
      }
    },
    [freeTextSearch, selectedFilters, fromClient, isClientOrders, getStatus],
  );

  return (
    <Page customCssClass={styles.page} title={!fromClient && translate(pages.ORDERS.NAME)}>
      <Paginate
        setIsLoading={setLoading}
        isLoading={isLoading}
        onChange={setOrders}
        provider={getProvider}
        skipSearch={isClientOrders && !fromClient}
      >
        <section>
          {!fromClient && (
            <TopFilter
              onSubmit={onClickSearch}
              filters={filters}
              setFilters={setFilters}
              fields={FILTERS}
              label={translate("SEARCH_FOR_ORDER")}
            >
              <>
                <div className={styles.searchGroup}>
                  <InputField
                    type="date"
                    label={translate("FROM_DATE")}
                    labelAlwaysTop
                    value={filters?.fromDate}
                    onChange={option => onFilterChange("fromDate", option?.target?.value)}
                  />
                  <InputField
                    type="date"
                    label={translate("TO_DATE")}
                    labelAlwaysTop
                    value={filters?.toDate}
                    onChange={option => onFilterChange("toDate", option?.target?.value)}
                  />
                </div>

                <div className={styles.searchGroup}>
                  {availableFilters?.clients?.length && (
                    <SelectField
                      label={translate("USER")}
                      options={availableFilters?.clients}
                      selectedId={filters?.clients}
                      onSelect={option => onSelectFilter(option, "clients")}
                      prepopulate={false}
                      customSettings={{ display: "label" }}
                    />
                  )}
                  {availableFilters?.facilities?.length && (
                    <SelectField
                      label={translate("BASE_STORAGE")}
                      options={availableFilters?.facilities?.map(current => {
                        return {
                          id: current.id,
                          value: `${current.value} - ${current.label}`,
                        };
                      })}
                      selectedId={filters.facilities}
                      onSelect={option => onSelectFilter(option, "facilities")}
                      prepopulate={false}
                    />
                  )}
                </div>
              </>
            </TopFilter>
          )}
          <section>
            <RadioButtonGroup
              values={filterStates}
              onChange={handleStatusChange}
              selected={status}
              customCssClass={styles.filter}
            />
          </section>

          <ul className={styles.wrapper}>
            {isLoading ? (
              <LoadingSpinner title="ORDERS_LOADING" />
            ) : (
              !!orders?.length &&
              orders?.map(order => {
                return (
                  <li className={styles.card} key={order.id}>
                    <div className={styles.header}>
                      <div className={styles.left}>
                        <div className={styles.bold}>
                          <Link
                            to={{
                              pathname: `${pages.ORDERS.PATH}/${order.id}`,
                              state: {
                                id: order.id,
                                referrer: `${window.location.pathname}${window.location.search}`,
                              },
                            }}
                          >
                            {`${translate("ORDER")} ${order.orderNumber}`}
                          </Link>
                        </div>
                        <Label label={translate("ORDERED")} value={order.orderDate} inline />
                      </div>
                    </div>
                    <div>
                      <p className={styles.text}>
                        <span className={styles.label}>
                          {fromClient ? translate("ORDERED_BY") : translate("RECEIVER")}:
                        </span>
                        <span className={styles.inline}>
                          <p className={styles.value}>
                            {order.receiver?.type === CLIENT_TYPE.USER
                              ? fromClient
                                ? `${order.responsible?.firstName} ${order.responsible?.surname}`
                                : `${order.receiver?.firstName} ${order.receiver?.surname}`
                              : ` ${order.receiver?.value} - ${order?.receiver?.label}`}
                          </p>
                          <p className={styles.value}>
                            {fromClient ? order.responsible?.email : order.receiver?.identityNumber}
                          </p>
                        </span>
                      </p>
                    </div>
                    <div>
                      <span
                        className={classNames(styles.status, styles[`status${order.status}`], isTablet && styles.left)}
                      >
                        {translate(`ORDER_STATUS_${order.status}`)}
                      </span>
                    </div>
                  </li>
                );
              })
            )}
          </ul>
        </section>
      </Paginate>
    </Page>
  );
};

Orders.propTypes = {
  setBreadcrumbs: PropType.func,
  fromClient: PropType.string,
};

export default Orders;
