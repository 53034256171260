import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setSessionId, setSSOSessionData } from "../reducers/session/sessionAction";
import sessionDataService from "../services/sessionData/sessionDataService";
const useFetchSessionData = () => {
  const dispatch = useDispatch();

  const loadSessionData = useCallback(async () => {
    const response = await sessionDataService.getSessionData();
    let result = {};

    if ("id" in response?.data) {
      result = { isError: false, sessionId: response.data.id };
      setSessionId(response?.data?.id);
      dispatch(setSSOSessionData(response));
    } else result = { isError: true };
    return result;
  }, [dispatch]);

  return loadSessionData;
};

export default useFetchSessionData;
