import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslate } from "../../language/i18n";

import Reference from "./Reference/Reference";
import Description from "./Description/Description";
import WizardPage from "../shared/WizardPage/WizardPage";
import DeviationType from "./DeviationType/DeviationType";
import deviationService from "../../services/deviation/deviationService";
import { setMessage } from "../../reducers/dialog/dialogAction";

const Deviation = () => {
  const translate = useTranslate();
  const history = useHistory();

  const [activeIndex, setActiveIndex] = useState(0);
  const [form, setForm] = useState({});
  const [data, setData] = useState({});

  const handleNext = (data, step) => {
    setForm({ ...form, ...data });
    setActiveIndex(step);
  };

  const submitHandler = async data => {
    const body = {
      categoryId: form.category.id,
      typeId: form.type.id,
      description: data.description,
      reference: {
        id: form.reference.id === "0" ? null : form.reference.id,
        value: form.reference.id === "0" ? "" : form.code,
      },
    };

    deviationService.createDeviation(body).then(response => {
      if (data?.files?.length) {
        data.files.forEach(file => {
          const formData = new FormData();
          formData.append("file1", file);

          deviationService.uploadFile({ deviationId: response.data.id, data: formData });
          return;
        });
      }

      if (response.isOk()) {
        setMessage(translate("DEVIATION_SUCCESS"));
        history.push("/");
      }
    });
  };

  const steps = [
    {
      title: translate("CHOOSE_DEVIATION_TYPE"),
      titleShort: translate("DEVIATION_TYPE"),
      children: (
        <DeviationType
          data={data}
          setData={item => setData({ ...data, ...item })}
          onNext={data => handleNext(data, 1)}
        />
      ),
    },
    {
      title: translate("CHOOSE_REFERENCE"),
      titleShort: translate("REFERENCE"),
      children: form.category && (
        <Reference
          data={data}
          setData={reference => setData({ ...data, ...reference })}
          category={form.category}
          onNext={data => handleNext(data, 2)}
        />
      ),
    },
    {
      title: translate("DEVIATION_DESCRIPTION"),
      titleShort: translate("DESCRIPTION"),
      children: (
        <Description
          data={data}
          setData={description => setData({ ...data, ...description })}
          onNext={data => submitHandler(data)}
        />
      ),
    },
  ];

  return (
    <WizardPage
      title={translate("DEVIATION_TITLE")}
      steps={steps}
      activeStep={activeIndex}
      setActiveStep={setActiveIndex}
      showUserInfo={false}
      requiresOrder={false}
    />
  );
};

export default Deviation;
