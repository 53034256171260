import PropType from "prop-types";
import { useEffect } from "react";
import { useTranslate } from "../../../language/i18n";
import pages from "../../../utils/pages";
import ClientHoldingList from "../../shared/ClientHoldingList/ClientHoldingList";
import styles from "./Holding.module.scss";

function HoldingHistory({ setBreadcrumbs, receiver, referrer, fromReceiverMenu }) {
  const translate = useTranslate();
  const clientId = receiver?.id;

  useEffect(() => {
    if (fromReceiverMenu) {
      setBreadcrumbs([
        { text: `${translate(pages.CLIENTS.NAME)} - ${translate(pages.CLIENT_HOLDING_TRANSACTIONS.NAME)} ` },
      ]);
    } else {
      setBreadcrumbs([
        { text: translate("SEARCH_CLIENT"), link: referrer || pages.CLIENTS.PATH },
        { text: `${translate(pages.CLIENTS.NAME)} - ${translate(pages.CLIENT_HOLDING_TRANSACTIONS.NAME)}` },
      ]);
    }
  }, [referrer, setBreadcrumbs, translate, fromReceiverMenu]);
  return (
    <>
      <div>
        <h3 className={styles.contentTitle}>{translate("HISTORICAL_HOLDING_LIST")}</h3>
        {clientId && (
          <ClientHoldingList
            isHistory
            amountChangeable={false}
            readOnly
            clientId={clientId}
            referrer={referrer}
            isReceiverMenu={fromReceiverMenu}
          />
        )}
      </div>
    </>
  );
}

HoldingHistory.propTypes = {
  receiver: PropType.object,
  productList: PropType.array,
  setBreadcrumbs: PropType.func,
  referrer: PropType.string,
  fromReceiverMenu: PropType.bool,
};

export default HoldingHistory;
