// import { InputField } from "@myloc/myloc-gui";
import { dialogVariables } from "@myloc/myloc-utils";
import { useCallback, useEffect, useState } from "react";

import { useTranslate } from "../../language/i18n";
import clientService from "../../services/client/clientService";
import pages from "../../utils/pages";

import { Link } from "react-router-dom";
import { setMessage } from "../../reducers/dialog/dialogAction";
import Label from "../shared/Label/Label";
import Page from "../shared/Page/Page";
import Paginate from "../shared/Pagination/Paginate";
import LoadingSpinner from "../shared/Spinner/LoadingSpinner";
import TopFilter from "../shared/TopFilter/TopFilter";
import styles from "./ClientSearch.module.scss";

const FILTERS = ["firstName", "surname", "zipCode", "city"];
const defaultChoices = () => FILTERS.reduce((c, key) => ({ ...c, [key]: "" }), {});

const getClientChoicesFromParams = params => {
  return FILTERS.reduce(
    (choice, key) => ({
      ...choice,
      [key]: params.get(key) || "",
    }),
    {},
  );
};

const ClientSearchPage = () => {
  const translate = useTranslate();
  const params = new URLSearchParams(window.location.search);

  const showFilters = false;
  const [choices, setChoices] = useState(showFilters ? getClientChoicesFromParams(params) : defaultChoices());
  const [clients, setClients] = useState([]);
  const [filters, setFilters] = useState({ ...choices });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ show: false, message: "" });
  const [paramsExist, setParamsExist] = useState(false);
  const freeTextSearch = params.get("q");

  const filterSearch = () => {
    setFilters({ ...choices });
  };

  // const onFilterChange = (key, value) => {
  //   setChoices({ ...choices, [key]: value });
  // };

  const getProvider = useCallback(
    filter => {
      // if (!choices.city && !choices.firstName && freeTextSearch == null && !choices.surname && !choices.zipCode) {
      if (freeTextSearch == null) {
        const message = { show: true, message: "INVALID_SEARCH_QUERY" };
        setErrorMessage(message);
        return message;
      } else {
        setErrorMessage({ show: true, message: "" });
        return clientService.getClients({ freeTextSearch, ...filters, ...filter });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, freeTextSearch],
  );

  useEffect(() => {
    if (paramsExist) {
      filterSearch();
      setParamsExist(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsExist]);

  useEffect(() => {
    FILTERS.forEach(filter => {
      if (params.get(filter)) {
        setParamsExist(true);
      }
    });
    if (params.get("q") != null) {
      filterSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title={translate("SEARCH_CLIENT")} customCssClass={styles.page}>
      <Paginate
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        onChange={setClients}
        provider={useCallback(filter => getProvider(filter), [getProvider])}
        loadOnMount={false}
        errorMessage={errorMessage}
        skipSearch={freeTextSearch == null}
        // skipSearch={
        //   !choices.city && !choices.firstName &&  && !choices.surname && !choices.zipCode
        // }
      >
        <TopFilter
          onSubmit={filterSearch}
          filters={choices}
          setFilters={setChoices}
          fields={FILTERS}
          label={translate("SEARCH_FOR_CLIENT")}
          showFilterButton={false}
        >
          {/* <>
            <div className={styles.searchGroup}>
              <InputField
                type="input"
                label={translate("FIRST_NAME")}
                labelAlwaysTop
                value={choices?.firstName}
                onChange={option => onFilterChange("firstName", option?.target?.value)}
              />
              <InputField
                type="input"
                label={translate("LAST_NAME")}
                labelAlwaysTop
                value={choices?.surname}
                onChange={option => onFilterChange("surname", option?.target?.value)}
              />
            </div>
            <div className={styles.searchGroup}>
              <InputField
                type="input"
                label={translate("POSTAL_NO")}
                labelAlwaysTop
                value={choices?.zipCode}
                onChange={option => onFilterChange("zipCode", option?.target?.value)}
              />
              <InputField
                type="input"
                label={translate("CITY")}
                labelAlwaysTop
                value={choices?.city}
                onChange={option => onFilterChange("city", option?.target?.value)}
              />
            </div>
          </> */}
        </TopFilter>
        {isLoading ? (
          <LoadingSpinner title="CLIENTS_LOADING" />
        ) : clients && clients.length ? (
          <section className={styles.results}>
            {clients?.map(client => {
              return (
                <div className={styles.row} key={client.id}>
                  <div className={styles.column}>
                    <div>
                      {client.hasAuthorizationForMunicipal ? (
                        <Link
                          to={{
                            pathname: `${pages.CLIENTS.PATH}/${client.id}`,
                            state: { referrer: `${window.location.pathname}${window.location.search}` },
                          }}
                        >{`${client.firstName} ${client.surname}`}</Link>
                      ) : (
                        <p
                          className={styles.link}
                          onClick={() =>
                            setMessage(
                              translate("NOT_AUTHORIZED_TO_MUNICIPALITY"),
                              dialogVariables.ACTION.MESSAGE_ERROR,
                            )
                          }
                        >{`${client.firstName} ${client.surname}`}</p>
                      )}
                    </div>
                    <div>{client.identityNumber}</div>
                  </div>
                  <div className={styles.column}>
                    <div>{client?.defaultAddress?.street}</div>
                    <div>{`${client?.defaultAddress?.postalNo} ${client?.defaultAddress?.city}`}</div>
                  </div>
                  <div className={styles.column}>
                    <div>{client?.contactInformation?.phoneNo}</div>
                    <div>{client?.contactInformation?.email}</div>
                  </div>
                  {client?.municipalAffiliation?.name && (
                    <div className={styles.column}>
                      <Label label={translate("MUNICIPALITY_AFFILIATION")} value={client?.municipalAffiliation?.name} />
                    </div>
                  )}
                </div>
              );
            })}
          </section>
        ) : (
          ""
        )}
      </Paginate>
    </Page>
  );
};

export default ClientSearchPage;
