import { Button, IconButton, THEME } from "@myloc/myloc-gui";
import { withPage } from "@myloc/myloc-utils";
import PropType from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Grid, List } from "../../../assets/icons";
import { useTranslate } from "../../../language/i18n";
import accountService from "../../../services/account/accountService";
import pages from "../../../utils/pages";
import Label from "../../shared/Label/Label";
import LoadingSpinner from "../../shared/Spinner/LoadingSpinner";
import ProductCard from "../ProductCard/ProductCard";
import { VIEWS } from "../ProductsPage";
import ProductCatalogueAddToCart from "./Components/ProductCatalogueAddToCart";
import styles from "./ProductCatalogue.module.scss";

const VIEW_SETTING_ID = "FSP_productCatalogueView";

const PARAMS = {
  VIEW: "view",
};
const ProductCatalogue = ({
  data,
  showFilter,
  productFilters,
  setProductFilters,
  setSearchValue,
  searchValue,
  isLoading,
  setParams,
}) => {
  const translate = useTranslate();
  const isEmbedded = Boolean(window.location.pathname.match(/^\/embedded\//));
  const savedSettingValue = useRef();

  const setActive = useCallback(
    async view => {
      const currentView = setParams(PARAMS.VIEW, view);

      if (currentView == null) return;
      setActiveView(currentView);
      if (savedSettingValue.current === currentView) return;
      const response = await accountService.setUserSetting(VIEW_SETTING_ID, { newValue: currentView });
      if (!response.isOk()) return;
      if (!response.data) return;
      savedSettingValue.current = response.data.value;
    },

    [setParams],
  );
  const [activeView, setActiveView] = useState();

  const clear = filterCategory => {
    let _filters = { ...productFilters };
    if (filterCategory === "includeNotOrderable") {
      _filters[filterCategory].include = false;
    }

    _filters[filterCategory].id = [];

    setProductFilters(_filters);
  };

  useEffect(() => {
    if (savedSettingValue.current != null) return;
    const getUserSetting = async () => {
      savedSettingValue.current = "";
      const response = await accountService.getUserSetting(VIEW_SETTING_ID, () => {});
      if (response.isOk()) {
        if (response.data) {
          savedSettingValue.current = response.data.value;
          setActive(response.data.value);
        }
      }
    };
    getUserSetting();
  }, [setActive]);

  const activeFilters = () => {
    if (!productFilters) {
      return [];
    }

    const filters = Object.keys(productFilters).filter(
      category => !!productFilters[category].id?.length || productFilters[category].include,
    );

    return filters.map(filterCategory => (
      <span className={styles.badge} key={filterCategory}>
        {translate(productFilters[filterCategory].title)} ({productFilters[filterCategory].id.length || "1"})
        <button type="button" onClick={() => clear(filterCategory)}>
          ✕
        </button>
      </span>
    ));
  };

  return (
    <>
      <div className={styles.row}>
        <input
          className={styles.input}
          placeholder={translate("SEARCH_FOR_PRODUCT_NUMBER_NAME_OR_SUPPLIER_ITEM_NUMBER")}
          value={searchValue}
          onChange={event => setSearchValue(event.target.value)}
        />
        <Button theme={THEME.SECONDARY} onClick={() => showFilter(true)}>
          {translate("FILTER")}
        </Button>
      </div>
      <section className={styles.toolBar}>
        <div className={styles.activeFilters}>{activeFilters()}</div>
      </section>
      <div className={styles.views}>
        <IconButton onClick={() => setActive(VIEWS.GRID)} customCssClass={activeView == VIEWS.GRID && styles.active}>
          <Grid />
        </IconButton>
        <IconButton onClick={() => setActive(VIEWS.LIST)} customCssClass={activeView == VIEWS.LIST && styles.active}>
          <List size={42} />
        </IconButton>
      </div>
      {isLoading ? (
        <LoadingSpinner title="PRODUCTS_LOADING" />
      ) : activeView == VIEWS.GRID ? (
        <ul className={styles.productListGrid}>
          {!!data?.length &&
            data?.map(product => (
              <li key={product.id} className={styles.listItem}>
                <Link
                  to={{
                    pathname: isEmbedded
                      ? `${pages.EMBEDDED_PRODUCTS.PATH}/${encodeURIComponent(product.id)}`
                      : `${pages.PRODUCTS.PATH}/${encodeURIComponent(product.id)}`,
                    state: { product, referrer: `${window.location.pathname}${window.location.search}` },
                  }}
                  className={styles.productLink}
                >
                  <ProductCard product={product} />
                </Link>
              </li>
            ))}
        </ul>
      ) : (
        <>
          {!!data?.length &&
            data?.map(product => (
              <div key={product.id} className={styles.productList}>
                <div className={styles.upperRow}>
                  <p>{product.description}</p>
                  <Label
                    label={translate("AVAILABLE_BALANCE")}
                    value={`${product.defaultWarehouseBalance} ${product.unit}`}
                    inline
                  />
                </div>
                <div className={styles.lowerRow}>
                  <div className={styles.group}>
                    <Link
                      to={{
                        pathname: isEmbedded
                          ? `${pages.EMBEDDED_PRODUCTS.PATH}/${encodeURIComponent(product.id)}`
                          : `${pages.PRODUCTS.PATH}/${encodeURIComponent(product.id)}`,
                        state: { product, referrer: `${window.location.pathname}${window.location.search}` },
                      }}
                      className={styles.productLink}
                    >
                      <Label label={translate("PRODUCT_NUMBER_SHORT")} value={product.productNumber} inline />
                    </Link>
                    <p className={styles.greyText}>{`(${product.replenishmentGroup?.label})`}</p>
                  </div>
                  <Label label={translate("SUPPLIER_ITEM_NUMBER")} value={product.mainSupplierItemNumber} inline />
                  <Label label={translate("SUPPLIER")} value={product.supplier?.label} inline />
                  <p className={styles.greyText}>{`(${product.defaultWarehouseName})`}</p>
                </div>
                <ProductCatalogueAddToCart product={product} />
              </div>
            ))}
        </>
      )}
    </>
  );
};

ProductCatalogue.propTypes = {
  productFilters: PropType.object,
  setProductFilters: PropType.func,
  showFilter: PropType.func,
  from: PropType.number,
  amount: PropType.number,
  count: PropType.number,
  search: PropType.string,
  searchValue: PropType.string,
  setSearchValue: PropType.func,
  data: PropType.arrayOf(
    PropType.shape({
      name: PropType.string,
      images: PropType.array,
      productNumber: PropType.string,
      quantityDecimals: PropType.number,
      quantity: PropType.number,
      id: PropType.string,
    }),
  ),
  isLoading: PropType.bool,
  setParams: PropType.func,
};

export default withPage(ProductCatalogue);
