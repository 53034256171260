import PropType from "prop-types";
import { useTranslate } from "../../../language/i18n";

import styles from "./PageSize.module.scss";
import SelectField from "../SelectField/SelectField";

export const pageSize = { SMALL: 24, MEDIUM: 52, LARGE: 100 };
const perPage = [
  {
    id: pageSize.SMALL,
    value: pageSize.SMALL,
  },
  {
    id: pageSize.MEDIUM,
    value: pageSize.MEDIUM,
  },
  {
    id: pageSize.LARGE,
    value: pageSize.LARGE,
  },
];

function PageSize({ onChange, size, customCssClass }) {
  const translate = useTranslate();
  return (
    <section className={customCssClass}>
      <p className={styles.text}>{translate("SHOW_PAGE_SIZE")}</p>
      <SelectField
        required
        customSettings={{ autocomplete: false }}
        options={perPage}
        selectedId={size || pageSize.SMALL}
        onSelect={item => onChange(item.value)}
        customCssClass={styles.select}
      />
    </section>
  );
}

PageSize.propTypes = {
  onChange: PropType.func.isRequired,
  size: PropType.number.isRequired,
  customCssClass: PropType.string,
};

export default PageSize;
