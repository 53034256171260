import PropType from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useOrderWizard from "../../../../hooks/useOrderWizard";
import { useTranslate } from "../../../../language/i18n";
import { getFullDate } from "../../../../utils/DateUtil";
import { CLIENT_TYPE, CONTENT_STATUS, CONTENT_TYPE } from "../../../../utils/constants";
import pages from "../../../../utils/pages";
import AddressAndContactModal from "../../../Cart/CartModals/AddressAndContactModal";
import { ORDER_CONTROL, isOrderControlMandatory } from "../../../Products/ProductDetail/Components/productConstants.js";
import Checkbox from "../../Checkbox/Checkbox";
import Counter from "../../Counter/Counter";
import Price from "../../Price/Price";
import { ContentType, Item, ItemInfo, Row, RowTitle } from "../ListComponents/ListComponents";
import JointDeliveryCheckbox from "./JointDelivery/JointDeliveryCheckbox";
import styles from "./Types.module.scss";

function ProductDelivery({
  list,
  onDelete,
  onQuantityChange,
  onAssemblyInstallationChange,
  onUpdateContent,
  readOnly,
  checkboxDisabled,
  isReferencedOrder,
  enableJointDelivery = false,
  onCheckJointDelivery,
  checkedJointDelivery,
  disableJointDelivery,
  fetchOrders,
}) {
  const translate = useTranslate();
  const order = useSelector(state => state.appData.order);
  const isEmbedded = Boolean(window.location.pathname.match(/^\/embedded\//));
  const [adressModalVisible, setAdressModalVisible] = useState(false);
  const isCart = Boolean(window.location.pathname.includes("cart"));

  const filteredList = list?.filter(item => item.steps && item.steps[item.currentStep] === "ADDRESS_AND_CONTACT");

  const handleQuantityChange = (newQuantity, item) => {
    if (newQuantity !== item.product.quantity) {
      const data = { product: { ...item.product, quantity: newQuantity } };
      onQuantityChange(item.id, data);
    }
  };

  const { data } = useOrderWizard(pages.PRODUCT_ORDER.PATH, {
    contentType: CONTENT_TYPE.PRODUCT_DELIVERY,
  });

  const handleCheckChange = (checked, item) => {
    const checkedValue = ORDER_CONTROL[item.product.orderControl].value;
    const data = { ...item, subContentType: { id: checked ? checkedValue : ORDER_CONTROL.NONE.id } };
    onAssemblyInstallationChange(item.id, data);
  };

  return (
    <>
      {isCart ? (
        <>
          {filteredList.length ? (
            <Link className={styles.addressAndContactLink} onClick={() => setAdressModalVisible(true)}>
              {translate("CHANGE_ADDRESS_ON_MULTIPLE")}
            </Link>
          ) : null}
          <AddressAndContactModal
            visible={adressModalVisible}
            productList={filteredList}
            onClose={() => {
              setAdressModalVisible(false);
            }}
            data={data}
            showDeliveryMethods
            fetchOrders={fetchOrders}
          />
        </>
      ) : null}
      <ContentType title={translate("CART_PRODUCT_DELIVERY_TITLE")}>
        {list.map(item => {
          const hiddenCheckbox =
            order?.receiver?.type === CLIENT_TYPE.BASE_STORAGE || item.product.orderControl === ORDER_CONTROL.NONE.id;

          const checked = item.subContentType.id === "INSTALLATION" || item.subContentType.id === "ASSEMBLY";

          const checkboxLabel = translate(ORDER_CONTROL[item.product.orderControl].value);

          const disabled = isOrderControlMandatory(item.product.orderControl);

          let isMissingDeliveryAddress = false;
          if (item.steps) {
            const currentStepValue = item.steps[item.currentStep];
            isMissingDeliveryAddress = currentStepValue === "ADDRESS_AND_CONTACT";
          }

          return (
            <Item
              key={item.id}
              orderData={item}
              onDelete={onDelete}
              readOnly={readOnly}
              isEmbedded={isEmbedded}
              isReferencedOrder={isReferencedOrder}
              missingDeliveryAddress={isMissingDeliveryAddress}
              updateContent={onUpdateContent}
            >
              <ItemInfo>
                <div>
                  {!isEmbedded ? (
                    <Link
                      to={{
                        pathname: `${pages.PRODUCTS.PATH}/${item.product.productId}`,
                        state: item.product,
                      }}
                    >
                      <RowTitle text={item.product.name} />
                    </Link>
                  ) : (
                    <RowTitle text={item.product.name} />
                  )}
                  <Row title={translate("PRODUCT_NUMBER_SHORT")} text={item.product.productNumber} />
                  {((enableJointDelivery && item.status.value != CONTENT_STATUS.DRAFT) || readOnly) && (
                    <JointDeliveryCheckbox
                      item={item}
                      onCheckJointDelivery={onCheckJointDelivery}
                      checkedJointDelivery={checkedJointDelivery}
                      disableJointDelivery={disableJointDelivery}
                      readOnly={readOnly}
                    />
                  )}
                  {item.deliveryDate && (
                    <Row
                      title={translate("ETA")}
                      text={
                        item.requestedDeliveryDate
                          ? getFullDate(item.requestedDeliveryDate)
                          : getFullDate(item.deliveryDate)
                      }
                    />
                  )}

                  {!hiddenCheckbox && (
                    <Checkbox
                      label={checkboxLabel}
                      checked={checked}
                      disabled={disabled || readOnly || checkboxDisabled}
                      onChange={e => handleCheckChange(e.currentTarget.checked, item)}
                      customCssClass={styles.assemblyCheckbox}
                    />
                  )}
                </div>
                {readOnly || (isReferencedOrder && item.status.value !== CONTENT_STATUS.DRAFT) ? (
                  <p className={styles.quantityText}>
                    {item.product.quantity} {item.product.unit}
                    <Price
                      quantity={item.product?.quantity}
                      price={item.product?.salesPrice}
                      priceQuantity={item.product?.salesPriceQuantity}
                      decimals={item.product?.numberOfPriceDecimals}
                      currency={item.product?.salesPriceCurrency?.value?.toUpperCase()}
                    />
                  </p>
                ) : (
                  <div>
                    {(!isReferencedOrder || (isReferencedOrder && isEmbedded)) && (
                      <Counter
                        onChange={quantity => handleQuantityChange(quantity, item)}
                        min={1}
                        startValue={item.product.quantity}
                      />
                    )}
                    <Price
                      quantity={item.product?.quantity}
                      price={item.product?.salesPrice}
                      priceQuantity={item.product?.salesPriceQuantity}
                      decimals={item.product?.numberOfPriceDecimals}
                      currency={item.product?.salesPriceCurrency?.value?.toUpperCase()}
                      customCssClass={styles.price}
                    />
                  </div>
                )}
              </ItemInfo>
            </Item>
          );
        })}
      </ContentType>
    </>
  );
}

ProductDelivery.propTypes = {
  list: PropType.arrayOf(
    PropType.shape({
      id: PropType.string,
      address: PropType.object,
      contentType: PropType.string,
      product: PropType.shape({
        id: PropType.string,
        name: PropType.string,
        productNumber: PropType.string,
        quantity: PropType.number,
        supplier: PropType.string,
        orderControl: PropType.string,
      }),
      make: PropType.array,
      priority: PropType.object,
      status: PropType.number,
      subContentType: PropType.object,
      text: PropType.string,
    }),
  ),
  fetchOrders: PropType.func,
  onDelete: PropType.func,
  onQuantityChange: PropType.func,
  onAssemblyInstallationChange: PropType.func,
  onUpdateContent: PropType.func,
  readOnly: PropType.bool,
  checkboxDisabled: PropType.bool,
  isReferencedOrder: PropType.object,
  enableJointDelivery: PropType.bool,
  onCheckJointDelivery: PropType.func.isRequired,
  checkedJointDelivery: PropType.object,
  disableJointDelivery: PropType.bool,
};

export default ProductDelivery;
