import PropType from "prop-types";
import { useTranslate } from "../../../language/i18n";
import orderService from "../../../services/order/orderService";
import LogisticsConfirmModal from "../../shared/Modals/LogisticsConfirmModal";
import styles from "../FittingDetailPage.module.scss";

const AnnulFittingMeetingModal = ({ visible, onClose, fittingId, meetingId, setFetchFittings }) => {
  const translate = useTranslate();

  const onSubmit = async () => {
    const response = await orderService.annulFittingMeeting(fittingId, meetingId);
    if (response.isOk()) {
      setFetchFittings(true);
    }
  };

  return (
    <LogisticsConfirmModal
      title={translate("ANNUL_FITTING_MEETING")}
      text={translate("ANNUL_FITTING_MEETING_DESCRIPTION")}
      visible={visible}
      cancel={onClose}
      small={false}
      submit={onSubmit}
      customCssClass={styles.modal}
    ></LogisticsConfirmModal>
  );
};

AnnulFittingMeetingModal.propTypes = {
  visible: PropType.bool,
  onClose: PropType.func,
  fittingId: PropType.string,
  meetingId: PropType.string,
  setFetchFittings: PropType.func,
};

export default AnnulFittingMeetingModal;
