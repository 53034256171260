import { useEffect, useState } from "react";
import { FormData } from "@myloc/myg-forms";
import { Button } from "@myloc/myloc-gui";
import PropType from "prop-types";
import orderService from "../../../services/order/orderService";
import { useParams } from "react-router";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { useTranslate } from "../../../language/i18n";

function AdditionalInfo({ onNext }) {
  const translate = useTranslate();
  const [form, setForm] = useState({});
  const params = useParams();

  useEffect(() => {
    orderService.getForm(params.order, params.content).then(response => {
      setForm(response.data);
    });
  }, [params]);

  const getUpdateFormSequenceRequest = sequences => {
    const data = [];

    for (const sequence of sequences) {
      if ("type" in sequence) {
        data.push({
          id: sequence.id,
          value: sequence.value !== "" ? sequence.value : null,
        });
      } else if (sequence.sequences) {
        data.push({ id: sequence.id, sequences: getUpdateFormSequenceRequest(sequence.sequences) });
      }
    }

    return data;
  };

  const handleOnSubmit = async form => {
    const reqBody = {
      sequences: form?.sequences ? getUpdateFormSequenceRequest(form.sequences) : null,
      id: form?.id ?? null,
    };
    const response = await orderService.updateForm(params.order, params.content, reqBody);

    if (response.isOk()) {
      onNext(response.data);
    }
  };

  if (!form?.sequences && form?.sequences !== null) return <LoadingSpinner />;

  return form.sequences ? (
    <FormData form={form} onSubmit={handleOnSubmit} inputMode showNextButton />
  ) : (
    <Button onClick={() => handleOnSubmit()}>{translate("NEXT")}</Button>
  );
}

AdditionalInfo.propTypes = {
  onNext: PropType.func.isRequired,
};

export default AdditionalInfo;
