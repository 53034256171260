import PropType from "prop-types";
import { useTranslate } from "../../../../language/i18n";
import clientService from "../../../../services/client/clientService";
import ConfirmModal from "../../../shared/Modals/ConfirmModal";

const DeleteAddressModal = ({ visible, onClose, clientId, addressId, setFetchAddresses }) => {
  const translate = useTranslate();
  const onSubmit = async () => {
    const response = await clientService.deleteAddress(clientId, addressId);

    if (response.isOk()) {
      setFetchAddresses(true);
    }
  };
  return (
    <ConfirmModal
      title={translate("DELETE_ADDRESS")}
      text={translate("DELETE_ADDRESS_DESCRIPTION")}
      visible={visible}
      cancel={onClose}
      submit={onSubmit}
    />
  );
};

DeleteAddressModal.propTypes = {
  visible: PropType.bool,
  onClose: PropType.func,
  clientId: PropType.string.isRequired,
  addressId: PropType.string.isRequired,
  setFetchAddresses: PropType.func,
};

export default DeleteAddressModal;
