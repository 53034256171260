import { useState } from "react";
import PropType from "prop-types";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { useTranslate } from "../../../language/i18n";
import { CONTENT_PATH, CONTENT_TYPE } from "../../../utils/constants";

import orderService from "../../../services/order/orderService";

import { Button, THEME } from "@myloc/myloc-gui";
import Modal from "../../shared/Modals/Modal";
import FunctionOrderInfo from "../../FunctionOrder/OrderInfo";
import PickupOrderInfo from "../../PickupOrder/OrderInfo/OrderInfo";
import ProductOrderInfo from "../../ProductOrder/OrderInfo";
import NewProductOrderInfo from "../../Products/ExternalAssortmentPage/OrderInfo";
import WorkOrderInfo from "../../WorkOrder/OrderInfo";
import FittingOrderInfo from "../../FittingOrder/OrderInfo";

import styles from "./CartModals.module.scss";

function OrderOverviewModal({ orderId, orderData, onClose, visible, readOnly }) {
  const history = useHistory();
  const queryParams = useParams();
  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState(false);

  const contentToShow = {
    [CONTENT_TYPE.FUNCTION]: <FunctionOrderInfo orderData={orderData} />,
    [CONTENT_TYPE.NEW_PRODUCT]: <NewProductOrderInfo orderData={orderData} />,
    [CONTENT_TYPE.PRODUCT_DELIVERY]: <ProductOrderInfo orderData={orderData} />,
    [CONTENT_TYPE.PICKUP]: <PickupOrderInfo orderData={orderData} />,
    [CONTENT_TYPE.FITTING]: <FittingOrderInfo orderData={orderData} />,
    [CONTENT_TYPE.WORK]: <WorkOrderInfo orderData={orderData} />,
  };

  const handleEdit = async () => {
    setIsLoading(true);
    const response = await orderService.revertContentToDraft({ orderId: orderId, contentId: orderData.id });

    if (response.isOk()) {
      onClose();
      const location = {
        pathname: generatePath(`${CONTENT_PATH[orderData.contentType]}`, {
          order: queryParams.order,
          content: orderData.id,
        }),
      };
      history.push(location);
    }
    setIsLoading(false);
  };

  return (
    <Modal
      title={translate("ORDER_OVERVIEW_SHORT")}
      visible={visible}
      onClose={onClose}
      customCssClass={styles.orderOverviewModal}
      footerContent={
        <>
          <Button theme={THEME.SECONDARY} onClick={onClose}>
            {translate("CANCEL")}
          </Button>
          {!readOnly && (
            <Button onClick={handleEdit} isLoading={isLoading}>
              {translate("EDIT")}
            </Button>
          )}
        </>
      }
    >
      {contentToShow[orderData.contentType]}
    </Modal>
  );
}

OrderOverviewModal.propTypes = {
  orderId: PropType.string,
  orderData: PropType.object,
  onClose: PropType.func,
  visible: PropType.bool,
  readOnly: PropType.bool,
};

export default OrderOverviewModal;
