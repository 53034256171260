import {
  AUTHORIZATION_LIST,
  INITIALIZED,
  IS_SINGLE_SIGN_ON,
  LOGIN_TOKEN,
  REMEMBER_ME,
  SESSION_ID,
  SINGLE_SIGN_OUT,
  SSO_SESSION_DATA,
  USER_HAS_MESSAGE_TO_ACCEPT,
} from "./sessionVariables";

const initialState = {
  loginToken: null,
  rememberMe: null,
  sessionId: null,
  initialized: false,
  authorizationList: null,
  userMessageToAccept: null,
  isSingleSignOn: undefined,
  ssoSessionData: null,
  singleSignOut: null,
};

export const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_TOKEN:
      return {
        ...state,
        loginToken: action.loginToken,
      };
    case REMEMBER_ME: {
      const rememberMe = action.rememberMe instanceof String ? action.rememberMe === "true" : action.rememberMe;
      return {
        ...state,
        rememberMe: rememberMe,
      };
    }
    case AUTHORIZATION_LIST: {
      return {
        ...state,
        authorizationList: action.authorizationList,
      };
    }

    case INITIALIZED: {
      return {
        ...state,
        initialized: action.initialized,
      };
    }
    case USER_HAS_MESSAGE_TO_ACCEPT: {
      return {
        ...state,
        userMessageToAccept: action.message,
      };
    }
    case IS_SINGLE_SIGN_ON: {
      return {
        ...state,
        isSingleSignOn: action.isSingleSignOn,
      };
    }
    case SINGLE_SIGN_OUT: {
      return {
        ...state,
        singleSignOut: action.singleSignOut,
      };
    }
    case SSO_SESSION_DATA: {
      return {
        ...state,
        ssoSessionData: action.ssoSessionData,
      };
    }
    case SESSION_ID: {
      return {
        ...state,
        sessionId: action.sessionId,
      };
    }
    default:
      return state;
  }
};
