import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Collapse, Button, THEME } from "@myloc/myloc-gui";
import { useMobile } from "../../../../utils/viewport";
import { ChevronUp, ChevronDown } from "../../../../assets/icons";
import { useTranslate } from "../../../../language/i18n";
import { openHoldingPopup } from "../../../../reducers/dialog/dialogAction";
import { CLIENT_TYPE } from "../../../../utils/constants";
import pages from "../../../../utils/pages";
import MunicipalityLogo from "../../../shared/MunicipalityLogo/MunicipalityLogo";
import styles from "./ReceiverMenu.module.scss";
import { setReceiverMenu } from "../../../../reducers/appData/appDataActions";

function ReceiverMenu({ customCssClass }) {
  const translate = useTranslate();
  const isMobile = useMobile();
  const history = useHistory();

  const [menuVisible, setMenuVisible] = useState(false);
  const order = useSelector(state => state.appData.order);

  let hideMenu;

  const isBaseStorage = order.receiver.type === CLIENT_TYPE.BASE_STORAGE;

  const { defaultAddress } = order?.receiver;

  const logo = defaultAddress?.municipalLogo && (
    <MunicipalityLogo
      id={defaultAddress?.municipal?.value}
      customCssClass={styles.logo}
      fullLogo={false}
      imgLink={defaultAddress?.municipalLogo}
    />
  );

  const displayReceiverName = receiver => {
    const maxLength = logo ? 10 : 18;
    if (isMobile) {
      const fullName = `${receiver.firstName} ${receiver.surname}`;
      if (fullName?.length > maxLength) {
        const truncatedFirstNameOnly = `${truncateFirstNameOnly(receiver.firstName)} ${receiver.surname}`;
        if (truncatedFirstNameOnly.length > maxLength) {
          return truncateFirstNameAndSurname(receiver, maxLength - 5);
        } else {
          return truncatedFirstNameOnly;
        }
      }
    }
    return `${receiver.firstName} ${receiver.surname}`;
  };

  const truncateFirstNameOnly = firstName => {
    return firstName.slice(0, 1);
  };

  const truncateFirstNameAndSurname = (receiver, maxLength) => {
    const amountToRemove = receiver.surname.length - maxLength;
    return `${receiver.firstName.slice(0, 1)} ${receiver.surname.slice(0, -amountToRemove)}...`;
  };
  const handleOnMouseLeave = () => {
    hideMenu = setTimeout(() => {
      setMenuVisible(false);
    }, 1000);
  };

  const handleOnMouseEnter = () => {
    clearTimeout(hideMenu);
  };

  const handleOnClick = () => {
    if (!isBaseStorage) {
      setMenuVisible(!menuVisible);
    }
  };

  const goToClientOverviewPage = () => {
    setReceiverMenu(true);
    setMenuVisible(false);
    history.push({
      pathname: `${pages.CLIENTS.PATH}/${order?.receiver?.id}`,
      state: { receiver: order?.receiver, from: ReceiverMenu.name },
    });
  };

  const buttonText = order?.receiver ? (
    <>
      {isBaseStorage ? (
        <p className={classNames(styles.recieverInfo)}>
          {order.receiver?.value} - {order.receiver?.label}
        </p>
      ) : (
        <p className={styles.recieverInfo}>
          {displayReceiverName(order.receiver)}
          <span className={logo ? styles.smallerIdentityNumber : styles.orderId}>{order.receiver?.identityNumber}</span>
        </p>
      )}
    </>
  ) : (
    translate("NO_ORDER_SELECTED")
  );

  return (
    <>
      <section
        className={classNames(styles.menuWrapper, customCssClass)}
        onMouseLeave={handleOnMouseLeave}
        onMouseEnter={handleOnMouseEnter}
      >
        <Button theme={THEME.PLAIN} onClick={handleOnClick}>
          {logo}
          {buttonText}
          {!isBaseStorage &&
            (menuVisible ? <ChevronUp customCssClass={styles.icon} /> : <ChevronDown customCssClass={styles.icon} />)}
        </Button>
        <Collapse visible={menuVisible} customCssClass={classNames(styles.submenuWrapper, !isMobile && styles.menu)}>
          <ul className={styles.userMenu}>
            {order.receiver.type === CLIENT_TYPE.USER && (
              <li>
                <Button theme={THEME.PLAIN} onClick={() => openHoldingPopup()}>
                  {translate("HOLDING_LIST")}
                </Button>
              </li>
            )}

            <li>
              {isBaseStorage ? (
                <Button theme={THEME.PLAIN}>{translate("BASE_STORAGE_OVERVIEW")}</Button>
              ) : (
                <Button theme={THEME.PLAIN} onClick={goToClientOverviewPage}>
                  {translate("CLIENTS_TITLE")}
                </Button>
              )}
            </li>
          </ul>
        </Collapse>
      </section>
    </>
  );
}

ReceiverMenu.propTypes = {
  customCssClass: PropTypes.string,
};

export default ReceiverMenu;
