import { Button, Form, InputField, Search } from "@myloc/myloc-gui";
import { useHistory } from "@myloc/myloc-utils";
import PropType from "prop-types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslate } from "../../../../language/i18n";
import messageService from "../../../../services/message/messageService";
import pages from "../../../../utils/pages";
import { useMobile } from "../../../../utils/viewport";
import Page from "../../../shared/Page/Page";
import Paginate from "../../../shared/Pagination/Paginate";
import RadioButtonGroup from "../../../shared/RadioButton/RadioButtonGroup";
import MessageDetail from "../MessageDetail/MessageDetail";
import MessageRow from "../MessageRow/MessageRow";
import { MESSAGE_TYPE } from "../MessageTypes";
import styles from "./Message.module.scss";
const DEFAULT_FILTER = "ALL";

const Message = ({ setBreadcrumbs, messageType }) => {
  const translate = useTranslate();
  const showFilters = messageType === MESSAGE_TYPE.ALL || messageType === MESSAGE_TYPE.INBOX;
  const history = useHistory();
  const params = useMemo(() => new URLSearchParams(window.location.search), []);
  const isMobile = useMobile();
  const [messages, setMessages] = useState([]);
  const [searchValue, setSearchValue] = useState(params.get("q") || null);
  const [searchValueText, setSearchValueText] = useState();
  const [selectedMessageId, setSelectedMessageId] = useState("");
  const [loading, setLoading] = useState(false);
  const [readValue, setReadValue] = useState(showFilters ? params.get("filter") || DEFAULT_FILTER : null);
  const firstParamCheck = useRef(true);

  const filterStates = [
    { id: "ALL", value: translate("ALL") },
    { id: "UNREAD", value: translate("UNREAD") },
  ];

  const handleOnClickMessage = message => {
    setSelectedMessageId(message?.id);
  };

  const filterSearch = useCallback(() => {
    setSelectedMessageId("");

    setSearchValueText(searchValue);
  }, [searchValue]);

  const getIsReadFilterValue = useCallback(() => {
    switch (readValue) {
      case "ALL":
        return null;
      case "UNREAD":
        return false;
    }

    return null;
  }, [readValue]);

  const getMessages = useCallback(
    filter => {
      switch (messageType) {
        case MESSAGE_TYPE.INBOX:
          return messageService.getMessages({
            searchValue: searchValueText,
            status: "20:=",
            isRead: getIsReadFilterValue(),
            sort: "SentDate:desc",
            isSender: false,
            ...filter,
          });
        case MESSAGE_TYPE.SENT:
          return messageService.getMessages({
            isSender: true,
            searchValue: searchValueText,
            sort: "SentDate:desc",
            ...filter,
          });

        case MESSAGE_TYPE.ARCHIVED:
          return messageService.getMessages({
            searchValue: searchValueText,
            status: "90:=",
            sort: "SentDate:desc",
            ...filter,
          });

        case MESSAGE_TYPE.ALL:
          return messageService.getMessages({
            searchValue: searchValueText,
            isRead: getIsReadFilterValue(),
            status: "20:>=;90:<=",
            sort: "SentDate:desc",
            ...filter,
          });
      }
    },
    [messageType, searchValueText, getIsReadFilterValue],
  );

  const getProvider = useCallback(
    filter => {
      return getMessages(filter);
    },
    [getMessages],
  );

  const getMessageName = useCallback(() => {
    switch (messageType) {
      case MESSAGE_TYPE.INBOX:
        return pages.MESSAGES_INBOX.NAME;
      case MESSAGE_TYPE.SENT:
        return pages.MESSAGES_SENT.NAME;
      case MESSAGE_TYPE.ARCHIVED:
        return pages.MESSAGES_ARCHIVED.NAME;
      case MESSAGE_TYPE.ALL:
        return pages.MESSAGES_ALL.NAME;
    }
  }, [messageType]);

  const clear = () => {
    setSelectedMessageId("");
    setSearchValue("");
    setSearchValueText("");
  };

  const handleIsReadChange = async selected => {
    setReadValue(selected.id);
    clear();
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    let paramsChanged = false;

    if (searchValue === null || searchValue === "") {
      if (params.get("q")) {
        params.delete("q");
        paramsChanged = true;
      }
    } else if (searchValue !== params.get("q")) {
      params.set("q", searchValue);
      paramsChanged = true;
    }

    if (showFilters) {
      if (params.get("filter") !== readValue) {
        params.set("filter", readValue);
        paramsChanged = true;
      }
    }

    if (paramsChanged) history.replace(`${window.location.pathname}${window.location.search}`, params);
  }, [searchValue, history, readValue, showFilters]);

  useEffect(() => {
    return () => {
      clear();
    };
  }, [messageType]);

  useEffect(() => {
    if (firstParamCheck.current && params.get("q") != null) {
      firstParamCheck.current = false;
      filterSearch();
    }
  }, [filterSearch, params]);

  useEffect(() => {
    setBreadcrumbs([{ text: `${translate(pages.MESSAGES.NAME)} - ${translate(getMessageName())}` }]);
  }, [setBreadcrumbs, translate, getMessageName]);

  return (
    <Page customCssClass={styles.page} title={translate(getMessageName())}>
      <div className={styles.grid}>
        <div className={styles.list}>
          <Form onSubmit={filterSearch} className={styles.generalSearch}>
            <InputField
              label={translate("SEARCH_FOR_MESSAGE")}
              value={searchValue}
              onChange={event => setSearchValue(event.target.value)}
              onClear={() => setSearchValue("")}
            />
            <Button type="submit" customCssClass={styles.button}>
              {isMobile ? <Search /> : translate("SEARCH")}
            </Button>
          </Form>
          {showFilters && (
            <RadioButtonGroup
              values={filterStates}
              onChange={handleIsReadChange}
              selected={readValue}
              customCssClass={styles.filter}
            />
          )}
          <Paginate onChange={setMessages} provider={getProvider} isLoading={loading} setIsLoading={setLoading}>
            {!!messages.length &&
              messages.map(message => (
                <MessageRow
                  key={message.id}
                  message={message}
                  handleOnClickMessage={handleOnClickMessage}
                  activeMessage={selectedMessageId === message.id ?? false}
                  setSelectedMessageId={setSelectedMessageId}
                  setMessages={setMessages}
                  messageType={messageType}
                />
              ))}
          </Paginate>
        </div>
        <div className={selectedMessageId && styles.messageDetail}>
          {selectedMessageId && (
            <MessageDetail messageId={selectedMessageId} setMessages={setMessages} messageType={messageType} />
          )}
        </div>
      </div>
    </Page>
  );
};

Message.propTypes = {
  setBreadcrumbs: PropType.func.isRequired,
  messageType: PropType.string.isRequired,
};

export default Message;
