import PropTypes from "prop-types";
import { useTranslate } from "../../language/i18n";
import { AdditionalInfoSection, AddressSection, InfoSection, Row } from "../shared/OrderInfoSections/OrderInfoSections";

function OrderInfo({ orderData: { product, accessories, subContentType, priority, text, form, address, contact } }) {
  const translate = useTranslate();

  const showAid = (product, text) => {
    if (!product && !text) {
      return;
    }

    return (
      <InfoSection title={translate("AID")}>
        {product?.name && <Row content={product?.name} />}
        {product?.inventoryNumber && (
          <Row noSpacing title={translate("INVENTORY_NUMBER_SHORT")} content={product?.inventoryNumber} />
        )}
        {product?.productNumber && (
          <Row noSpacing title={translate("PRODUCT_NUMBER_SHORT")} content={product?.productNumber} />
        )}
        {text && <Row title={translate("DESCRIBE_AID")} content={text} />}
      </InfoSection>
    );
  };

  const showAccessories = accessories => {
    if (!accessories?.length) {
      return;
    }

    return (
      <InfoSection title={translate("ACCESSORIES")}>
        {accessories.map(accessory => (
          <Row key={accessory.id} content={accessory.name} />
        ))}
      </InfoSection>
    );
  };

  const showWorkType = ({ workType, priority }) => {
    if (!workType || !priority) {
      return;
    }

    return (
      <InfoSection title={`${translate("WORK_TYPE")}/${translate("PRIORITY")}`}>
        <Row title={translate("WORK_TYPE")} content={workType.value} />
        <Row title={translate("PRIORITY")} content={priority.value} />
      </InfoSection>
    );
  };

  return (
    <section>
      {showAid(product, text)}
      {showAccessories(accessories)}
      {showWorkType({ workType: subContentType, priority })}
      {form && <AdditionalInfoSection form={form} />}
      {(address || contact) && (
        <AddressSection address={address} contact={contact} title="WORK_ORDER_ADDRESS_AND_CONTACT" />
      )}
    </section>
  );
}

OrderInfo.propTypes = {
  orderData: PropTypes.shape({
    product: PropTypes.object,
    accessories: PropTypes.array,
    text: PropTypes.string,
    form: PropTypes.object,
    address: PropTypes.object,
    contact: PropTypes.object,
    subContentType: PropTypes.object,
    priority: PropTypes.object,
  }),
};

export default OrderInfo;
