import classNames from "classnames";
import PropType from "prop-types";
import { useEffect, useState } from "react";
import Problem from "../../../assets/icons/Problem";
import useDebounce from "../../../hooks/useDebounce";
import { useTranslate } from "../../../language/i18n";
import { setError } from "../../../reducers/dialog/dialogAction";
import Label from "../../shared/Label/Label";
import styles from "./Inventory.module.scss";

const InventoryDetails = ({ inventoryLine, updateInventoryLine }) => {
  const translate = useTranslate();
  const [reportedValue, setReportedValue] = useState(inventoryLine?.reportedQty);
  const plusDiff = inventoryLine?.balanceAtReportedTime - inventoryLine?.reportedQty < 0;
  const minusDiff = inventoryLine?.balanceAtReportedTime - inventoryLine?.reportedQty > 0;
  const debouncedReportedValue = useDebounce(reportedValue, 500);

  useEffect(() => {
    if (debouncedReportedValue !== inventoryLine?.reportedQty) {
      updateInventoryLine(debouncedReportedValue, inventoryLine);
    }
  }, [debouncedReportedValue, inventoryLine, updateInventoryLine]);

  const getDifference = () => {
    if (plusDiff) {
      return `${translate("DIFF")} + ${inventoryLine?.reportedQty - inventoryLine?.balanceAtReportedTime}`;
    } else if (minusDiff) {
      return `${translate("DIFF")} - ${inventoryLine?.balanceAtReportedTime - inventoryLine?.reportedQty}`;
    }
    return translate("OK");
  };

  const removeSeconds = time => {
    const splitTime = time?.split(":");
    return `${splitTime[0]}:${splitTime[1]}`;
  };

  return (
    <div>
      <article className={styles.item} key={inventoryLine?.id}>
        <section className={classNames(styles.section, inventoryLine?.deviation && styles.titleDeviation)}>
          <div className={styles.column}>
            <p className={styles.itemTitle}>
              {inventoryLine?.product?.name || inventoryLine?.product?.id}
              {inventoryLine?.product?.hasDeviation && <Problem customCssClass={styles.deviation} />}
            </p>
            <Label label={translate("PRODUCT_NUMBER_SHORT")} value={inventoryLine?.product?.productNumber} inline />
            <Label
              label={translate("INVENTORY_TAKEN")}
              value={
                inventoryLine?.approvedDate
                  ? `${inventoryLine?.approvedDate} ${removeSeconds(inventoryLine?.approvedTime)} / ${
                      inventoryLine?.approvedBy
                    }`
                  : ""
              }
              inline
              hideEmpty
            />
          </div>
          <div className={styles.column}>
            <Label label={translate("STORAGE_PLACE")} value={inventoryLine?.location?.value} inline />
            <Label label={translate("INVENTORY_NUMBER_SHORT")} value={inventoryLine?.product?.inventoryNumber} inline />
            <Label label={translate("BATCH_NO")} value={inventoryLine?.product?.batch} inline />
          </div>
          <div className={styles.column}>
            <Label
              label={translate("STOCK_BALANCE")}
              value={inventoryLine?.product?.quantity + " " + translate("UNITS")}
              inline
            />
            <Label label={translate("RESERVED")} value={inventoryLine?.product?.allocatedQuantity} inline />
            <Label
              label={translate("AVAILABLE_BALANCE")}
              value={inventoryLine?.product?.quantity - inventoryLine?.product?.allocatedQuantity}
              inline
            />
          </div>
          <div className={styles.reportQuantity}>
            <div className={styles.reportInput}>
              <span className={styles.text}>{translate("REPORTED_QUANTITY")}:</span>
              <ReportingInput
                item={inventoryLine}
                max={inventoryLine?.product?.inventoryNumber && 1}
                onChange={e => setReportedValue(e)}
                startValue={inventoryLine?.reportedQty}
              />
              {inventoryLine?.reportedQty !== null && inventoryLine?.reportedDate && (
                <span className={plusDiff || minusDiff ? styles.diff : styles.ok}>{getDifference()}</span>
              )}
            </div>

            {inventoryLine?.reportedQty !== null && inventoryLine?.reportedDate && (
              <>
                <Label
                  label={translate("STOCK_BALANCE_WHEN_REPORTING")}
                  value={inventoryLine?.balanceAtReportedTime}
                  customLabelCss={styles.inline}
                  inline
                />

                <Label
                  label={translate("REPORTED_SINGULAR")}
                  value={
                    inventoryLine?.reportedDate
                      ? `${inventoryLine?.reportedDate} ${removeSeconds(inventoryLine?.reportedTime)} / ${
                          inventoryLine?.reportedBy
                        }`
                      : ""
                  }
                  inline
                  hideEmpty
                />
              </>
            )}
          </div>
        </section>
      </article>
    </div>
  );
};

const ReportingInput = ({ item, min, max, onChange, startValue, disabled, customCssClass }) => {
  const translate = useTranslate();
  const [value, setValue] = useState(startValue);
  const [clearInput, setClearInput] = useState(false);

  useEffect(() => {
    onChange(value);
  }, [onChange, value]);

  const handleOnChange = e => {
    const newVal = e.target.value;

    const parsedVal = parseInt(newVal, 10);
    if (max && parsedVal > max) {
      setError({
        error: 500,
        errorMessage: translate("REPORTED_INVENTORY_QUANTITY_NOT_VALID", {
          inventoryNumber: item?.product?.inventoryNumber,
        }),
      });
      setClearInput(true);
    } else if (parsedVal < 0) {
      setError({
        error: 500,
        errorMessage: translate("MINIMUM_VALUE_IS_ZERO"),
      });
      setClearInput(true);
    } else {
      if (clearInput) setClearInput(false);
      if (newVal === "") {
        setValue(null);
      } else {
        setValue(parsedVal);
      }
    }
  };

  return (
    <div className={classNames(styles.counter, customCssClass)}>
      <input
        name="count"
        type="number"
        className={styles.count}
        min={min}
        max={max}
        value={clearInput ? "" : value}
        onChange={handleOnChange}
        disabled={disabled}
      />
    </div>
  );
};

Label.propTypes = {
  label: PropType.string,
  value: PropType.string,
  customLabelCss: PropType.string,
};

ReportingInput.propTypes = {
  item: PropType.object,
  min: PropType.number,
  max: PropType.number,
  startValue: PropType.number,
  onChange: PropType.func.isRequired,
  disabled: PropType.bool,
  customCssClass: PropType.string,
};

InventoryDetails.propTypes = {
  inventoryLine: PropType.object,
  updateInventoryLine: PropType.func,
};

export default InventoryDetails;
