import PropTypes from "prop-types";

const Trash = ({ color = "currentColor", size = 24, customCssClass }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={color}
    className={customCssClass}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
  </svg>
);

Trash.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  customCssClass: PropTypes.string,
};

export default Trash;
