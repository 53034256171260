import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "../../../../language/i18n";
import { CLIENT_TYPE } from "../../../../utils/constants";
import { openOrderPopup } from "../../../../reducers/dialog/dialogAction";
import MessageBox, { Type } from "../../../shared/MessageBox/MessageBox";
import { Button, THEME } from "@myloc/myloc-gui";
import OrderControl from "./OrderControl";
import { DELIVERY_LOCATION, getOrderControlValue, isOrderControlMandatory, ORDER_CONTROL } from "./productConstants";
import styles from "./ProductOrder.module.scss";

const ProductOrder = ({
  onClick,
  receiverLocation,
  onSetReceiverLocation,
  messageBoxText,
  inventoryBaseStorage,
  disabled = false,
  ...props
}) => {
  const translate = useTranslate();
  const receiver = useSelector(state => state.appData.order?.receiver);

  const quantity = 1;
  const [orderControlChecked, setOrderControlChecked] = useState(isOrderControlMandatory(props.orderControl));

  useEffect(() => {
    onSetReceiverLocation(
      receiver?.type === CLIENT_TYPE.BASE_STORAGE || !props?.storageBalances?.length
        ? DELIVERY_LOCATION.DELIVERY
        : DELIVERY_LOCATION.BASE_STORAGE,
    );
  }, [onSetReceiverLocation, props?.storageBalances?.length, receiver]);

  useEffect(() => {
    setOrderControlChecked(
      receiverLocation.id === DELIVERY_LOCATION.DELIVERY.id && isOrderControlMandatory(props.orderControl),
    );
  }, [receiverLocation, props.orderControl]);

  const onOrder = async () => {
    const orderControl = orderControlChecked ? getOrderControlValue(props.orderControl) : ORDER_CONTROL.NONE.value;
    await onClick(quantity, null, orderControl);
  };

  return (
    <section className={styles.container}>
      {!receiver && (
        <>
          <MessageBox
            type={Type.Warning}
            message={messageBoxText ? messageBoxText : translate("NO_RECEIVER_SELECTED")}
            customCssClass={styles.messageBox}
          >
            <Button
              theme={THEME.SECONDARY}
              onClick={() => openOrderPopup({ includeNavigation: false })}
              customCssClass={styles.messageButton}
              disabled={disabled}
            >
              {translate("NEW_ORDER")}
            </Button>
          </MessageBox>
        </>
      )}

      <Button onClick={onOrder} disabled={!receiver || disabled}>
        {translate("ADD_TO_CART")}
      </Button>

      <div className={styles.row}>
        <div className={styles.label}>{translate("RESERVE_AND_PICKUP_IN_BASE_STORAGE")}:</div>
        <div>{inventoryBaseStorage ? inventoryBaseStorage : props?.selectedStorage?.description}</div>
      </div>
      <OrderControl
        receiverLocation={receiverLocation.id}
        checked={orderControlChecked}
        onToggle={() => setOrderControlChecked(!orderControlChecked)}
        orderControl={props.orderControl}
      />
    </section>
  );
};

ProductOrder.propTypes = {
  isInventory: PropTypes.bool,
  orderControl: PropTypes.string,
  quantityDecimals: PropTypes.number,
  quantity: PropTypes.number,
  accessories: PropTypes.array,
  storageBalances: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string,
      quantity: PropTypes.number,
    }),
  ),
  selectedStorage: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    quantity: PropTypes.number,
    description: PropTypes.string,
  }),
  onSelectStorage: PropTypes.func,
  receiverLocation: PropTypes.shape({
    id: PropTypes.string,
  }),
  onSetReceiverLocation: PropTypes.func,
  onClick: PropTypes.func,
  messageBoxText: PropTypes.string,
  inventoryBaseStorage: PropTypes.string,
  disabled: PropTypes.string,
};

export default ProductOrder;
