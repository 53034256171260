import MyFittingsPage from "./MyFittingsPage";
import AdminFittingsPage from "./AdminFittingsPage";
import { setEmbeddedFitting } from "../../reducers/embedded/embeddedActions";

const FittingsPage = () => {
  const params = new URLSearchParams(window.location.search);
  setEmbeddedFitting({
    path: `${window.location.pathname}${window.location.search}`,
    text: params.get("role") === "admin" ? "FITTING_ADMIN_PLANNING" : "SUB_NAV_MY_FITTINGS",
  });

  if (params.get("role") === "admin") {
    return <AdminFittingsPage />;
  } else {
    return <MyFittingsPage />;
  }
};

export default FittingsPage;
