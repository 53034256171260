import PropType from "prop-types";
import { useTranslate } from "../../../../language/i18n";
import clientService from "../../../../services/client/clientService";
import productService from "../../../../services/product/productService";
import ConfirmModal from "../../Modals/ConfirmModal";

const RemoveAccessoryModal = ({ clientId, holdingId, fixedAssetId, visible, onClose, selectedAccessories }) => {
  const translate = useTranslate();
  const onSubmit = async () => {
    const response = await (clientId
      ? clientService.removeAccessoriesFromAid({
          clientId,
          holdingId: encodeURIComponent(holdingId),
          data: selectedAccessories,
        })
      : productService.removeAccessoriesFromFixedAsset({
          fixedAssetId: encodeURIComponent(fixedAssetId),
          data: selectedAccessories,
        }));
    if (response.isOk()) {
      location.reload();
    }
  };

  return (
    <ConfirmModal
      title={translate("REMOVE_ACCESSORIES")}
      text={translate("REMOVE_ACCESSORIES_DESCRIPTION")}
      visible={visible}
      cancel={onClose}
      small={false}
      submit={onSubmit}
    ></ConfirmModal>
  );
};

RemoveAccessoryModal.propTypes = {
  clientId: PropType.string,
  holdingId: PropType.string,
  fixedAssetId: PropType.string,
  visible: PropType.bool.isRequired,
  onClose: PropType.func.isRequired,
  selectedAccessories: PropType.array.isRequired,
};

export default RemoveAccessoryModal;
