import FittingDetailPage from "../FittingDetail/FittingDetailPage";
import pages from "../../utils/pages";
import styles from "./FittingsPage.module.scss";
import { useLocation } from "react-router-dom";

const MyFittingPage = () => {
  const location = useLocation();

  const referrer = {
    path: location.state?.path || pages.EMBEDDED_MY_FITTINGS.PATH,
    text: location.state?.text,
  };

  return (
    <div className={styles.detail}>
      <FittingDetailPage referrer={referrer} fromComponent={`${window.location.pathname}${window.location.search}`} />
    </div>
  );
};

export default MyFittingPage;
