import { useEffect, useState } from "react";
import { InputField } from "@myloc/myloc-gui";
import { useHistory } from "@myloc/myloc-utils";
import { useTranslate } from "../../language/i18n";
import pages from "../../utils/pages";
import Page from "../shared/Page/Page";
import OrdersTable from "../shared/Tables/OrdersTable";
import SelectField from "../shared/SelectField/SelectField";
import TopFilter from "../shared/TopFilter/TopFilter";
import styles from "./OrdersPage.module.scss";
import orderService from "../../services/order/orderService";

const FILTERS = ["clients", "facilities", "fromDate", "toDate"];

const OrdersPage = () => {
  const history = useHistory();
  const translate = useTranslate();
  const params = new URLSearchParams(window.location.search);

  const breadcrumbs = [
    {
      text: translate(pages.ORDERS.NAME),
    },
  ];

  const [availableFilters, setAvailableFilters] = useState({ clients: [], facilities: [] });
  const [filters, setFilters] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});
  const [freeTextSearch, setFreeTextSearch] = useState(params.get("q"));

  useEffect(() => {
    orderService
      .getFiltersForOrders({ clients: true, facilities: true })
      .then(response => setAvailableFilters({ ...response.data }));
  }, []);

  useEffect(() => {
    if (params.get("search")) {
      if (filters.clients || filters.facilities || filters.fromDate || filters.toDate) {
        filterSearch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const filterSearch = () => {
    if (!filters.clients && !filters.facilities && !filters.fromDate && !filters.toDate) {
      params.delete("search");
      history.replace(pages.ORDERS.PATH, params);
    }
    if (freeTextSearch != params.get("q")) {
      setFreeTextSearch(params.get("q"));
    }
    setSelectedFilters({
      clientId: filters?.clients || "",
      facilityId: decodeURI(filters?.facilities) || "",
      fromOrderDate: filters?.fromDate || "",
      toOrderDate: filters?.toDate || "",
    });
  };

  const onClickSearch = () => {
    params.set("search", true);
    history.replace(pages.ORDERS.PATH, params);
    filterSearch();
  };

  const onFilterChange = (key, value) => {
    params.delete("search");
    history.replace(pages.ORDERS.PATH, params);
    setFilters({ ...filters, [key]: value });
  };

  const onSelectFilter = (option, key) => {
    if (!option) {
      setFilters(prev => ({ ...prev, [key]: "" }));
    } else {
      setFilters(prev => {
        return { ...prev, [key]: option.id };
      });
    }

    if (params.get("search")) {
      setFreeTextSearch(null);
      params.delete("search");
      history.replace(pages.ORDERS.PATH, params);
    }
  };

  return (
    <Page customCssClass={styles.page} title={translate(pages.ORDERS.NAME)} breadcrumbs={breadcrumbs}>
      <TopFilter
        onSubmit={onClickSearch}
        filters={filters}
        setFilters={setFilters}
        fields={FILTERS}
        label={translate("SEARCH_FOR_ORDER")}
      >
        <>
          <div className={styles.searchGroup}>
            <InputField
              type="date"
              label={translate("FROM_DATE")}
              labelAlwaysTop
              value={filters?.fromDate}
              onChange={option => onFilterChange("fromDate", option?.target?.value)}
            />
            <InputField
              type="date"
              label={translate("TO_DATE")}
              labelAlwaysTop
              value={filters?.toDate}
              onChange={option => onFilterChange("toDate", option?.target?.value)}
            />
          </div>

          <div className={styles.searchGroup}>
            {availableFilters?.clients?.length && (
              <SelectField
                label={translate("USER")}
                options={availableFilters?.clients}
                selectedId={filters?.clients}
                onSelect={option => onSelectFilter(option, "clients")}
                prepopulate={false}
                customSettings={{ display: "label" }}
              />
            )}
            {availableFilters?.facilities?.length && (
              <SelectField
                label={translate("BASE_STORAGE")}
                options={availableFilters?.facilities?.map(current => {
                  return {
                    id: current.id,
                    value: `${current.value} - ${current.label}`,
                  };
                })}
                selectedId={filters.facilities}
                onSelect={option => onSelectFilter(option, "facilities")}
                prepopulate={false}
              />
            )}
          </div>
        </>
      </TopFilter>
      <OrdersTable selectedFilters={selectedFilters} freeTextSearch={freeTextSearch} search={!!params.get("search")} />
    </Page>
  );
};

export default OrdersPage;
