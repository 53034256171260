import { Button, THEME } from "@myloc/myloc-gui";
import classNames from "classnames";
import PropType from "prop-types";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslate } from "../../../language/i18n";
import taskService from "../../../services/taskService";
import { CLIENT_TYPE } from "../../../utils/constants";
import pages from "../../../utils/pages";
import Checkbox from "../../shared/Checkbox/Checkbox";
import Counter from "../../shared/Counter/Counter";
import ConfirmModal from "../../shared/Modals/ConfirmModal";
import styles from "./TaskDetails.module.scss";

const STATUS_COMPLETED = 90;
const ACTION = { RETURN: "RETURN", HAND_OVER: "HAND_OVER" };

const TaskTypeCH = ({ task }) => {
  const translate = useTranslate();
  const history = useHistory();
  const [selection, setSelection] = useState([]);
  const [modal, setModal] = useState(null);
  const disabled = !selection.length;

  const getAllocationQuantity = (lineAllocations, selection) => {
    let count = 0;

    const allocationQuantity = lineAllocations
      .map(allocation => {
        if (count === selection.quantity) return;
        if (count + allocation.quantity <= selection.quantity) {
          count = count + allocation.quantity;
          return { allocation, quantity: allocation.quantity };
        } else if (count + allocation.quantity > selection.quantity) {
          const diff = selection.quantity - count;
          if (diff) {
            return { allocation, quantity: diff };
          } else {
            return { allocation, quantity: selection.quantity };
          }
        }
      })
      .filter(allocation => allocation);

    if (allocationQuantity.length) return allocationQuantity[0];
    return null;
  };

  const getSelectedAllocations = () => {
    let allocationData = [];
    if (!selection.length) return allocationData;

    selection.forEach(selection => {
      const lineId = decodeURI(selection.line.id);
      const lineAllocations = task.allocations?.filter(allocation => {
        let taskOrderLineId = decodeURIComponent(allocation.taskOrderLine.id);
        if (taskOrderLineId === lineId) return true;
        if (!taskOrderLineId.endsWith("¤0")) return false;
        return taskOrderLineId.substring(0, taskOrderLineId.length - 2) === lineId;
      });

      const allocationQuantity = getAllocationQuantity(lineAllocations, selection);

      if (allocationQuantity === null) return;

      if (!allocationData.length) allocationData.push(allocationQuantity);
      else {
        const index = allocationData.findIndex(
          allocation => allocation.allocation.id === allocationQuantity.allocation.id,
        );
        if (index === -1) allocationData.push(allocationQuantity);
        else allocationData[index].quantity = allocationQuantity.quantity;
      }
    });

    return allocationData;
  };

  const removeLine = line => {
    setSelection(selection.filter(item => item.line.id !== line.id));
  };

  const addLine = line => {
    setSelection([...selection, { quantity: line.maxQuantity, line }]);
  };

  const handleCheck = line => {
    isSelected(line) ? removeLine(line) : addLine(line);
  };

  const allSelected = () => {
    const linesWithQuantity = task.lines.filter(line => line.maxQuantity > 0);
    return selection.length === linesWithQuantity?.length;
  };

  const disableSelectAll = lines => {
    const totalMaxQuantity = lines.reduce((sum, line) => sum + line.maxQuantity, 0);
    return totalMaxQuantity < 1;
  };

  const isSelected = row => selection.find(item => item.line?.id === row.id);

  const toggleAll = () => {
    const linesWithQuantity = task.lines.filter(line => line.maxQuantity > 0);
    allSelected()
      ? setSelection([])
      : setSelection(
          linesWithQuantity?.map(line => ({
            quantity: line.maxQuantity,
            line,
          })),
        );
  };

  const handleQuantityChange = (line, num) => {
    if (isSelected(line)) {
      const _selection = selection.filter(item => item.line.id !== line.id);
      _selection.push({ line, quantity: num });
      setSelection(_selection);
    }
  };

  const getQuantity = line => {
    if (isSelected(line)) {
      return line.maxQuantity;
    }
  };

  const isSameId = (allocation, lineId) => {
    let taskOrderLineId = decodeURIComponent(allocation.taskOrderLine.id);
    if (taskOrderLineId === lineId) return true;
    if (!taskOrderLineId.endsWith("¤0")) return false;
    return taskOrderLineId.substring(0, taskOrderLineId.length - 2) === lineId;
  };

  if (!task.orderNumber) {
    return <div>Loading</div>;
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.card}>
          <div className={styles.header}>
            <div className={styles.left}>
              <div className={styles.bold}>
                <span>{`${task.description}`}</span>
                {task?.to?.type === CLIENT_TYPE.USER && <span>{` - ${task.to.firstName} ${task.to.surname}`}</span>}
              </div>
              <div className={styles.row}>
                <p className={styles.label}>{translate("ORDER_NUMBER")}:</p>
                <p>{task.referenceOrderLine.orderNumber}</p>
              </div>
            </div>

            <div className={styles.right}>
              <div>{task.plannedDateTime}</div>
              <div className={classNames(styles.status, styles[`status${task.status}`])}>
                {translate(`TASK_STATUS_${task.status}`)}
              </div>
            </div>
          </div>

          <section className={styles.allocations}>
            <div className={styles.listHead}>
              <Checkbox
                label={translate("SELECT_ALL")}
                checked={!disableSelectAll(task.lines) && allSelected()}
                onChange={toggleAll}
                disabled={disableSelectAll(task.lines)}
              />
            </div>
            <ul>
              {task.lines?.map(line => {
                const allocations = task?.allocations?.filter(allocation =>
                  isSameId(allocation, decodeURIComponent(line.id)),
                );
                const locations = allocations?.map(allocation => allocation.location.value);
                const disabled = line.maxQuantity === 0;

                return (
                  <li key={line.id} className={disabled && styles.disabled}>
                    <div>
                      <Checkbox
                        onChange={() => handleCheck(line)}
                        checked={!disabled && Boolean(isSelected(line))}
                        disabled={disabled}
                      />
                    </div>

                    <div className={styles.allocation}>
                      <div className={styles.titleSection}>
                        <div className={styles.title}>{line.item?.name || line.logisticsObject?.item?.name}</div>
                      </div>
                      <div className={styles.locationQuantity}>
                        <div>{`${translate("RESERVED_ON_LOCATION")} ${locations.join(", ")}`}</div>
                        <div className={styles.title}>
                          {isSelected(line) ? (
                            <Counter
                              onChange={num => handleQuantityChange(line, num)}
                              min={1}
                              max={line.maxQuantity}
                              startValue={getQuantity(line)}
                              customCssClass={classNames(styles.counter, isSelected && styles.selected)}
                            />
                          ) : (
                            `${line.maxQuantity} ${translate("UNITS")}`
                          )}
                        </div>
                      </div>
                      <div className={styles.itemInfo}>
                        <div>
                          <span className={styles.label}>{`${translate("PRODUCT_NUMBER_SHORT")}`}</span>:{" "}
                          {line.item?.itemNumber || line.logisticsObject?.item?.itemNumber}
                        </div>
                        <div>
                          <span className={styles.label}>{`${translate("ARTICLE_CLASS")}`}</span>:{" "}
                          {line.item?.itemClass?.label || line.logisticsObject?.item?.itemClass?.label}
                        </div>
                      </div>
                      <div className={styles.assetInfo}>
                        <div>
                          <span className={styles.label}>{`${translate("INVENTORY_NUMBER_SHORT")}`}</span>:{" "}
                          {line.fixedAssetNumber || line.logisticsObject?.fixedAssetNumber}
                        </div>
                        <div>
                          <span className={styles.label}>{`${translate("BATCH_NO")}`}</span>:{" "}
                          {line.item?.batch || line.logisticsObject?.item?.batch}
                        </div>
                      </div>
                      <div className={styles.quantityInfo}>
                        <div>
                          <span className={styles.label}>{translate("TASK_ORDERED")}</span>: {line?.quantity}
                        </div>
                        <div>
                          <span className={styles.label}>{translate("TASK_DELIVERED")}</span>: {line?.reportedQuantity}
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </section>

          <section className={styles.actions}>
            <div className={styles.button}>
              <Button onClick={() => setModal("RETURN_TO_BASE_STORAGE")} theme={THEME.SECONDARY} disabled={disabled}>
                {translate("RETURN_TO_BASE_STORAGE")}
              </Button>
            </div>
            <div className={styles.button}>
              <Button onClick={() => setModal("DELIVER")} disabled={disabled}>
                {translate("DELIVER")}
              </Button>
            </div>
          </section>
        </div>
      </div>
      <ConfirmModal
        title={translate("DELIVER")}
        text={translate("TASK_DELIVER_DESCRIPTION")}
        visible={modal === "DELIVER"}
        cancel={() => setModal(null)}
        submit={() =>
          taskService
            .doAllocationTask({
              id: task.id,
              allocations: getSelectedAllocations(),
              action: ACTION.HAND_OVER,
              task,
            })
            .then(response => {
              setModal(null);
              if (response.data.status === STATUS_COMPLETED) {
                history.replace(pages.MY_TASKS.PATH);
              } else {
                location.reload();
              }
            })
        }
      />
      <ConfirmModal
        title={translate("RETURN_TO_BASE_STORAGE")}
        text={translate("TASK_RETURN_TO_BASE_STORAGE_DESCRIPTION")}
        visible={modal === "RETURN_TO_BASE_STORAGE"}
        cancel={() => setModal(null)}
        submit={() =>
          taskService
            .doAllocationTask({
              id: task.id,
              allocations: getSelectedAllocations(),
              action: ACTION.RETURN,
              task,
            })
            .then(response => {
              setModal(null);
              if (response.data.status === STATUS_COMPLETED) {
                history.replace(pages.MY_TASKS.PATH);
              } else {
                location.reload();
              }
            })
        }
      />
    </>
  );
};

TaskTypeCH.propTypes = {
  task: PropType.shape({
    id: PropType.string,
    description: PropType.string,
    plannedDateTime: PropType.string,
    status: PropType.number,
    orderNumber: PropType.number,
    to: PropType.shape({
      type: PropType.string,
      firstName: PropType.string,
      surname: PropType.string,
    }),
    referenceOrderLine: PropType.shape({
      orderNumber: PropType.number,
    }),
    lines: PropType.arrayOf(
      PropType.shape({
        fixedAssetNumber: PropType.string,
        quantity: PropType.number,
        maxQuantity: PropType.number,
        item: PropType.shape({
          name: PropType.string,
          itemNumber: PropType.string,
          batch: PropType.string,
          itemClass: PropType.shape({
            label: PropType.string,
          }),
        }),
      }),
    ),
    logisticsObject: PropType.arrayOf(
      PropType.shape({
        item: PropType.shape({
          name: PropType.string,
          itemNumber: PropType.string,
          batch: PropType.string,
          itemClass: PropType.shape({
            label: PropType.string,
          }),
        }),
      }),
    ),
    allocations: PropType.arrayOf(
      PropType.shape({
        quantity: PropType.number,
        taskOrderLine: PropType.shape({
          id: PropType.string,
        }),
        location: PropType.shape({
          value: PropType.string,
        }),
      }),
    ),
  }),
};

export default TaskTypeCH;
