import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./MessageBox.module.scss";

export const Type = {
  Error: styles.error,
  Warning: styles.warning,
  Info: styles.info,
};

const MessageBox = ({ message, type = Type.Error, children, customCssClass }) => {
  return (
    <article className={classNames(styles.box, type, customCssClass)}>
      <p>{message}</p>
      {children}
    </article>
  );
};

MessageBox.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf([Type.Error, Type.Warning, Type.Info]),
  customCssClass: PropTypes.string,
  children: PropTypes.node,
};

export default MessageBox;
