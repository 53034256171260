import { Button, MultiselectField } from "@myloc/myloc-gui";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useTranslate } from "../../../language/i18n";
import orderService from "../../../services/order/orderService";
import { DefaultItemText } from "../Text/TextComponents";
import styles from "./Accessories.module.scss";
import ChooseSpecificAccessories from "./SpecificAccessories/ChooseSpecificAccessories";
import ChosenSpecificAccessories from "./SpecificAccessories/ChosenSpecificAccessories";

function Accessories({ onNext, data }) {
  const translate = useTranslate();
  const [isLoading, setLoading] = useState(false);
  const [accessories, setAccessories] = useState([]);
  const params = useParams();
  const [chosenSpecificAccessories, setChosenSpecificAccessories] = useState([]);

  useEffect(() => {
    orderService
      .getAccessories(params.order, params.content)
      .then(response => setAccessories(response.data.accessories));
  }, [params]);

  const selectedValues = useRef({});

  const handleOnSelect = useCallback((fieldId, selected) => {
    selectedValues.current = Object.assign(selectedValues.current, { [fieldId]: selected });
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    const reqBody = {
      accessories: Object.entries(selectedValues.current).map(pair => {
        return { id: pair[0], selected: pair[1] };
      }),
    };

    if (data) {
      await orderService.updateContent(params.content, {
        ...data,
        accessories: chosenSpecificAccessories,
      });
    }
    const updateAccessoriesResponse = await orderService.updateAccessories(params.order, params.content, reqBody);

    setLoading(false);
    if (updateAccessoriesResponse.isOk()) {
      onNext(updateAccessoriesResponse.data);
    }
  };

  const handleSelectSpecificAccessory = selected => {
    const alreadyInListIndex = chosenSpecificAccessories.indexOf(selected);
    if (alreadyInListIndex !== -1) {
      let _selected = [...chosenSpecificAccessories];
      _selected[alreadyInListIndex].quantity++;
      setChosenSpecificAccessories(_selected);
    } else {
      setChosenSpecificAccessories(oldArray => [...oldArray, selected]);
    }
  };

  useEffect(() => {
    if (data?.accessories?.length > 0) {
      setChosenSpecificAccessories(data.accessories);
    }
  }, [data]);

  const template = item => {
    return (
      <p className={styles.item}>
        {item.value} {item.default && <DefaultItemText />}
      </p>
    );
  };

  const renderSelections = () => {
    return (
      accessories &&
      accessories.map(item => (
        <MultiselectField
          key={item.id}
          selection={item.values}
          label={item.label}
          preSelected={item.selected}
          onSelect={val => handleOnSelect(item.id, val)}
          required
          listTemplate={template}
        />
      ))
    );
  };

  const renderSpecificAccessory = () => {
    return (
      <div>
        <h3 className={styles.title}>{translate("CHOOSE_SPECIFIC_ACCESSORY")}</h3>
        <div className={styles.lists}>
          <ChooseSpecificAccessories onSelect={handleSelectSpecificAccessory} />
          <ChosenSpecificAccessories
            chosenSpecificAccessories={chosenSpecificAccessories}
            setChosenSpecificAccessories={setChosenSpecificAccessories}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className={styles.accessories}>{renderSelections()}</div>
      {data && <div className={styles.specificAccessory}>{renderSpecificAccessory()}</div>}
      <Button onClick={handleSubmit} isLoading={isLoading} customCssClass={styles.submitBtn}>
        {translate("NEXT")}
      </Button>
    </div>
  );
}

Accessories.propTypes = {
  onNext: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default Accessories;
